<template>
  <v-container :class="{ invisible: dialog3 }">
    <v-form ref="form" v-model="valid1" @submit.prevent="submitform">
      <div class="row">
        <div class="col-lg-8 col-md-12 col-sm-12 col-12 mx-0 px-1">
          <div class="fare-show">
            <v-card rounded="2" max-height="500px" class="p-2 m-auto">
              <div class="d-flex justify-content-between">
                <div>
                  <p class="m-0" style="font-size: 26px; font-weight: 500">
                    Total Price
                  </p>
                </div>
                <div>
                  <p style="font-size: 26px; font-weight: 500">
                    {{ currency }} {{ totalAmountpassenger }}
                  </p>
                </div>
              </div>

              <div class="row mt-2">
                <div class="col-md-12">
                  <div class="row justify-content-between">
                    <div class="col-6">
                      <span style="font-size: 14px; font-weight: 500"
                        >Passengers</span
                      >
                    </div>
                    <!-- <div class="col-md-4"></div> -->
                    <div class="col-6 text-end" style="font-size: 14px">
                      <span v-if="adultLength > 0"
                        >{{ adultLength }} x Adult</span
                      ><br />
                      <span v-if="childLength > 0"
                        >{{ childLength }} x Child</span
                      ><br />
                      <span v-if="infLength > 0"
                        >{{ infLength }} x Lap Infant</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-1">
                <hr class="mt-1 mb-2" />
                <div class="d-flex align-end mb-2">
                  <span
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    "
                  >
                    <span style="color: 0D6EFD; text-decoration: underline"
                      >Fare Summary</span
                    >
                  </span>

                  <v-icon
                    size="18"
                    class="not-collapsed"
                    data-bs-toggle="collapse"
                    href="#collapse-Example"
                    aria-expanded="true"
                    aria-controls="collapse-Example"
                    v-model="isIcon"
                    style="
                      color: 0D6EFD;
                      cursor: pointer;
                      overflow-anchor: none;
                    "
                  >
                    {{
                      isIcon
                        ? "mdi-chevron-up-box-outline"
                        : "mdi-chevron-down-box-outline"
                    }}</v-icon
                  >
                </div>

                <hr class="mt-1 mb-2" />

                <div
                  class="p-1 collapse"
                  :class="{ show: isIcon }"
                  id="collapse-Example"
                >
                  <div class="row">
                    <div class="col-6">
                      Base Price<br />
                      Taxes & Fees
                    </div>
                    <!-- <div class="col-6" align="right">
                      {{ currency }} {{ totalbasePassanger }}<br />
                      {{ currency }} {{ totalTaxPassanger }}
                    </div> -->
                    <div class="col-6 f-size-14">
                      <div class="d-flex flex-row justify-end">
                        <div class="col-5 text-end pe">
                          {{ currency }} <br />
                          {{ currency }}
                        </div>
                        <div class="col-7 text-end pe-2">
                          {{ totalbasePassanger }}<br />
                          {{ totalTaxPassanger }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="d-flex align-end mb-2">
                  <span
                    class="not-collapsed"
                    style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    "
                  >
                    <a
                      class="baggage border-none"
                      style="
                        font-size: 14px;
                        color: rgb(13, 110, 253);
                        text-decoration: underline;
                      "
                    >
                      <span
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop"
                        aria-controls="staticBackdrop"
                        @click="getFareRules()"
                        >Baggage and Cancellation Rules</span
                      >
                    </a>
                    <div class="drawer-section">
                      <div
                        class="offcanvas offcanvas-end"
                        data-bs-backdrop="static"
                        tabindex="-1"
                        id="staticBackdrop"
                        aria-labelledby="staticBackdropLabel"
                        style="width: 100% !important"
                      >
                        <div
                          class="offcanvas-header d-flex justify-content-between"
                          style="
                            background: #5293cb !important;
                            color: #fff !important;
                          "
                        >
                          <h5
                            class="offcanvas-title p-0"
                            id="staticBackdropLabel"
                          >
                            Baggage and Cancellation Rules
                          </h5>
                          <button
                            @click="closeBaggageRules"
                            type="button"
                            class="text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            color="white"
                          >
                            <v-icon>mdi-close</v-icon>
                          </button>
                        </div>
                        <div class="offcanvas-body">
                          <v-card class="">
                            <v-tabs v-model="tab" align-tabs="center" stacked>
                              <v-tab value="tab-1" class="m-0">
                                <v-icon>mdi-book-cancel-outline</v-icon>
                                Cancellation Rules
                              </v-tab>
                              <v-tab value="tab-2">
                                <v-icon>mdi-bag-checked</v-icon>
                                Baggage
                              </v-tab>
                            </v-tabs>

                            <v-card-text class="pt-0">
                              <keep-alive>
                                <v-window v-model="tab">
                                  <div v-if="tab === 'tab-1'">
                                    <h6 class="mt-2">Cancellation Rules:</h6>
                                    <div class="p-2 cancellationRules">
                                      <div
                                        v-if="fareRulesContent"
                                        v-html="fareRulesContent"
                                      ></div>
                                      <p v-else class="my-1">
                                        Check with Airline websites for
                                        Cancellation and Change Rules
                                      </p>
                                    </div>
                                  </div>
                                  <div v-if="tab === 'tab-2'">
                                    <h6 class="mt-1">Baggage Rules:</h6>
                                    <div
                                      class="p-2 rounded"
                                      v-for="(data, index) of roundresult"
                                      :key="index"
                                    >
                                      <div>
                                        <div
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>Carry-On:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> Checked Bag:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}

                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? "Pieces"
                                                  : "Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <hr v-if="data.Return" />
                                      <div v-if="data.Return" class="mt-2">
                                        <div
                                          class="mt-1"
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                        </div>
                                        
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>Carry-On:</span>
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-1">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> Checked Bag:</span>
                                            
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? "Pieces"
                                                  : "Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="onewayresult">
                                      <div
                                        class="p-2 rounded"
                                        v-for="(data, index) of onewayresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Depature[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Depature[
                                                data.Depature.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On:</span>
                                              <span
                                                v-if="data.BaggageData"
                                                class="ms-1"
                                              >
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " Piece"
                                                    : 1 + " Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag:</span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}

                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? "Pieces"
                                                    : "Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="multiresult.length > 0">
                                      <div
                                        class="border p-2 rounded"
                                        v-for="(data, index) of multiresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight1[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight1[
                                                data.Flight1.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData1.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>

                                          <div class="ms-2 mt-2">
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight2" />
                                        <div v-if="data.Flight2" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight2[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight2[
                                                data.Flight2.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData2.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight3" />
                                        <div v-if="data.Flight3" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight3[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight3[
                                                data.Flight3.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData3.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight4" />
                                        <div v-if="data.Flight4" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight4[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight4[
                                                data.Flight4.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData4.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="d-flex ms-2 mt-2">
                                            <div
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span
                                                ><v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="mt-1">
                                                {{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-window>
                              </keep-alive>
                            </v-card-text>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </span>
                </div> -->

                <!-- <br /> -->
                <p style="font-size: 12px">
                  All fares are quoted in {{ metaCurrency }}. Some airlines may
                  charge baggage fees. Your credit/debit card may be billed in
                  multiple charges totaling the final total price.
                </p>
              </div>
            </v-card>
          </div>

          <h3 class="mt-2">Flight Details</h3>
          <div v-for="(data, index) of roundresult" :key="index">
            <v-card class="card-space" v-if="itineraryInfo">
              <div class="mt-2">
                <v-card style="border: 1px solid lightgray">
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto"
                    style="background-color: #26225e; color: white"
                  >
                    <div class="col-md-8 col-4">
                      <div class="m-0 f-size-18 fw-600 d-flex align-center">
                        {{
                          console.log(
                            data,
                            "getFromCityNamegetFromCityNamegetFromCityName"
                          )
                        }}
                        <span style="color: white">{{
                          getdepcode(data.Depature)
                        }}</span>
                        <span class="mx-2"
                          ><v-img
                            :src="require('@/assets/img/icon/arrowright.png')"
                            width="45px"
                            class="arrowright"
                          ></v-img
                        ></span>
                        <span style="color: white">{{
                          getarrcode(data.Depature)
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-4 col-5">
                      <h6
                        class="m-0 font-change-11 text-end"
                        style="color: white"
                      >
                        {{ depdate(data.Depature) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 d-none">
                      <span class="font-change-11"
                        >{{ getStop(data.Depature) }} Stops</span
                      >
                    </div>
                    <div class="col-md-4 col-5 text-end trip-text me-2 d-none">
                      <span class="f-size-14 font-change-11"
                        >Trip Duration {{ data.Depaturejourney.Time }}</span
                      >
                    </div>
                    <!-- <div class="col-md-1 p-0 col-2">
               <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
             </div> -->
                  </div>

                  <div class="px-2 mx-1" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2 flex-wrap align-items-center"
                      v-for="(data1, index) of data.Depature"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-2 col-5 mb-3 mb-md-0 p-0">
                        <div
                          class="row ms-sm-1 ms-md-3 ms-0 w-100 d-flex flex-row justify-content-center p-0"
                        >
                          <div
                            class="col-md-4 col-12 col-sm-4 airline-logo m-auto p-0 pe-1"
                          >
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div
                            class="col-md-8 col-12 col-sm-8 pe-0 ps-1 airline-profile"
                          >
                            <p class="airline-name m-0">
                              <span class="f-size-12 fw-500">{{
                                data1.MarketingCarrier.Name
                              }}</span>
                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-md-9 col-9"></div> -->
                      <!-- <div class="row align-items-baseline"></div> -->
                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightdep(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Departure.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-2 col-sm-2 col-5 duration-data">
                        <p class="m-0 f-size-14 fw-600 text-center">
                          {{ getDurationTime(data1) }}
                        </p>
                        <p class="m-0 f-size-14 fw-500 text-center text-muted">
                          Duration
                        </p>
                      </div>

                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightarr(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Arrival.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-7 col-9 d-none">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-flex flex-md-column justify-xs-start justify-sm-start justify-md-start class-box d-none"
                      >
                        <div class="d-flex class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div
                        class="mt-3"
                        v-if="data.Depature.length - 1 !== index"
                      >
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Depature.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Depature.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data1.layoverTimes }} Layover Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

              <!-- Return Flight Start -->

              <div class="mt-3">
                <v-card style="border: 1px solid lightgray">
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto"
                    style="background-color: #26225e; color: white"
                  >
                    <div class="col-md-8 col-4">
                      <div class="m-0 f-size-18 fw-600 d-flex align-center">
                        <span style="color: white">{{
                          getstart(data.Return)
                        }}</span>
                        <span class="mx-2"
                          ><v-img
                            :src="require('@/assets/img/icon/arrowleft.png')"
                            width="45px"
                            class="arrowright"
                          ></v-img
                        ></span>
                        <span style="color: white">{{
                          getend(data.Return)
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-4 col-5">
                      <h6
                        class="m-0 font-change-11 text-end"
                        style="color: white"
                      >
                        {{ getstartdate(data.Return) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 d-none">
                      <span class="font-change-14"
                        >{{ getstop1(data.Return) }} Stops</span
                      >
                    </div>
                    <div class="col-md-4 col-5 text-end trip-text me-2 d-none">
                      <span class="f-size-14 font-change-11"
                        >Trip Duration {{ data.Returnjourney.Time }}</span
                      >
                    </div>
                    <!-- <div class="col-md-1 col-2 p-0">
               <a href="/search" class="text-decoration-none f-size-14  font-change-11">Change</a>
             </div> -->
                  </div>

                  <div class="px-2 mx-1" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2 flex-wrap align-items-center"
                      v-for="(data1, index) of data.Return"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-2 col-5 mb-3 mb-md-0 p-0">
                        <div
                          class="row ms-sm-1 ms-md-3 ms-0 w-100 d-flex flex-row p-0"
                        >
                          <div
                            class="col-md-4 col-12 col-sm-4 airline-logo m-auto p-0 pe-1"
                          >
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div
                            class="col-md-8 col-12 col-sm-8 pe-0 ps-1 airline-profile"
                          >
                            <p class="airline-name m-0">
                              <span class="f-size-12 fw-500">{{
                                data1.MarketingCarrier.Name
                              }}</span>
                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="col-md-9 col-9"></div> -->
                      <!-- <div class="row align-items-baseline"></div> -->
                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightdep(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Departure.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-2 col-sm-2 col-5 duration-data">
                        <p class="m-0 f-size-14 fw-600 text-center">
                          {{ getDurationTime(data1) }}
                        </p>
                        <p class="m-0 f-size-14 fw-500 text-center text-muted">
                          Duration
                        </p>
                      </div>

                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightarr(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Arrival.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-7 col-9 d-none">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-flex flex-md-column justify-xs-start justify-sm-start justify-md-start class-box d-none"
                      >
                        <div class="d-flex class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3" v-if="data.Return.length - 1 !== index">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Return.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Return.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data1.layoverTimes }} Layover Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
              <!-- Return Flight End -->
              <!-- <div>
     <span
       @click="itinerary()"
       class="text-decoration-none text-info"
       style="cursor: pointer"
       >Itinerary Details</span
     >&nbsp; &nbsp; &nbsp;
     <span
       ><span
         @click="baggage()"
         class="text-decoration-none text-info"
         style="cursor: pointer"
         >Baggage Info</span
       ></span
     >
   </div> -->
            </v-card>
          </div>

          <div v-for="(data, index) of onewayresult" :key="index">
            <!-- <div class="row">
                <h3 class="col-12 col-md-6">Flight Details</h3>
              </div> -->
            <v-card class="card-space" v-if="itineraryInfo1">
              <div class="mt-2">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto"
                    style="background-color: #26225e; color: white"
                  >
                    <div class="col-md-8 col-4">
                      <div class="m-0 f-size-18 fw-600 d-flex align-center">
                        <!-- <span style="color: white;">{{ getFromCityName(data.fromCity) }}</span>  -->
                        <span style="color: white">{{
                          getdepcode(data.Depature)
                        }}</span>
                        <span class="mx-2"
                          ><v-img
                            :src="require('@/assets/img/icon/arrowright.png')"
                            width="45px"
                            class="arrowright"
                          ></v-img
                        ></span>
                        <!-- <span style="color: white;">{{ getFromCityName(data.toCity) }}</span> -->
                        <span style="color: white">{{
                          getarrcode(data.Depature)
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-4 col-5">
                      <h6
                        class="m-0 font-change-11 text-end"
                        style="color: white"
                      >
                        {{ depdate(data.Depature) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 d-none">
                      <span class="font-change-11"
                        >{{ getStop(data.Depature) }} Stops</span
                      >
                    </div>
                    <div class="col-md-4 col-5 text-end trip-text me-2 d-none">
                      <span class="f-size-14 font-change-11"
                        >Trip Duration {{ data.Depaturejourney.Time }}</span
                      >
                    </div>
                    <!-- <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Depature) }} -

                        {{ getarrcode(data.Depature) }}
                      </h5>
                    </div>
                    <div class="col-md-3 col-5">
                      <h6 class="m-0 font-change-11">
                        {{ depdate(data.Depature) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 d-none">
                      <span class="font-change-11">{{ getStop(data.Depature) }} Stops</span>
                    </div>
                    <div class="col-md-4 col-10 text-end trip-text">
                      <span class="f-size-14 font-change-11">Trip Duration {{ data.Depaturejourney.Time }}</span>
                    </div> -->
                    <!-- <div class="col-md-1 p-0 col-2">
               <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
             </div> -->
                  </div>

                  <div class="px-2 mx-1" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2 flex-wrap align-items-center"
                      v-for="(data1, index) of data.Depature"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-2 col-5 mb-3 mb-md-0 p-0">
                        <div
                          class="row ms-sm-1 ms-lg-3 ms-0 w-100 d-flex flex-row justify-content-between p-0"
                        >
                          <div
                            class="col-md-4 col-12 col-sm-4 airline-logo m-auto p-0 pe-1"
                          >
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div
                            class="col-md-8 col-12 col-sm-8 pe-0 ps-1 airline-profile"
                          >
                            <p class="airline-name m-0">
                              <span class="f-size-14 fw-500">{{
                                data1.MarketingCarrier.Name
                              }}</span>
                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-9 col-9"></div>
                        <div class="row align-items-baseline"></div> -->
                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightdep(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Departure.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-2 col-sm-2 col-5 duration-data">
                        <p class="m-0 f-size-14 fw-600 text-center">
                          {{ getDurationTime(data1) }}
                        </p>
                        <p class="m-0 f-size-14 fw-500 text-center text-muted">
                          Duration
                        </p>
                      </div>

                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightarr(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Arrival.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-7 col-9 d-none">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 d-none col-12 d-flex flex-md-column justify-md-start class-box"
                      >
                        <div class="d-flex class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Depature.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Depature.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data1.layoverTimes }} Layover Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card>
          </div>

          <div v-for="(data, index) of multiresult" :key="index">
            <!-- <div class="row">
                <h3 class="col-12 col-md-6">Flight Details</h3>
              </div> -->
            <v-card class="card-space">
              <div class="mt-2">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto"
                    style="background-color: #26225e; color: white"
                  >
                    <!-- <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Flight1) }} -

                        {{ getarrcode(data.Flight1) }}
                      </h5>
                    </div> -->
                    <div class="col-md-8 col-4">
                      <div class="m-0 f-size-18 fw-600 d-flex align-center">
                        <span style="color: white">{{
                          data.muticityData[0].from
                        }}</span>
                        <span class="mx-2"
                          ><v-img
                            :src="require('@/assets/img/icon/arrowright.png')"
                            width="45px"
                            class="arrowright"
                          ></v-img
                        ></span>
                        <span style="color: white">{{
                          data.muticityData[0].to
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-3 col-5">
                      <h6
                        class="m-0 font-change-11 text-end"
                        style="color: white"
                      >
                        {{ depdate(data.Flight1) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 d-none">
                      <span class="font-change-11"
                        >{{ getStop(data.Flight1) }} Stops</span
                      >
                    </div>
                    <div class="col-md-4 col-10 text-end trip-text d-none">
                      <span class="f-size-14 font-change-11"
                        >Trip Duration {{ data.Flight1JourneyTime.Time }}</span
                      >
                    </div>
                    <!-- <div class="col-md-1 p-0 col-2">
            <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
          </div> -->
                  </div>

                  <div class="px-2 mx-1" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2 flex-wrap align-items-center"
                      v-for="(data1, index) of data.Flight1"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-2 col-5 mb-3 mb-md-0 p-0">
                        <div
                          class="row ms-sm-1 ms-md-3 ms-0 w-100 d-flex flex-row justify-content-center p-0"
                        >
                          <div
                            class="col-md-4 col-12 col-sm-4 airline-logo m-auto p-0 pe-1"
                          >
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div
                            class="col-md-8 col-12 col-sm-8 pe-0 ps-1 airline-profile"
                          >
                            <p class="airline-name m-0">
                              <span class="f-size-14 fw-500">{{
                                data1.MarketingCarrier.Name
                              }}</span>
                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-9 col-9">
                        <div class="row align-items-baseline"></div>
                      </div> -->
                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightdep(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Departure.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-2 col-sm-2 col-5 duration-data">
                        <p class="m-0 f-size-14 fw-600 text-center">
                          {{ getDurationTime(data1) }}
                        </p>
                        <p class="m-0 f-size-14 fw-500 text-center text-muted">
                          Duration
                        </p>
                      </div>

                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightarr(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Arrival.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-7 col-9 d-none">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box d-none"
                      >
                        <div class="d-flex class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Flight1.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Flight1.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data1.layoverTimes }} Layover Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

              <div class="mt-2">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto"
                    style="background-color: #26225e; color: white"
                  >
                    <!-- <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Flight2) }} -

                        {{ getarrcode(data.Flight2) }}
                      </h5>
                    </div> -->
                    <div class="col-md-8 col-4">
                      <div class="m-0 f-size-18 fw-600 d-flex align-center">
                        <span style="color: white">{{
                          data.muticityData[1].from
                        }}</span>
                        <span class="mx-2"
                          ><v-img
                            :src="require('@/assets/img/icon/arrowright.png')"
                            width="45px"
                            class="arrowright"
                          ></v-img
                        ></span>
                        <span style="color: white">{{
                          data.muticityData[1].to
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-3 col-5">
                      <h6
                        class="m-0 font-change-11 text-end"
                        style="color: white"
                      >
                        {{ depdate(data.Flight2) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 d-none">
                      <span class="font-change-11"
                        >{{ getStop(data.Flight2) }} Stops</span
                      >
                    </div>
                    <div class="col-md-4 col-10 d-none">
                      <span class="f-size-14 font-change-11"
                        >Trip Duration {{ data.Flight2JourneyTime.Time }}</span
                      >
                    </div>
                    <!-- <div class="col-md-1 p-0 col-2">
            <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
          </div> -->
                  </div>

                  <div class="px-2 mx-1" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2 flex-wrap align-items-center"
                      v-for="(data1, index) of data.Flight2"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-2 col-5 mb-3 mb-md-0 p-0">
                        <div
                          class="row ms-sm-1 ms-md-3 ms-0 w-100 d-flex flex-row justify-content-center p-0"
                        >
                          <div
                            class="col-md-4 col-12 col-sm-4 airline-logo m-auto p-0 pe-1"
                          >
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div
                            class="col-md-8 col-12 col-sm-8 pe-0 ps-1 airline-profile"
                          >
                            <p class="airline-name m-0">
                              <span class="f-size-14 fw-500">{{
                                data1.MarketingCarrier.Name
                              }}</span>
                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-9 col-9">
                        <div class="row align-items-baseline"></div>
                      </div> -->
                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightdep(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Departure.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-2 col-sm-2 col-5 duration-data">
                        <p class="m-0 f-size-14 fw-600 text-center">
                          {{ getDurationTime(data1) }}
                        </p>
                        <p class="m-0 f-size-14 fw-500 text-center text-muted">
                          Duration
                        </p>
                      </div>

                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightarr(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Arrival.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-7 col-9 d-none">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box d-none"
                      >
                        <div class="d-flex class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Flight2.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Flight2.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data1.layoverTimes }} Layover Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

              <div class="mt-2" v-if="data.Flight3 ? true : false">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto"
                    style="background-color: #26225e; color: white"
                  >
                    <!-- <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Flight3) }} -

                        {{ getarrcode(data.Flight3) }}
                      </h5>
                    </div> -->
                    <div class="col-md-8 col-4">
                      <div class="m-0 f-size-18 fw-600 d-flex align-center">
                        <span style="color: white">{{
                          data.muticityData[2].from
                        }}</span>
                        <span class="mx-2"
                          ><v-img
                            :src="require('@/assets/img/icon/arrowright.png')"
                            width="45px"
                            class="arrowright"
                          ></v-img
                        ></span>
                        <span style="color: white">{{
                          data.muticityData[2].to
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-3 col-5">
                      <h6
                        class="m-0 font-change-11 text-end"
                        style="color: white"
                      >
                        {{ depdate(data.Flight3) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 d-none">
                      <span class="font-change-11"
                        >{{ getStop(data.Flight3) }} Stops</span
                      >
                    </div>
                    <div class="col-md-4 col-10 d-none">
                      <span class="f-size-14 font-change-11"
                        >Trip Duration {{ data.Flight3JourneyTime.Time }}</span
                      >
                    </div>
                    <!-- <div class="col-md-1 p-0 col-2">
            <a href="/search" class="text-decoration-none f-size-14 font-change-11">Change</a>
          </div> -->
                  </div>

                  <div class="px-2 mx-1" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2 flex-wrap align-items-center"
                      v-for="(data1, index) of data.Flight3"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-2 col-5 mb-3 mb-md-0 p-0">
                        <div
                          class="row ms-sm-1 ms-md-3 ms-0 w-100 d-flex flex-row justify-content-center p-0"
                        >
                          <div
                            class="col-md-4 col-12 col-sm-4 airline-logo m-auto p-0 pe-1"
                          >
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div
                            class="col-md-8 col-12 col-sm-8 pe-0 ps-1 airline-profile"
                          >
                            <p class="airline-name m-0">
                              <span class="f-size-14 fw-500">{{
                                data1.MarketingCarrier.Name
                              }}</span>
                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-9 col-9">
                        <div class="row align-items-baseline"></div>
                      </div> -->
                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightdep(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Departure.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-2 col-sm-2 col-5 duration-data">
                        <p class="m-0 f-size-14 fw-600 text-center">
                          {{ getDurationTime(data1) }}
                        </p>
                        <p class="m-0 f-size-14 fw-500 text-center text-muted">
                          Duration
                        </p>
                      </div>

                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightarr(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Arrival.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-7 col-9 d-none">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box d-none"
                      >
                        <div class="d-flex class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Flight3.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Flight3.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data1.layoverTimes }} Layover Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>

              <div class="mt-2" v-if="data.Flight4 ? true : false">
                <v-card>
                  <div
                    class="row d-flex justify-content-between align-center pt-2 pb-2 m-auto"
                    style="background-color: #26225e; color: white"
                  >
                    <!-- <div class="col-md-2 col-4">
                      <h5 class="m-0 font-change-14">
                        {{ getdepcode(data.Flight4) }} -

                        {{ getarrcode(data.Flight4) }}
                      </h5>
                    </div> -->
                    <div class="col-md-8 col-4">
                      <div class="m-0 f-size-18 fw-600 d-flex align-center">
                        <span style="color: white">{{
                          data.muticityData[3].from
                        }}</span>
                        <span class="mx-2"
                          ><v-img
                            :src="require('@/assets/img/icon/arrowright.png')"
                            width="45px"
                            class="arrowright"
                          ></v-img
                        ></span>
                        <span style="color: white">{{
                          data.muticityData[3].to
                        }}</span>
                      </div>
                    </div>
                    <div class="col-md-3 col-5">
                      <h6 class="m-0 font-change-11 text-end" style="color: white">
                        {{ depdate(data.Flight4) }}
                      </h6>
                    </div>
                    <div class="col-md-2 col-3 d-none">
                      <span class="font-change-11"
                        >{{ getStop(data.Flight4) }} Stops</span
                      >
                    </div>
                    <div class="col-md-4 col-10 d-none">
                      <span class="f-size-14 font-change-11"
                        >Trip Duration {{ data.Flight4JourneyTime.Time }}</span
                      >
                    </div>
                  </div>

                  <div class="px-2 mx-1" style="max-width: 100%">
                    <div
                      class="row mt-3 mb-2 flex-wrap align-items-center"
                      v-for="(data1, index) of data.Flight4"
                      :key="index"
                    >
                      <div class="col-md-2 col-sm-2 col-5 mb-3 mb-md-0 p-0">
                        <div
                          class="row ms-sm-1 ms-md-3 ms-0 w-100 d-flex flex-row justify-content-center p-0"
                        >
                          <div
                            class="col-md-4 col-12 col-sm-4 airline-logo m-auto p-0 pe-1"
                          >
                            <v-img
                              :src="data1.MarketingCarrier.logo"
                              width="30px"
                            ></v-img>
                          </div>
                          <div
                            class="col-md-8 col-12 col-sm-8 pe-0 ps-1 airline-profile"
                          >
                            <p class="airline-name m-0">
                              <span class="f-size-14 fw-500">{{
                                data1.MarketingCarrier.Name
                              }}</span>
                              <br />
                              <span class="f-size-12 airline-id">
                                {{ data1.MarketingCarrier.AirlineID }} -
                                {{ data1.MarketingCarrier.FlightNumber }}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-9 col-9">
                        <div class="row align-items-baseline"></div>
                      </div> -->
                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightdep(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Departure.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-2 col-sm-2 col-5 duration-data">
                        <p class="m-0 f-size-14 fw-600 text-center">
                          {{ getDurationTime(data1) }}
                        </p>
                        <p class="m-0 f-size-14 fw-500 text-center text-muted">
                          Duration
                        </p>
                      </div>

                      <div class="col-md-4 col-sm-4 col-7 time-data">
                        <p class="m-0 f-size-20 fw-600 text-center">
                          {{ getflightarr(data1) }}
                        </p>
                        <p class="m-0 f-size-12 fw-500 text-center text-muted">
                          {{ data1.Arrival.AirportName }}
                        </p>
                      </div>

                      <div class="col-md-7 col-9 d-none">
                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated(data1.Departure.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightdep(data1) }}</span
                            >
                          </div>
                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500"
                              >{{ data1.Departure.AirportCode }} </span
                            >, {{ data1.Departure.AirportName }}
                          </div>
                        </div>

                        <div class="row d-flex">
                          <div class="col-sm-3 p-0">
                            <span class="f-size-10 airline-date">{{
                              getdated1(data1.Arrival.Date)
                            }}</span>
                            <span
                              class="f-size-13 airline-time"
                              style="margin-left: 5px"
                              >{{ getflightarr(data1) }}</span
                            >
                          </div>

                          <div
                            class="col-sm-9 p-0 f-size-13 aircode-name ps-md-2 ps-0"
                            style="margin-bottom: 4px"
                          >
                            <span class="fw-500">{{
                              data1.Arrival.AirportCode
                            }}</span>
                            , {{ data1.Arrival.AirportName }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-md-2 col-12 d-flex flex-md-column justify-md-start class-box d-none"
                      >
                        <div class="d-flex class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-seat-passenger
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            {{ data.cabinClass }}
                          </span>
                        </div>

                        <!-- <div class="d-flex ms-md-0 ms-2 class-type">
                          <v-icon size="15px" color="#ccc">
                            mdi-food-fork-drink
                          </v-icon>

                          <span class="f-size-12" style="padding-left: 5px">
                            Meal provided
                          </span>
                        </div> -->
                      </div>

                      <div class="mt-3">
                        <div class="d-flex position-relative">
                          <hr
                            v-if="data.Flight4.length - 1 !== index"
                            style="
                              width: 100%;
                              position: absolute;
                              margin-top: 8px !important;
                              margin-bottom: 0px !important;
                            "
                          />
                          <div
                            class="d-flex justify-center"
                            v-if="data.Flight4.length - 1 !== index"
                            style="
                              width: 180px;
                              background: antiquewhite;
                              border-radius: 18px;
                              z-index: 1;
                              margin: auto;
                            "
                          >
                            <div class="d-flex align-center">
                              <span class="f-size-10"
                                >{{ data1.layoverTimes }} Layover Time</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card>
              </div>
            </v-card>
          </div>



          <div class="my-4">
            <h3 class="pt-3">Traveller Details</h3>
            <div class="row gx-0 mx-1">
              <div class="col-md-12 p-0">
                <v-card style="border-top: 4px solid #26225e">
                  <div class="py-2 px-3">
                    <div
                      class="mt-1"
                      style="
                        background-color: rgb(246, 246, 246);
                        padding: 10px;
                      "
                    >
                      <h6 class="m-0">Passenger Contact Data</h6>
                    </div>
                    <div class="row">
                      <div class="row pe-0" v-for="data of paxContacts" :key="data">
                        <div class="col-md-4 mt-2 pe-0">
                          <v-autocomplete
                            :items="countrycode"
                            item-title="name"
                            variant="outlined"
                            width="auto"
                            :rules="[(v) => !!v || 'Country Code is required']"
                            label="Country Code"
                            v-model="data.code"
                          >
                          </v-autocomplete>
                        </div>
                        <div class="col-md-4 mt-2 pe-0">
                          <v-text-field
                            v-model="data.number"
                            label="Phone Number"
                            minlength="5"
                            maxlength="15"
                            :rules="phoneNumberValid"
                            variant="outlined"
                          ></v-text-field>
                        </div>
                        <div class="col-md-4 mt-2 pe-0">
                          <v-text-field
                            label="Email ID"
                            :rules="emailRequire"
                            variant="outlined"
                            v-model="data.email"
                          ></v-text-field>
                        </div>
                      </div>
                    </div>
                    <p class="m-0 f-size-12 fw-500" style="color: #162849">
                      Your Booking Details will be sent to this email address
                      and mobile number.
                    </p>

                    <div
                      class="row mt-2"
                      v-for="(data, index) of adultData"
                      :key="index"
                    >
                      <p class="m-0">
                        <span style="font-size: 20px"
                          >Passenger {{ index + 1 }} |
                        </span>
                        <span style="font-size: 16px">Adult</span>
                      </p>

                      <div class="row mt-4 pe-0">
                        <div class="col-lg-2 pe-0 mrdata">
                          <v-select
                            label="Salutation"
                            :items="['Mr', 'Miss', 'Mrs', 'Ms']"
                            v-model="data.salutation"
                            variant="outlined"
                          ></v-select>
                        </div>
                        <div class="col-lg-5 pe-0">
                          <v-text-field
                            label="First Name"
                            variant="outlined"
                            v-model="data.fname"
                            :rules="firstName"
                          ></v-text-field>
                        </div>

                        <div class="col-lg-5 pe-0">
                          <v-text-field
                            label="Middle Name"
                            variant="outlined"
                            v-model="data.middle"
                          ></v-text-field>
                        </div>
                      </div>

                      <div class="row pe-0">
                        <div class="col-lg-5 pe-0">
                          <v-text-field
                            label="Last Name"
                            variant="outlined"
                            v-model="data.last"
                            :rules="lastName"
                          ></v-text-field>
                        </div>

                        <div class="col-lg-3 pe-0">
                          <v-select
                            v-model="data.gender"
                            label="Gender"
                            :items="['Male', 'Female']"
                            variant="outlined"
                            :rules="[(v) => !!v || 'This field is required']"
                          ></v-select>
                        </div>

                        <div class="col-md-4 pe-0">
                          <div class="col-md-12">
                            <span
                              :class="
                                data.clicked1
                                  ? 'p-float-label-1'
                                  : 'p-float-label-2'
                              "
                            >
                              <label
                                :class="
                                  data.clicked1 ? 'dob-label-top' : 'dob-label'
                                "
                                >Date of Birth</label
                              >
                              <VueDatePicker
                                v-model="data.dateOfBirth"
                                ref="dpRef1"
                                no-today
                                :six-weeks="true"
                                auto-apply
                                :start-date="
                                  new Date(
                                    new Date(getdepDate).getFullYear() - 12,
                                    new Date(getdepDate).getMonth(),
                                    new Date(getdepDate).getDate(),
                                    0,
                                    0,
                                    0
                                  )
                                "
                                :max-date="
                                  new Date(
                                    new Date(getdepDate).getFullYear() - 12,
                                    new Date(getdepDate).getMonth(),
                                    new Date(getdepDate).getDate(),
                                    23,
                                    59,
                                    59
                                  )
                                "
                                :min-date="
                                  new Date(
                                    new Date(getdepDate).getFullYear() - 100,
                                    new Date(getdepDate).getMonth(),
                                    new Date(getdepDate).getDate()
                                  )
                                "
                                :format="dateFormat"
                                :hide-navigation="['time']"
                                @update:modelValue="adultDob()"
                                class="px-0"
                                required
                              />
                              {{ console.log(data, index, "datepickerrr") }}

                              <!-- <VueDatePicker v-model="data.dateOfBirth" auto-apply ref="dpRef1" 
                                :start-date="new Date(new Date(getdepDate).getFullYear() - 12, new Date(getdepDate).getMonth(), new Date(getdepDate).getDate(), 0, 0, 0)"  
               :max-date="new Date(new Date(getdepDate).getFullYear() - 12, new Date(getdepDate).getMonth(), new Date(getdepDate).getDate(), 23, 59, 59)"  /> -->
                              <!-- <span class="p-error">Dep. Date is required</span> -->
                            </span>
                            <p
                              v-if="
                                !data.dateOfBirth &&
                                (data.dobAdultError[index] || valid1 == false)
                              "
                              class="p-error px-4 m-0"
                            >
                              Date of Birth is required
                            </p>
                          </div>
                        </div>
                      </div>

                      <div>
                        <v-card class="ffb-cart" v-if="data.ffbnumber">
                          <div align="end">
                            <v-icon
                              color="primary"
                              @click="data.ffbnumber = false"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>

                          <h6>Frequent Flyer Program</h6>

                          <div class="row mt-4">
                            <div class="col-md-3">
                              <v-select
                                label="Frequent Flyer Program"
                                rounted="5"
                                v-model="air"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                label="FFP Number"
                                variant="outlined"
                                v-model="ffb"
                              ></v-text-field>
                            </div>
                            <div class="col-md-6" align="end">
                              <v-btn
                                variant="outlined"
                                color="primary"
                                @click="reset()"
                              >
                                Reset
                              </v-btn>
                            </div>
                          </div>
                        </v-card>

                        <v-card class="ffb-cart" v-if="data.TSAnumber">
                          <div align="end">
                            <v-icon
                              color="primary"
                              @click="data.TSAnumber = false"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>

                          <h6>Transport Security Administration</h6>
                          <div class="row mt-4 gy-3">
                            <div class="col-lg-3">
                              <v-text-field
                                label="Last name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="First name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="2nd and additional given names"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="Date of Birth"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <p class="redress">
                            Redress number and known traveller number
                            <span>
                              <v-icon @click="Redressnumber = !Redressnumber"
                                >mdi-menu-down</v-icon
                              >
                            </span>
                          </p>

                          <div class="row mt-4 gy-3">
                            <div class="col-md-3">
                              <v-text-field
                                v-if="Redressnumber"
                                label="Redress Number"
                                v-model="Redress"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                v-if="Redressnumber"
                                label="Known Traveler Number"
                                v-model="Traveler"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-6" align="end">
                              <v-btn
                                variant="outlined"
                                color="primary"
                                @click="reset1()"
                              >
                                Reset
                              </v-btn>
                            </div>
                          </div>
                        </v-card>

                        <v-card class="ffb-cart p-4" v-if="data.Apinumber">
                          <h6>Advance Passenger Information System</h6>
                          <h6>Primary Data of the Travel Document</h6>
                          <div class="row mt-3 gy-3">
                            <div class="col-lg-3">
                              <v-text-field
                                label="Last name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="First name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="2nd and additional given names    "
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-2">
                              <v-text-field
                                label="Date Of Birth"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>

                          <div class="row mt-4 gy-3">
                            <div class="col-3-lg">
                              <v-select
                                label="Document Type"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-3-lg">
                              <v-text-field
                                label="Document Number"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-3-lg">
                              <v-select
                                label="Issuance Country"
                                rounted="5"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-3-lg">
                              <v-select
                                label="Nationality"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                          </div>

                          <div class="row mt-2 gy-3">
                            <div class="col-2-lg">
                              <v-text-field
                                label="Issue Date"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-2-lg">
                              <v-text-field
                                label="Expiration"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <p
                            style="color: blue; cursor: pointer"
                            @click="apivalue = !apivalue"
                          >
                            Passenger contact address (DOCA)
                            <span>
                              <v-icon>mdi-menu-down</v-icon>
                            </span>
                          </p>

                          <div class="row mt-2 gy-3" v-if="apivalue">
                            <div class="col-md-2">
                              <p>Type of address</p>
                            </div>
                            <div class="col-md-10">
                              <v-radio-group
                                inline
                                color="primary"
                                v-model="value"
                              >
                                <v-radio
                                  label="Destination"
                                  value="1"
                                ></v-radio>
                                <v-radio label="Residence" value="2"></v-radio>
                              </v-radio-group>
                            </div>
                          </div>

                          <div class="row mt-4 gy-3" v-if="apivalue">
                            <div class="col-md-2">
                              <v-text-field
                                label="Address Details"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                label="ZIP/Postal code"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-select
                                label="Country"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <v-select
                                label="State/province"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                label="City"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-12 mt-2" align="end">
                            <v-btn variant="outlined" color="primary">
                              Reset
                            </v-btn>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      v-for="(data, index) of childData"
                      :key="index"
                    >
                      <!-- <h6>Children {{ index + 1 }}</h6> -->
                      <p>
                        <span style="font-size: 20px"
                          >Passenger {{ adultData.length + (index + 1) }} |
                        </span>
                        <span style="font-size: 16px">Children</span>
                      </p>

                      <div class="row mt-4">
                        <div class="col-lg-2">
                          <v-select
                            label="Salutation"
                            :items="['Mstr', 'Miss']"
                            v-model="data.salutation"
                            variant="outlined"
                          ></v-select>
                        </div>

                        <div class="col-lg-5">
                          <v-text-field
                            label="First Name"
                            variant="outlined"
                            v-model="data.fname"
                            :rules="firstName"
                          ></v-text-field>
                        </div>

                        <div class="col-lg-5">
                          <v-text-field
                            label="Middle Name"
                            variant="outlined"
                            v-model="data.middle"
                          ></v-text-field>
                        </div>

                        <!-- <div class="col-lg-2">
                   <v-select label="Gender" :items="['Male', 'Female']" variant="outlined"
                     :rules="[(v) => !!v || 'this field is required']" v-model="data.gender"></v-select>
                 </div> -->
                      </div>

                      <div class="row">
                        <div class="col-lg-5">
                          <v-text-field
                            label="Last Name"
                            variant="outlined"
                            v-model="data.last"
                            :rules="lastName"
                          ></v-text-field>
                        </div>

                        <div class="col-lg-3">
                          <v-select
                            label="Gender"
                            :items="['Male', 'Female']"
                            variant="outlined"
                            :rules="[(v) => !!v || 'this field is required']"
                            v-model="data.gender"
                          ></v-select>
                        </div>
                        <!-- <label>Date of Birth</label> -->
                        <div class="col-md-4">
                          <!-- <span>
                     <v-icon>mdi-calendar</v-icon>
                   </span> -->
                          <span
                            :class="
                              data.clicked1
                                ? 'p-float-label-1'
                                : 'p-float-label-2'
                            "
                          >
                            <label
                              :class="
                                data.clicked1 ? 'dob-label-top' : 'dob-label'
                              "
                              >Date of Birth</label
                            >
                            <VueDatePicker
                              v-model="data.dateOfBirth"
                              ref="dpRef1"
                              no-today
                              :six-weeks="true"
                              :start-date="
                                new Date(
                                  new Date(getdepDate).getFullYear() - 12,
                                  new Date(getdepDate).getMonth(),
                                  new Date(getdepDate).getDate(),
                                  0,
                                  0,
                                  0
                                )
                              "
                              :max-date="
                                new Date(
                                  new Date(getdepDate).getFullYear() - 2,
                                  new Date(getdepDate).getMonth(),
                                  new Date(getdepDate).getDate(),
                                  23,
                                  59,
                                  59
                                )
                              "
                              :min-date="
                                new Date(
                                  new Date(getdepDate).getFullYear() - 12,
                                  new Date(getdepDate).getMonth(),
                                  new Date(getdepDate).getDate()
                                )
                              "
                              :format="dateFormat"
                              :hide-navigation="['time']"
                              auto-apply
                              @update:modelValue="childDob()"
                              class="px-0"
                            />
                            <!-- <span class="p-error">Dep. Date is required</span> -->
                          </span>
                          <p
                            v-if="
                              !data.dateOfBirth &&
                              (data.dobChildError[index] || valid1 == false)
                            "
                            class="p-error px-4 m-0"
                          >
                            This field is required
                          </p>
                        </div>

                        <!-- <div class="col-md-6 mt-2">
                   <v-btn-toggle class="d-flex align-center">
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="ffbdata(data, index)">
                       FFp
                       <v-tooltip activator="parent" location="top" width="200px">
                         Please select an airline/frequent
                       </v-tooltip>
                     </v-btn>
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="tsadata(index)">
                       TSA
                       <v-tooltip activator="parent" location="top" width="200px">
                         Please select an airline/frequent flyer program
                       </v-tooltip>
                     </v-btn>
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="apidata(index)">
                       APIS
                       <v-tooltip activator="parent" location="top" width="200px">
                         the validating carrier are being displayed.
                       </v-tooltip>
                     </v-btn>
                   </v-btn-toggle>
                 </div> -->
                      </div>

                      <div>
                        <v-card class="ffb-cart" v-if="data.ffbnumber">
                          <div align="end">
                            <v-icon
                              color="primary"
                              @click="data.ffbnumber = false"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>

                          <h6>Frequent Flyer Program</h6>

                          <div class="row mt-4">
                            <div class="col-md-3">
                              <v-select
                                label="Frequent Flyer Program"
                                rounted="5"
                                v-model="air"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                label="FFP Number"
                                variant="outlined"
                                v-model="ffb"
                              ></v-text-field>
                            </div>
                            <div class="col-md-6" align="end">
                              <v-btn
                                variant="outlined"
                                color="primary"
                                @click="reset()"
                              >
                                Reset
                              </v-btn>
                            </div>
                          </div>
                        </v-card>

                        <v-card class="ffb-cart" v-if="data.TSAnumber">
                          <div align="end">
                            <v-icon
                              color="primary"
                              @click="data.TSAnumber = false"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>

                          <h6>Transport Security Administration</h6>
                          <div class="row mt-4 gy-3">
                            <div class="col-lg-3">
                              <v-text-field
                                label="Last name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="First name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="2nd and additional given names"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="Date of Birth"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <p class="redress">
                            Redress number and known traveller number
                            <span>
                              <v-icon @click="Redressnumber = !Redressnumber"
                                >mdi-menu-down</v-icon
                              >
                            </span>
                          </p>

                          <div class="row mt-4 gy-3">
                            <div class="col-md-3">
                              <v-text-field
                                v-if="Redressnumber"
                                label="Redress Number"
                                v-model="Redress"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                v-if="Redressnumber"
                                label="Known Traveler Number"
                                v-model="Traveler"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-6" align="end">
                              <v-btn
                                variant="outlined"
                                color="primary"
                                @click="reset1()"
                              >
                                Reset
                              </v-btn>
                            </div>
                          </div>
                        </v-card>

                        <v-card class="ffb-cart p-4" v-if="data.Apinumber">
                          <h6>Advance Passenger Information System</h6>
                          <h6>Primary Data of the Travel Document</h6>
                          <div class="row mt-3 gy-3">
                            <div class="col-lg-3">
                              <v-text-field
                                label="Last name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="First name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="2nd and additional given names    "
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-2">
                              <v-text-field
                                label="Date Of Birth"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>

                          <div class="row mt-4 gy-3">
                            <div class="col-3-lg">
                              <v-select
                                label="Document Type"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-3-lg">
                              <v-text-field
                                label="Document Number"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-3-lg">
                              <v-select
                                label="Issuance Country"
                                rounted="5"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-3-lg">
                              <v-select
                                label="Nationality"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                          </div>

                          <div class="row mt-2 gy-3">
                            <div class="col-2-lg">
                              <v-text-field
                                label="Issue Date"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-2-lg">
                              <v-text-field
                                label="Expiration"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <p
                            style="color: blue; cursor: pointer"
                            @click="apivalue = !apivalue"
                          >
                            Passenger contact address (DOCA)
                            <span>
                              <v-icon>mdi-menu-down</v-icon>
                            </span>
                          </p>

                          <div class="row mt-2 gy-3" v-if="apivalue">
                            <div class="col-md-2">
                              <p>Type of address</p>
                            </div>
                            <div class="col-md-10">
                              <v-radio-group
                                inline
                                color="primary"
                                v-model="value"
                              >
                                <v-radio
                                  label="Destination"
                                  value="1"
                                ></v-radio>
                                <v-radio label="Residence" value="2"></v-radio>
                              </v-radio-group>
                            </div>
                          </div>

                          <div class="row mt-4 gy-3" v-if="apivalue">
                            <div class="col-md-2">
                              <v-text-field
                                label="Address Details"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                label="ZIP/Postal code"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-select
                                label="Country"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <v-select
                                label="State/province"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                label="City"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-12 mt-2" align="end">
                            <v-btn variant="outlined" color="primary">
                              Reset
                            </v-btn>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <div
                      class="row mt-2"
                      v-for="(data, index) of infrantData"
                      :key="index"
                    >
                      <!-- <h6>Lap Infant {{ index + 1 }}</h6> -->
                      <p>
                        <span style="font-size: 20px"
                          >Passenger
                          {{
                            adultData.length + childData.length + (index + 1)
                          }}
                          |
                        </span>
                        <span style="font-size: 16px">Lap Infant</span>
                      </p>

                      <div class="row mt-4">
                        <div class="col-lg-2">
                          <v-select
                            label="Salutation"
                            :items="['Mstr', 'Miss']"
                            v-model="data.salutation"
                            variant="outlined"
                          ></v-select>
                        </div>

                        <div class="col-lg-5">
                          <v-text-field
                            label="First Name"
                            variant="outlined"
                            v-model="data.fname"
                            :rules="firstName"
                          ></v-text-field>
                        </div>

                        <div class="col-lg-5">
                          <v-text-field
                            label="Middle Name"
                            variant="outlined"
                            v-model="data.middle"
                          ></v-text-field>
                        </div>

                        <!-- <div class="col-lg-2">
                   <v-select label="Gender" :items="['Male', 'Female']" variant="outlined"
                     :rules="[(v) => !!v || 'this field is required']" v-model="data.gender"></v-select>
                 </div> -->
                      </div>

                      <div class="row">
                        <div class="col-lg-5">
                          <v-text-field
                            label="Last Name"
                            variant="outlined"
                            v-model="data.last"
                            :rules="lastName"
                          ></v-text-field>
                        </div>
                        <div class="col-lg-3">
                          <v-select
                            label="Gender"
                            :items="['Male', 'Female']"
                            variant="outlined"
                            :rules="[(v) => !!v || 'this field is required']"
                            v-model="data.gender"
                          ></v-select>
                        </div>
                        <!-- <label>Date of Birth</label> -->
                        <div class="col-md-4">
                          <span
                            :class="
                              data.clicked1
                                ? 'p-float-label-1'
                                : 'p-float-label-2'
                            "
                          >
                            <label
                              :class="
                                data.clicked1 ? 'dob-label-top' : 'dob-label'
                              "
                              >Date of Birth</label
                            >
                            <VueDatePicker
                              v-model="data.dateOfBirth"
                              ref="dpRef1"
                              no-today
                              :six-weeks="true"
                              :start-date="
                                new Date(
                                  new Date(getdepDate).getFullYear() - 2,
                                  new Date(getdepDate).getMonth(),
                                  new Date(getdepDate).getDate(),
                                  0,
                                  0,
                                  0
                                )
                              "
                              :max-date="
                                new Date(
                                  new Date(getdepDate).getFullYear(),
                                  new Date(getdepDate).getMonth(),
                                  new Date(getdepDate).getDate(),
                                  23,
                                  59,
                                  59
                                )
                              "
                              :min-date="
                                new Date(
                                  new Date(getdepDate).getFullYear() - 2,
                                  new Date(getdepDate).getMonth(),
                                  new Date(getdepDate).getDate()
                                )
                              "
                              :format="dateFormat"
                              :hide-navigation="['time']"
                              auto-apply
                              @update:modelValue="infrantDob()"
                              class="px-0"
                            />
                            <!-- <span class="p-error">Dep. Date is required</span> -->
                          </span>
                          <p
                            v-if="
                              !data.dateOfBirth &&
                              (data.dobInfrantError[index] || valid1 == false)
                            "
                            class="p-error px-4 m-0"
                          >
                            This field is required
                          </p>
                        </div>

                        <!-- <div class="col-md-6 mt-2">
                   <v-btn-toggle class="d-flex align-center">
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="ffbdata(data, index)">
                       FFp
                       <v-tooltip activator="parent" location="top" width="200px">
                         Please select an airline/frequent
                       </v-tooltip>
                     </v-btn>
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="tsadata(index)">
                       TSA
                       <v-tooltip activator="parent" location="top" width="200px">
                         Please select an airline/frequent flyer program
                       </v-tooltip>
                     </v-btn>
                     <v-btn append-icon="mdi-menu-down" variant="outlined" rounded="2" class="ffb-button"
                       @click="apidata(index)">
                       APIS
                       <v-tooltip activator="parent" location="top" width="200px">
                         the validating carrier are being displayed.
                       </v-tooltip>
                     </v-btn>
                   </v-btn-toggle>
                 </div> -->
                      </div>

                      <div>
                        <v-card class="ffb-cart" v-if="data.ffbnumber">
                          <div align="end">
                            <v-icon
                              color="primary"
                              @click="data.ffbnumber = false"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>

                          <h6>Frequent Flyer Program</h6>

                          <div class="row mt-4">
                            <div class="col-md-3">
                              <v-select
                                label="Frequent Flyer Program"
                                rounted="5"
                                v-model="air"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                label="FFP Number"
                                variant="outlined"
                                v-model="ffb"
                              ></v-text-field>
                            </div>
                            <div class="col-md-6" align="end">
                              <v-btn
                                variant="outlined"
                                color="primary"
                                @click="reset()"
                              >
                                Reset
                              </v-btn>
                            </div>
                          </div>
                        </v-card>

                        <v-card class="ffb-cart" v-if="data.TSAnumber">
                          <div align="end">
                            <v-icon
                              color="primary"
                              @click="data.TSAnumber = false"
                            >
                              mdi-close-circle
                            </v-icon>
                          </div>

                          <h6>Transport Security Administration</h6>
                          <div class="row mt-4 gy-3">
                            <div class="col-lg-3">
                              <v-text-field
                                label="Last name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="First name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="2nd and additional given names"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="Date of Birth"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <p class="redress">
                            Redress number and known traveller number
                            <span>
                              <v-icon @click="Redressnumber = !Redressnumber"
                                >mdi-menu-down</v-icon
                              >
                            </span>
                          </p>

                          <div class="row mt-4 gy-3">
                            <div class="col-md-3">
                              <v-text-field
                                v-if="Redressnumber"
                                label="Redress Number"
                                v-model="Redress"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-text-field
                                v-if="Redressnumber"
                                label="Known Traveler Number"
                                v-model="Traveler"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-6" align="end">
                              <v-btn
                                variant="outlined"
                                color="primary"
                                @click="reset1()"
                              >
                                Reset
                              </v-btn>
                            </div>
                          </div>
                        </v-card>

                        <v-card class="ffb-cart p-4" v-if="data.Apinumber">
                          <h6>Advance Passenger Information System</h6>
                          <h6>Primary Data of the Travel Document</h6>
                          <div class="row mt-3 gy-3">
                            <div class="col-lg-3">
                              <v-text-field
                                label="Last name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="First name as per passport"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-3">
                              <v-text-field
                                label="2nd and additional given names    "
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-lg-2">
                              <v-text-field
                                label="Date Of Birth"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>

                          <div class="row mt-4 gy-3">
                            <div class="col-3-lg">
                              <v-select
                                label="Document Type"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-3-lg">
                              <v-text-field
                                label="Document Number"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-3-lg">
                              <v-select
                                label="Issuance Country"
                                rounted="5"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-3-lg">
                              <v-select
                                label="Nationality"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                          </div>

                          <div class="row mt-2 gy-3">
                            <div class="col-2-lg">
                              <v-text-field
                                label="Issue Date"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-2-lg">
                              <v-text-field
                                label="Expiration"
                                type="date"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <p
                            style="color: blue; cursor: pointer"
                            @click="apivalue = !apivalue"
                          >
                            Passenger contact address (DOCA)
                            <span>
                              <v-icon>mdi-menu-down</v-icon>
                            </span>
                          </p>

                          <div class="row mt-2 gy-3" v-if="apivalue">
                            <div class="col-md-2">
                              <p>Type of address</p>
                            </div>
                            <div class="col-md-10">
                              <v-radio-group
                                inline
                                color="primary"
                                v-model="value"
                              >
                                <v-radio
                                  label="Destination"
                                  value="1"
                                ></v-radio>
                                <v-radio label="Residence" value="2"></v-radio>
                              </v-radio-group>
                            </div>
                          </div>

                          <div class="row mt-4 gy-3" v-if="apivalue">
                            <div class="col-md-2">
                              <v-text-field
                                label="Address Details"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                label="ZIP/Postal code"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                            <div class="col-md-3">
                              <v-select
                                label="Country"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-3">
                              <v-select
                                label="State/province"
                                :items="[
                                  'Aeroplan-Air canada',
                                  'Flying Returns-Air india',
                                  'Air Points-Air America',
                                  'Phonix-Air china',
                                ]"
                                variant="outlined"
                              ></v-select>
                            </div>
                            <div class="col-md-2">
                              <v-text-field
                                label="City"
                                variant="outlined"
                              ></v-text-field>
                            </div>
                          </div>
                          <div class="col-12 mt-2" align="end">
                            <v-btn variant="outlined" color="primary">
                              Reset
                            </v-btn>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <!-- <div class="mt-1" style="background-color: #f6f6f6; padding: 10px">
                      <h6>Passenger Contact Data</h6>
                    </div>
                    <div class="row mt-3">

                      <div>
                        <div class="row" v-for="data of paxContacts" :key="data">
                          <div class="col-md-4 mt-2">


                            <v-autocomplete :items="countrycode" item-title="name" variant="outlined" width="auto"
                              :rules="[(v) => !!v || 'Country Code is required']" label="Country Code"
                              v-model="data.code">
                            </v-autocomplete>


                          </div>


                          <div class="col-md-4 mt-2">
                            <v-text-field v-model="data.number" label="Phone Number" minlength="5" maxlength="15"
                              :rules="phoneNumberValid" variant="outlined"></v-text-field>
                          </div>


                          <div class="col-md-4 mt-2">
                            <v-text-field label="Email ID" :rules="emailRequire" variant="outlined"
                              v-model="data.email"></v-text-field>
                          </div>

                        </div>

                      </div>

                    </div> -->

                    <div class="row mt-2">
                      <div class="col-lg-8">
                        <div class="row">
                          <div class="col-md-5">
                            <v-checkbox
                              v-model="contactCheck"
                              class="spl-checkbox"
                              label="Contact me on whatsapp"
                              color="success"
                            ></v-checkbox>
                          </div>
                          <div class="col-md-7">
                            <v-checkbox
                              v-model="couponsCheck"
                              class="spl-checkbox"
                              label="Send me the latest travel deals, special offers, coupons."
                              color="success"
                            ></v-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr style="margin-top:10px;margin-bottom:20px;"/>

                  <div
                    class="d-flex justify-center checked-box"
                    style="max-width: 100%; margin: auto"
                  >
                    <v-checkbox
                      v-model="formCheckBox"
                      class="px-3"
                      style="flex: none"
                    ></v-checkbox>
                    <span
                      class="agree-content d-flex align-center pe-2"
                      style="font-size: 14px"
                      >By selecting to continue I acknowledge that I have read
                      and accept the Rules & Restrictions, Terms of use and
                      Privacy Policy. In addition, I also confirm that I have
                      verified that all information entered during the
                      reservation process is accurate. I also acknowledge that
                      this will serve as my electronic signature.
                    </span>
                  </div>
                  <br />
                </v-card>
              </div>
            </div>
          </div>

          <div class="mb-5" align="center">
            <v-btn
              width="35%"
              height="40px"
              type="submit"
              style="
                color: white;
                text-transform: capitalize;
                background-color: #26225e;
                font-size: 20px;
              "
              >Continue</v-btn
            >
          </div>
        </div>

        <div class="col-lg-4 col-md-3" style="margin-bottom: 70px">
          <div class="fare-summery" :style="{ top: topSticky }">
            <v-card
              max-width="400px"
              max-height="500px"
              class="m-auto"
              style="border: 1px solid lightgrey"
            >
              <div class="p-2" style="background-color: #d3d3d373">
                <h5 class="pa-2 py-2 m-0">Fare Details</h5>
              </div>

              <div class="pa-3">
                <div class="d-flex justify-content-between">
                  <!-- <div>
                    <p class="m-0" style="font-size: 16px; font-weight: 500">
                      Passenger
                    </p>
                  </div> -->
                  <div>
                    <p class="m-0" style="font-size: 16px; font-weight: 500">
                      Total Price
                    </p>
                  </div>
                  <div>
                    <p class="tot-price m-0">
                      {{ currency }} {{ totalAmountpassenger }}
                    </p>
                  </div>
                </div>
                <!-- <hr /> -->
                <div class="row mt-2">
                  <div class="col-md-12">
                    <div class="row justify-content-between">
                      <div class="col-md-6">
                        <span style="font-size: 14px; font-weight: 500"
                          >Passengers</span
                        >
                      </div>
                      <!-- <div class="col-md-4"></div> -->
                      <div class="col-md-6 f-size-14 text-end">
                        <div>
                          <span v-if="adultLength > 0"
                            >{{ adultLength }} x Adult</span
                          >
                        </div>
                        <div>
                          <span v-if="childLength > 0"
                            >{{ childLength }} x Child</span
                          >
                        </div>
                        <div>
                          <span v-if="infLength > 0"
                            >{{ infLength }} x Lap Infant</span
                          >
                        </div>
                      </div>
                      <!-- <div class="col-md-6">
                        <div class="tot-price m-0 text-end d-flex justify-end">
                          <span>{{ currency }} {{ totalAmountpassenger }}</span>
                          <span
                            ><v-icon
                              size="18"
                              class="not-collapsed ms-1"
                              data-bs-toggle="collapse"
                              href="#collapse-Example"
                              aria-expanded="true"
                              aria-controls="collapse-Example"
                              >mdi-information-outline</v-icon
                            ></span
                          >
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
                <!-- <hr> -->
                <div>
                  <hr class="mt-1 mb-2" />
                  <div class="d-flex align-end mb-2">
                    <span
                      class="not-collapsed"
                      data-bs-toggle="collapse"
                      href="#collapse-Example"
                      aria-expanded="true"
                      aria-controls="collapse-Example"
                      style="
                        padding-right: 5px;
                        cursor: pointer;
                        font-size: 14px;
                        letter-spacing: 1px;
                      "
                    >
                      <span style="font-weight: 600"
                        >Fare Summary<v-icon
                          size="18"
                          class="not-collapsed ms-1"
                          data-bs-toggle="collapse"
                          href="#collapse-Example"
                          aria-expanded="true"
                          aria-controls="collapse-Example"
                          >mdi-information-outline</v-icon
                        ></span
                      >
                    </span>
                  </div>

                  <hr class="mt-1 mb-2" />

                  <div
                    class="p-1 collapse"
                    :class="{ show: isIcon }"
                    id="collapse-Example"
                  >
                    <div class="row">
                      <div class="col-6">
                        Base Price<br />
                        Taxes & Fees
                      </div>
                      <!-- <div class="col-6" align="right">
                        {{ currency }} {{ totalbasePassanger }}<br />
                        {{ currency }} {{ totalTaxPassanger }}
                      </div> -->
                      <div class="col-6 f-size-14">
                        <div class="d-flex flex-row justify-end">
                          <div class="col-5 text-end pe">
                            {{ currency }} <br />
                            {{ currency }}
                          </div>
                          <div class="col-7 text-end pe-2">
                            {{ totalbasePassanger }}<br />
                            {{ totalTaxPassanger }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="d-flex align-end mb-2">
                  <span
                    class="not-collapsed"
                    style="
                      padding-right: 5px;
                      cursor: pointer;
                      font-size: 14px;
                      letter-spacing: 1px;
                    "
                  >
                    <a
                      class="baggage border-none"
                      style="
                        font-size: 14px;
                        color: rgb(13, 110, 253);
                        text-decoration: underline;
                      "
                    >
                      <span
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop1"
                        aria-controls="staticBackdrop1"
                        @click="getFareRules()"
                        >Baggage and Cancellation Rules</span
                      >
                    </a>
                    <div class="drawer-section">
                      <div class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" id="staticBackdrop1" aria-labelledby="staticBackdropLabel" style="width: 35% !important">
                      <div class="offcanvas-header d-flex justify-content-between" style="
                            background: #5293cb !important;
                            color: #fff !important;
                          "
                        >
                          <h5
                            class="offcanvas-title p-0"
                            id="staticBackdropLabel"
                          >
                            Baggage and Cancellation Rules
                          </h5>
                          <button
                            @click="closeBaggageRules"
                            type="button"
                            class="text-reset"
                            data-bs-dismiss="offcanvas"
                            aria-label="Close"
                            color="white"
                          >
                            <v-icon>mdi-close</v-icon>
                          </button>
                        </div>
                        <div class="offcanvas-body">
                          <v-card class="">
                            <v-tabs v-model="tab" align-tabs="center" stacked>
                              <v-tab value="tab-1" class="m-0">
                                <v-icon>mdi-book-cancel-outline</v-icon>
                                Cancellation Rules
                              </v-tab>
                              <v-tab value="tab-2">
                                <v-icon>mdi-bag-checked</v-icon>
                                Baggage
                              </v-tab>
                            </v-tabs>

                            <v-card-text class="pt-0">
                              <keep-alive>
                                <v-window v-model="tab">
                                  <div v-if="tab === 'tab-1'">
                                    <h6 class="mt-2">Cancellation Rules:</h6>
                                    <div class="p-2 cancellationRules">
                                      <div
                                        v-if="fareRulesContent"
                                        v-html="fareRulesContent"
                                      ></div>
                                      <p v-else class="my-1">
                                        Check with Airline websites for
                                        Cancellation and Change Rules
                                      </p>
                                    </div>
                                  </div>
                                  <div v-if="tab === 'tab-2'">
                                    <h6 class="mt-1">Baggage Rules:</h6>
                                    <div
                                      class="p-2 rounded"
                                      v-for="(data, index) of roundresult"
                                      :key="index"
                                    >
                                      <div>
                                        <div
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>Carry-On:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> Checked Bag:</span>
                                            <span
                                              v-if="data.depBaggage"
                                              class="ms-1"
                                            >
                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}

                                              {{
                                                data.depBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? "Pieces"
                                                  : "Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      <hr v-if="data.Return" />
                                      <div v-if="data.Return" class="mt-2">
                                        <div
                                          class="mt-1"
                                          style="
                                            font-size: 15px;
                                            font-weight: 500;
                                          "
                                        >
                                          {{
                                            data.Depature[
                                              data.Depature.length - 1
                                            ].Arrival.AirportCode
                                          }}
                                          -
                                          {{
                                            data.Depature[0].Departure
                                              .AirportCode
                                          }}
                                        </div>
                                       
                                        <div class="ms-2 mt-2">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-checked
                                              </v-icon></span
                                            >
                                            <span>Carry-On:</span>
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                        <div class="ms-2 mt-1">
                                          <div
                                            class="d-flex flex-row"
                                            style="
                                              font-size: 14px;
                                              font-weight: 400;
                                              margin-left: 5px;
                                            "
                                          >
                                            <span>
                                              <v-icon
                                                color="gray"
                                                style="color: gray !important"
                                              >
                                                mdi-bag-suitcase
                                              </v-icon></span
                                            >
                                            <span> Checked Bag:</span>
                                          
                                            <span class="ms-1">
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity || 0
                                              }}
                                              {{
                                                data.arrBaggage.PieceAllowance
                                                  .TotalQuantity > 1
                                                  ? "Pieces"
                                                  : "Piece"
                                              }}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="onewayresult">
                                      <div
                                        class="p-2 rounded"
                                        v-for="(data, index) of onewayresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Depature[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Depature[
                                                data.Depature.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On:</span>
                                              <span
                                                v-if="data.BaggageData"
                                                class="ms-1"
                                              >
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 0
                                                    ? 1 + " Piece"
                                                    : 1 + " Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag:</span>
                                              <span class="ms-1">
                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}

                                                {{
                                                  data.BaggageData
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? "Pieces"
                                                    : "Piece"
                                                }}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div v-if="multiresult.length > 0">
                                      <div
                                        class="border p-2 rounded"
                                        v-for="(data, index) of multiresult"
                                        :key="index"
                                      >
                                        <div>
                                          <div
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight1[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight1[
                                                data.Flight1.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData1.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="">
                                                {{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData1
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>

                                          <div class="ms-2 mt-2">
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight2" />
                                        <div v-if="data.Flight2" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight2[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight2[
                                                data.Flight2.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData2.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="">
                                                {{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData2
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight3" />
                                        <div v-if="data.Flight3" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight3[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight3[
                                                data.Flight3.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData3.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="">
                                                {{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData3
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                        <hr v-if="data.Flight4" />
                                        <div v-if="data.Flight4" class="mt-2">
                                          <div
                                            class="mt-1"
                                            style="
                                              font-size: 15px;
                                              font-weight: 500;
                                            "
                                          >
                                            {{
                                              data.Flight4[0].Departure
                                                .AirportCode
                                            }}
                                            -
                                            {{
                                              data.Flight4[
                                                data.Flight4.length - 1
                                              ].Arrival.AirportCode
                                            }}
                                          </div>
                                          <div class="ms-2 mt-2">
                                            <div
                                              class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span>
                                                <v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-checked
                                                </v-icon></span
                                              >
                                              <span>Carry-On: </span>
                                              <span class="">{{
                                                data.BaggageData4.PieceAllowance
                                                  .TotalQuantity > 0
                                                  ? 1 + " Piece"
                                                  : 1 + " Piece"
                                              }}</span>
                                            </div>
                                          </div>
                                          <div class="d-flex ms-2 mt-2">
                                            <div class="d-flex flex-row"
                                              style="
                                                font-size: 14px;
                                                font-weight: 400;
                                                margin-left: 5px;
                                              "
                                            >
                                              <span
                                                ><v-icon
                                                  color="gray"
                                                  style="color: gray !important"
                                                >
                                                  mdi-bag-suitcase
                                                </v-icon></span
                                              >
                                              <span> Checked Bag: </span>
                                              <span class="">
                                                {{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity || 0
                                                }}{{
                                                  data.BaggageData4
                                                    .PieceAllowance
                                                    .TotalQuantity > 1
                                                    ? " Pieces"
                                                    : " Piece"
                                                }}</span
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </v-window>
                              </keep-alive>
                            </v-card-text>
                          </v-card>
                        </div>
                      </div>
                    </div>
                  </span>
                </div> -->
                  <!-- <hr class="mt-1 mb-2" /> -->

                  <br />
                  <p style="font-size: 12px">
                    All fares are quoted in {{ metaCurrency }}. Some airlines
                    may charge baggage fees. Your credit/debit card may be
                    billed in multiple charges totaling the final total price.
                  </p>
                </div>

                <!-- <div>
                 <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop" @click="getFareRules()" aria-controls="staticBackdrop">
                  Baggage and Cancellation Rules
                </button>
                </div> -->
              </div>
            </v-card>

            <!-- <div class="mt-3 mb-3" align="center">
              <v-btn width="80%" height="40px" type="submit" style="
                  font-size: 16px;
                  font-weight: 500;
                  color: white;
                  text-transform: capitalize;
                  background-color: #162849;
                ">
                Continue</v-btn>
            </div> -->
          </div>
        </div>
      </div>
    </v-form>
  </v-container>

  <v-dialog v-model="dialog3" persistent>
    <div class="d-flex justify-center">
      <v-card class="p-4">
        <h3 class="text-center">OOPS!</h3>
        <h5>Your request cannot be processed at this moment.</h5>
        <h6>Please Search Another Flight.</h6>
        <div class="d-flex justify-space-evenly mt-3">
          <v-btn @click="dialog3 = false" color="#162849">Try Again</v-btn>

          <v-btn to="/" color="#162849">Return to Home</v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import airLogo from "@/airlinesLogo.json";
import axios from "axios";
import country1 from "@/coundtrycode.json";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  components: {
    // headerCom,
    // footerCom,
    // travellerDetail,
    VueDatePicker,
  },
  data() {
    return {
      shoppingid: "",
      metaCurrency: "",
      selectId: "",
      tab: null,
      portalId: "",
      bookingviewApi: "",
      fareRulesContent: "",
      airlogodata: [],
      portal: false,
      countrycode: [],
      portal_shopId: "",
      portal_offId: "",
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: "17%",
      booking_Id: "",
      formCheckBox: false,
      datenumber: false,
      contactCheck: true,
      couponsCheck: true,
      totalAmountpassenger: [],
      currency: "",
      totalTaxPassanger: [],
      totalbasePassanger: [],
      valid1: false,
      ffb: "",
      air: "Aeroplan-Air canada",
      Redress: "",
      Traveler: "",
      Redressnumber: true,
      result: "name",
      value: "1",
      dateOfBirth: "",
      dateFormat: "dd MMM yyyy",
      apivalue: true,
      dateData: [],
      yearData: [],
      portalData: [],
      mode: true,
      dialog3: false,

      passengersDatas: {
        adultDatas: [],
        childDatas: [],
        infrantDatas: [],
      },

      adultData: [],
      childData: [],
      infrantData: [],
      adult: "",
      child: "",
      inf: "",

      paxContacts: [
        {
          number: "",
          email: "",
        },
      ],

      emailRequire: [
        (v) => !!v || "This field is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],

      phoneNumberValid: [
        (v) => !!v || "Phone Number is required",
        (v) => /^\+?\d{5,15}$/.test(v) || "Invalid Phone Number",
      ],

      //     emailRequire: {
      //   required: (v) => !!v || 'Email is required',
      //   validEmail: (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email address'
      // }

      itineraryInfo: false,
      itineraryInfo1: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      flightInfo: false,
      flightDetail: false,
      bagInfo: false,
      baggageDetails: false,
      overlaySelector: false,
      valid: true,
      titleBox: true,
      roundresult: [],
      onewayresult: [],
      multiresult: [],
      isIcon: false,
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infLength: "",
      classed: "",
      fareItems: [],
      adultFare: [],
      childFare: [],
      infrantFare: [],
      adultprice: [],
      childprice: [],
      infprice: [],
      adulttax: [],
      childtax: [],
      inftax: [],
      totaltax: [],
      totalprice: [],
      totalamount: [],
      layoverTimes: [],
      portalResData: [],
      fareshow: false,
      // dobError:false,
      // clicked1: false,
      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      muticityData: [],
      dobAdultError: false,
      dobChildError: false,
      dobInfrantError: false,

      adultpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },
      chd: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      infpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      local_id: "",
      Localoneway_id: null,

      titles: ["Flight", "Passenger", "Request", "Free Text"],
      specialBox: [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ],
      selectFlight: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],
      selectPassanger: ["Adult 1"],
      selectRequest: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],

      flightValid: [(v) => !!v || "Field is required"],
      passengerValid: [(v) => !!v || "Field is required"],
      requestValid: [(v) => !!v || "Field is required"],
      // textValid:[v => !!v || 'Field is required'],

      firstName: [
        (v) => !!v || "Please enter your First Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      lastName: [
        (v) => !!v || "Please enter your Last Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      middleName: [
        (v) => !!v || "Please enter your Middle Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      getdepDate: "",
    };
  },
  methods: {
    getDurationTime(data) {
      console.log(data, "durationnnnnn");
      return data.FlightDetail.FlightDuration.Value;
    },

    flight1(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightdepature = true;
    },
    flight2(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightreturn = true;
    },
    closeDep(data) {
      data.Flightdepature = false;
    },
    closearr(data) {
      data.Flightreturn = false;
    },

    flight() {
      this.overlaySelector = true;
      this.baggageDetails = false;
      this.flightInfo = true;
      this.flightDetail = true;
      this.bagInfo = false;
    },
    overlay() {
      this.overlaySelector = false;
    },
    itinerary() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = true;
      this.baggageDetails = false;
      this.bagInfo = true;
    },
    baggage() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = false;
      this.baggageDetails = true;
      this.bagInfo = true;
    },
    closedialog() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    closedialog1() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    addBox() {
      if (this.valid && this.specialBox.length < 5) {
        this.specialBox.push({
          // fBox: null,
          pBox: "Adult 1",
          // rBox: null,
          // freeBox: null,
          ...this.specialBox,
        });
      }
      console.log(this.valid, "rest");
    },
    resetValues() {
      this.valid = false;
      this.specialBox = [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ];
    },

    getdepcode(data) {
      console.log(data, "getdepcode");
      return data[0].Departure.AirportCode;
    },
    getarrcode(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getFromCityName($event) {
      console.log($event, "oioioi");
      return $event.split("(")[0];
    },
    depdate(data) {
      this.getdepDate = moment(data[0].Departure.Date).format(
        "ddd, MMM DD, YYYY"
      );
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getStop(data) {
      return data.length - 1;
    },
    getflightname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getlogo($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getlogo1($event) {
      return $event[0].MarketingCarrier.logo;
    },

    getflightnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getflightid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    depairname(data) {
      return data[0].Departure.AirportName;
    },
    depairtime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    arrAirname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },
    arrAirtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    // ----return --------------

    getstart(data) {
      return data[0].Departure.AirportCode;
      // console.log(data[0].Departure.AirportCode, 'kkkkkkk...q.e.we.e.e.')
    },
    getend(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getToCityName($event) {
      console.log($event, "oioioi");
      return $event.split("(")[0];
    },
    getstartdate(data) {
      return moment(data[0].Departure.Date).format("ddd, MMM DD, YYYY");
    },
    getstop1(data) {
      return data.length - 1;
    },

    getstartname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getstartnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getstartid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    getdepname(data) {
      return data[0].Departure.AirportName;
    },
    getdeptime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getarrname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },

    getarrtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getdeparDate($event) {
      console.log($event, "$event$event$event$event");
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getdated($event) {
      return moment($event).format("MMM DD");
    },

    getdated1($event) {
      return moment($event).format("MMM DD");
    },
    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    reset() {
      (this.ffb = ""), (this.air = "");
    },
    reset1() {
      (this.Redress = ""), (this.Traveler = "");
    },
    added() {
      let result =
        this.adultData.length + this.childData.length + this.infrantData.length;
      if (this.paxContacts.length < result) {
        this.paxContacts.push({
          number: "",
          email: "",
        });
      }
    },
    delet(index) {
      this.paxContacts.splice(index, 1);
    },
    // add() {
    //     for (let i = 0; i < this.adultData.length; i++) {
    //         if (this.adultData.length < 1) {
    //             this.adultData.push({
    //                 fname: "",
    //                 last: "",
    //                 middle: "",
    //                 year: "",
    //                 date: "",
    //                 month: "",
    //                 gender: "",
    //                 salutation: "",
    //                 ffbnumber: false,
    //             })
    //         }
    //     }
    // },
    ffbdata(data, index) {
      console.log(index, "index");
      console.log(data, "12345");
      this.adultData.map((item, i) => {
        if (i == index) {
          item.ffbnumber = !item.ffbnumber;
        }

        return item;
      });
    },
    tsadata(index) {
      this.adultData.map((item, i) => {
        if (i == index) {
          item.TSAnumber = !item.TSAnumber;
        }

        return item;
      });
    },
    apidata(index) {
      this.apivalue = true;
      this.adultData.map((item, i) => {
        if (i == index) {
          item.Apinumber = !item.Apinumber;
        }

        return item;
      });
    },

    day() {
      for (let i = 1; i <= 31; i++) {
        this.dateData.push(i);
        // console.log(this.date, '9999999')
      }
    },
    year() {
      for (let i = 2023; i <= 2035; i++) {
        this.yearData.push(i);
        // console.log(this.yearData, '9999999')
      }
    },

    adultDob() {
      this.adultData.map((v) => {
        if (v.dateOfBirth) {
          console.log(v.dateOfBirth, "dobdob");

          v.clicked1 = true;
          v.dobAdultError = false;
          console.log(v.dobAdultError, "dobdob");
        } else {
          v.clicked1 = false;
          v.dobAdultError = true;
        }
      });
    },
    childDob() {
      this.childData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          v.dobChildError = false;
        } else {
          v.clicked1 = false;
          v.dobChildError = true;
        }
      });
    },
    infrantDob() {
      this.infrantData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          v.dobInfrantError = false;
        } else {
          v.clicked1 = false;
          v.dobInfrantError = true;
        }
      });
    },

    // ClickOutDepat() {
    //     this.$refs.dpRef1.closeMenu();

    //     if (this.oneway.dedate) {
    //         this.clicked1 = true;
    //     }
    //     else {
    //         this.clicked1 = false;
    //     }
    // },

    getData() {
      console.log(this.adultData, "this.adultthis.adult");
      for (let i = 0; i <= this.adultData.length; i++) {
        if (this.adultData.length < this.adult) {
          this.adultData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Adult",
            clicked1: false,
            dobAdultError: false,
          });
        } else {
          return this.adultData;
        }
      }
      console.log(this.adultData, "ssss");
    },

    getChild() {
      for (let i = 0; i <= this.childData.length; i++) {
        if (this.childData.length < this.child) {
          this.childData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mstr",
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Child",
            clicked1: false,
            dobChildError: false,
          });
        } else {
          return this.childData;
        }
      }
    },

    getinfrant() {
      for (let i = 0; i <= this.infrantData.length; i++) {
        if (this.infrantData.length < this.inf) {
          this.infrantData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mstr",
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Infrant",
            clicked1: false,
            dobInfrantError: false,
          });
        } else {
          return this.infrantData;
        }
      }
    },

    getadult() {
      this.roundresult.forEach((v) => {
        console.log(v, "tttooooo....");
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    onewayData() {
      this.onewayresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    multiData() {
      let res = this.local_id.split("-");
      let res1 = res[res.length - 1];
      let data_pass = [];
      data_pass = JSON.parse(localStorage.getItem(`${"pass"}-${res1}`));

      this.adultLength = data_pass.adult;
      this.childLength = data_pass.child;
      this.infLength = data_pass.inf;
      this.adult = data_pass.adult;
      this.child = data_pass.child;
      this.inf = data_pass.inf;
      console.log(data_pass, "data_pass");

      let totalpass = [];
      totalpass = this.adultLength + this.childLength + this.infLength;
      console.log(totalpass, "totalpass");

      this.multiresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = v.Taxprice;
        this.totalbasePassanger = v.Baseprice;
        this.totalAmountpassenger = v.TotalPrice;
      });
    },

    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "17%";
      }

      this.lastPosition = window.scrollY;
    },

    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.portalId = getConfigData1.payload.portal_configuration.portal_id;

        this.metaCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.bookingviewApi =
          getConfigData1.payload.portal_configuration.API_endpoints.meta_get_itinerary;
      }
    },
    // fareinsert() {
    //   this.insertFare.hit_id = this.booking_Id

    // },
    closeBaggageRules() {
      this.tab = "tab-1";
    },
    getFareRules() {
      let fareRequest = {
        request_type: "farerules",
        shopping_response_id: this.shoppingid,
        offer_id: this.selectId,
        portal_id: this.portalId,
      };
      console.log(fareRequest, "fareRequestssssss");
      axios
        .get("https://booking.traveloplex.com/api/farerules", {
          headers: {
            "Content-Type": "text/html",
          },
          params: fareRequest,
        })
        .then((response) => {
          this.fareRulesContent = response.data;
          // console.log(response.data, "fareRulesContentfareRulesContent.....")
        })

        .catch((error) => {
          console.log(error, "erroroor.....");
        });
    },
    submitform() {
      // this.fareinsert();
      let contact = {
        contactCheck: this.contactCheck,
        couponsCheck: this.couponsCheck,
      };
      localStorage.setItem("Travelcontact", JSON.stringify(contact));
      this.passengersDatas.adultDatas = this.adultData;
      this.passengersDatas.childDatas = this.childData;
      this.passengersDatas.infrantDatas = this.infrantData;

      if (this.valid1 && this.paxContacts[0].number && this.formCheckBox) {
        console.log(this.valid1, "ggghghghghg.....");
        this.datenumber = true;

        if (this.portal) {
          let uniDataId = `${"env"}-${this.local_id}`;
          localStorage.setItem(
            `${"env"}-${this.local_id}`,
            JSON.stringify(this.portalResData)
          );

          this.$router.push({
            path: "/confirmBook",
            query: { pass_id: uniDataId },
          });
        } else {
          if (this.local_id) {
            this.$router.push({
              path: "/confirmBook",
              query: { pass_id: this.local_id },
            });
          } else if (this.Localoneway_id) {
            this.$router.push({
              path: "/confirmBook",
              query: { pass_id1: this.Localoneway_id },
            });
          } else {
            console("Not....working....");
          }
        }
      } else {
        this.dobAdultError = true;
        this.dobChildError = true;
        this.dobInfrantError = true;
        this.datenumber = true;

        return this.valid1;
      }

      localStorage.setItem(
        "TravelerDetails",
        JSON.stringify(this.passengersDatas)
      );
      localStorage.setItem(
        "passengerContact",
        JSON.stringify(this.paxContacts)
      );
    },

    async portelDataConvert() {
      let userBooking = {
        offer_id: this.portal_offId,
        shopping_response_id: this.portal_shopId,
        pos: "US",
        api_env: "CERT",
        request_type: "user_booking_view",
      };

      console.log(userBooking, this.bookingviewApi, "userBookinguserBooking");

      await axios
        .post("https://booking.traveloplex.com/api/bookingview", userBooking, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((responce) => {
          console.log(responce.data, "responce.dataresponce.data");
          if (responce.data.view_response == "fail") {
            setTimeout(() => {
              this.dialog1 = true;
            }, 3000);
            console.log("dialog1111");
          } else {
            let result = responce.data;
            this.portalData.push(result);
            console.log(this.portalData, "this.portalDatathis.portalData");
            this.checkOutPage = true;
            this.dataloader = false;
            this.showConfirmPage = true;
            console.log("dialog11114444444444444");
          }
        })
        .catch((err) => {
          console.log(err, "ppppp5555555");
          setTimeout(() => {
            this.dialog3 = true;
          }, 3000);
        });

      if (this.portalData.length > 0 && (!this.portalData[0] == "" || null)) {
        this.dialog3 = false;
        console.log("sdfadfzxczxsdvasdfasdf");
        for (let i = 0; i < this.portalData.length; i++) {
          console.log(this.portalData[i], "power");

          let Journey = [];
          let flightsegment = [];
          Journey = this.portalData[i].DataLists.FlightList.Flight;
          console.log(Journey, "Journey");
          flightsegment =
            this.portalData[i].DataLists.FlightSegmentList.FlightSegment;
          let shoppingId = this.portalData[i].ShoppingResponseId;
          // console.log(flightsegment, "flightsegment");
          let $data = [];
          $data.push(this.portalData[i].OffersGroup.AirlineOffers.Offer);

          let source1 = [];
          let source2 = [];
          let source3 = [];
          let source4 = [];
          let adult = 0;
          let child = 0;
          let inf = 0;
          $data.forEach((v) => {
            v.OfferItem[0].forEach((t) => {
              if (t.PassengerType == "ADT") {
                adult = t.PassengerQuantity;
              }
              if (t.PassengerType == "CNN") {
                child = t.PassengerQuantity;
              }
              if (t.PassengerType == "INF") {
                inf = t.PassengerQuantity;
              }
              // else if(this.local_id.includes("multi")){
              //   this.$router.push({ path: "/confirmBook", query: { pass_uid: this.local_id } })
            });

            v.OfferItem[0][0].FareComponent.forEach((s, index) => {
              if (index == 0) {
                this.offerData1.push(s.SegmentRefs.split(" "));

                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source1.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 1) {
                this.offerData2.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source2.push(Journey[i].Journey);
                  }
                }
              }

              if (index == 2) {
                this.offerData3.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source3.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 3) {
                this.offerData4.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source4.push(Journey[i].Journey);
                  }
                }
              }
            });
          });
          console.log(flightsegment, "this.flightsegment");

          if (this.offerData1.length > 0) {
            for (let i = 0; i < this.offerData1.length; i++) {
              let seg = [];
              this.offerData1[i].forEach((f, index) => {
                let items = [];
                items = flightsegment.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == this.offerData1[i].length - 1) {
                  this.portalResData.push({ Depature: seg });
                }
              });
            }
          }

          if (this.offerData2.length > 0) {
            for (let i = 0; i < this.offerData2.length; i++) {
              let seg = [];
              this.offerData2[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData2[i].length - 1) {
                  this.Flight2.push(seg);
                }
              });
            }
          }

          if (this.offerData3.length > 0) {
            for (let i = 0; i < this.offerData3.length; i++) {
              let seg = [];
              this.offerData3[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData3[i].length - 1) {
                  this.Flight3.push(seg);
                }
              });
            }
          }

          if (this.offerData4.length > 0) {
            for (let i = 0; i < this.offerData4.length; i++) {
              let seg = [];
              this.offerData4[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData4[i].length - 1) {
                  this.Flight4.push(seg);
                }
              });
            }
          }

          for (let i = 0; i < this.airlogodata.length - 1; i++) {
            for (let j = 0; j < flightsegment.length - 1; j++) {
              if (
                flightsegment[j].MarketingCarrier.AirlineID ==
                this.airlogodata[i].id
              ) {
                flightsegment[j].MarketingCarrier.logo =
                  this.airlogodata[i].logo;
              }
            }
          }

          let currency = [];
          let Baseprice = [];
          let TotalPrice = [];
          let Tax = [];
          let perperson = [];
          let ownername = [];
          let ownerlogo = [];
          let offerId = [];

          console.log($data, "ppppppppppkeerthi");

          $data.forEach((v, i) => {
            console.log(v, i, "offeriddatataa");
            offerId.push(v.OfferID);
          });

          $data.forEach((s) => {
            if (s.ReqCurrency == "USD") {
              currency.push("US$");
            } else if (s.ReqCurrency == "CAD") {
              currency.push("CA$");
            } else {
              currency.push("US$");
            }
            ownername.push(s.OwnerName);
            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              if (s.Owner == this.airlogodata[i].id) {
                ownerlogo.push(this.airlogodata[i].logo);
              }
            }
            Baseprice.push(s.BasePrice.BookingCurrencyPrice);
            Tax.push(s.TaxPrice.BookingCurrencyPrice);
            perperson.push(s.PerPerson.BookingCurrencyPrice);
            TotalPrice.push(s.TotalPrice.BookingCurrencyPrice);
          });

          if (this.Flight3.length > 0) {
            console.log(
              this.Flight2[0][0].Departure.AirportCode,
              "this.Flight2[0].Departure.AirportCode"
            );
            this.muticityData = [
              {
                from: "",
                to: "",
              },
              {
                from: this.Flight2[0][0].Departure.AirportCode,
                to: this.Flight2[0][this.Flight2[0].length - 1].Arrival
                  .AirportCode,
              },
              {
                from: this.Flight3[0][0].Departure.AirportCode,
                to: this.Flight3[0][this.Flight3[0].length - 1].Arrival
                  .AirportCode,
              },
              {
                from: this.Flight4[0][0].Departure.AirportCode,
                to: this.Flight4[0][this.Flight4[0].length - 1].Arrival
                  .AirportCode,
              },
            ];
          }

          for (let i = 0; i < this.portalResData.length; i++) {
            this.portalResData[i].selectId = offerId[i];
            this.portalResData[i].currency = currency[i];
            this.portalResData[i].Baseprice = Baseprice[i];
            this.portalResData[i].TotalPrice = TotalPrice[i];
            this.portalResData[i].Taxprice = Tax[i];
            this.portalResData[i].perperson = perperson[i];
            this.portalResData[i].Return = this.Flight2[i];
            if (this.Flight3.length > 0) {
              this.portalResData[i].Flight1 = this.portalResData[0].Depature;
              this.portalResData[i].Flight2 = this.Flight2[i];
              this.portalResData[i].Flight3 = this.Flight3[i];
              this.muticityData[0].from =
                this.portalResData[0].Depature[0].Departure.AirportCode;
              this.muticityData[0].to =
                this.portalResData[0].Depature[
                  this.portalResData[0].Depature.length - 1
                ].Arrival.AirportCode;

              this.portalResData[i].muticityData = this.muticityData;
            }

            if (this.Flight4.length > 0) {
              this.portalResData[i].Flight4 = this.Flight4[i];
              // this.muticityData[3].from = this.Flight4[0][0].Departure.AirportCode;
              // this.muticityData[3].to = this.Flight4[0][this.Flight4[0].length - 1].Arrival.AirportCode;
              this.portalResData[i].muticityData = this.muticityData;
            }

            this.portalResData[i].ownerlogo = ownerlogo[i];
            this.portalResData[i].ownername = ownername[i];
            this.portalResData[i].Depaturejourney = source1[i];
            this.portalResData[i].Returnjourney = source2[i];
            this.portalResData[i].Flight1JourneyTime = source1[i];
            this.portalResData[i].Flight2JourneyTime = source2[i];
            this.portalResData[i].Flight3JourneyTime = source3[i];
            this.portalResData[i].Flight4JourneyTime = source4[i];
            this.portalResData[i].adult = adult;
            this.portalResData[i].child = child;
            this.portalResData[i].inf = inf;
            this.portalResData[i].ShoppingResponseId = shoppingId;
          }
          console.log(
            this.portalResData,
            "this.portalResDatathis.portalResData"
          );

          if (this.offerData3.length > 0 || this.offerData4.length > 0) {
            this.multiresult = this.portalResData;
            this.getmultitime1();
            console.log(this.portalResData, "this.portalResData");
            // this.itineraryInfo = true;
            // this.gettimeret();
            // this.gettimedep();
            // console.log(this.roundresult, "this.roundresult88");
          } else if (this.offerData2.length > 0) {
            this.roundresult = this.portalResData;
            this.itineraryInfo = true;
            this.gettimeret();
            this.gettimedep();
            console.log(this.roundresult, "this.roundresult88");
          } else if (this.offerData1.length > 0) {
            this.onewayresult = this.portalResData;
            console.log(this.onewayresult, "this.onewayresult5555");
            this.itineraryInfo1 = true;
            this.gettimedep1();
          }

          this.portalResData.forEach((v) => {
            console.log(v, "ppppp");
            this.local_id = v.selectId;
            this.currency = v.currency;
            this.adult = parseFloat(v.adult);
            console.log(this.adult, "pppp");
            this.adultLength = parseFloat(v.adult);
            this.childLength = parseFloat(v.child);
            this.child = parseFloat(v.child);
            this.inf = parseFloat(v.inf);
            this.infLength = parseFloat(v.inf);
            this.totalTaxPassanger = v.Taxprice;
            this.totalbasePassanger = v.Baseprice;
            this.totalAmountpassenger = v.TotalPrice;
          });
          this.getData();
          this.getChild();
          this.getinfrant();
          console.log(
            this.portalResData,
            "this.portalResDatathis.portalResData"
          );
        }
      } else {
        this.dialog3 = true;
        console.log("flightdata");
      }
    },

    gettimedep1() {
      for (let i = 0; i < this.onewayresult.length; i++) {
        for (let j = 0; j < this.onewayresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.onewayresult[i].Depature.length;

          const time1 = new Date(
            `${this.onewayresult[i].Depature[currentIndex].Arrival.Date}T${this.onewayresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.onewayresult[i].Depature[nextIndex].Departure.Date}T${this.onewayresult[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.onewayresult[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    gettimeret() {
      for (let i = 0; i < this.roundresult.length; i++) {
        // console.log(this.roundresult, "result222222222222222222222222");
        for (let j = 0; j < this.roundresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.roundresult[i].Return.length;

          const time1 = new Date(
            `${this.roundresult[i].Return[currentIndex].Arrival.Date}T${this.roundresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.roundresult[i].Return[nextIndex].Departure.Date}T${this.roundresult[i].Return[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.roundresult[i].Return[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },
    gettimedep() {
      for (let i = 0; i < this.roundresult.length; i++) {
        // console.log(this.roundresult, "result222222222222222222222222");
        for (let j = 0; j < this.roundresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.roundresult[i].Depature.length;

          const time1 = new Date(
            `${this.roundresult[i].Depature[currentIndex].Arrival.Date}T${this.roundresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.roundresult[i].Depature[nextIndex].Departure.Date}T${this.roundresult[i].Depature[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.roundresult[i].Depature[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }
      }
    },

    getmultitime1() {
      for (let i = 0; i < this.multiresult.length; i++) {
        for (let j = 0; j < this.multiresult[i].Flight1.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multiresult[i].Flight1.length;

          const time1 = new Date(
            `${this.multiresult[i].Flight1[currentIndex].Arrival.Date}T${this.multiresult[i].Flight1[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multiresult[i].Flight1[nextIndex].Departure.Date}T${this.multiresult[i].Flight1[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.multiresult[i].Flight1[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }

        for (let j = 0; j < this.multiresult[i].Flight2.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multiresult[i].Flight2.length;

          const time1 = new Date(
            `${this.multiresult[i].Flight2[currentIndex].Arrival.Date}T${this.multiresult[i].Flight2[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multiresult[i].Flight2[nextIndex].Departure.Date}T${this.multiresult[i].Flight2[nextIndex].Departure.Time}`
          );

          const diffInMilliseconds = Math.abs(time2 - time1);
          const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

          const hours = Math.floor(diffInMinutes / 60);
          const minutes = diffInMinutes % 60;

          this.multiresult[i].Flight2[currentIndex].layoverTimes = `${hours
            .toString()
            .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
        }

        if (this.multiresult[i].Flight3) {
          for (let j = 0; j < this.multiresult[i].Flight3.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multiresult[i].Flight3.length;

            const time1 = new Date(
              `${this.multiresult[i].Flight3[currentIndex].Arrival.Date}T${this.multiresult[i].Flight3[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multiresult[i].Flight3[nextIndex].Departure.Date}T${this.multiresult[i].Flight3[nextIndex].Departure.Time}`
            );

            const diffInMilliseconds = Math.abs(time2 - time1);
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;

            this.multiresult[i].Flight3[currentIndex].layoverTimes = `${hours
              .toString()
              .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
          }
        }

        if (this.multiresult[i].Flight4) {
          for (let j = 0; j < this.multiresult[i].Flight4.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multiresult[i].Flight4.length;

            const time1 = new Date(
              `${this.multiresult[i].Flight4[currentIndex].Arrival.Date}T${this.multiresult[i].Flight4[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multiresult[i].Flight4[nextIndex].Departure.Date}T${this.multiresult[i].Flight4[nextIndex].Departure.Time}`
            );

            const diffInMilliseconds = Math.abs(time2 - time1);
            const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

            const hours = Math.floor(diffInMinutes / 60);
            const minutes = diffInMinutes % 60;

            this.multiresult[i].Flight4[currentIndex].layoverTimes = `${hours
              .toString()
              .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
          }
        }
      }
    },

    //     async airlineLogo_func() {

    // let airlogoUrl = ""
    // let airlogoType = ""

    // airlogoUrl = this.airLogoApi.url
    // airlogoType = this.airLogoApi.type

    // await axios.get(airlogoUrl, {
    //   headers: {
    //     "Content-Type": airlogoType,
    //   },
    // })
    //   .then((response) => {
    //     if (response.data) {
    //       this.airline_logoData = response.data
    //       // console.log(this.airline_logoData, "ressloglooglooglooglool")
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error, "ressloerrrororooool")
    //   })
    // },
  },

  watch: {
    adultData: {
      deep: true,
      handler(newData) {
        if (newData) {
          console.log(newData, "newDatanewDatanewDatanewDatanewDatanewData");
          newData.forEach((data) => {
            if (data.salutation !== "Mr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }
          });
        } else {
          console.log(
            newData,
            "newDatanewDatanewDatanewDatanewDatanewDataelse"
          );
        }
      },
    },

    childData: {
      deep: true,
      handler(newData) {
        if (newData) {
          newData.forEach((data) => {
            if (data.salutation !== "Mstr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }
          });
        }
      },
    },

    infrantData: {
      deep: true,
      handler(newData) {
        newData.forEach((data) => {
          if (data.salutation !== "Mstr") {
            data.gender = "Female";
          } else {
            data.gender = "Male";
          }
        });
      },
    },

    "data.number"(newValue) {
      console.log(newValue, "ndnjdnjdnjnd");

      if (newValue.length <= 12 && newValue.length > 5) {
        this.data.number = newValue.slice(0, 12);
      }
    },

    // "data.number"(newValue) {

    //   if (newValue.length <= 12 && newValue.length >5) {
    //     this.data.number = newValue.slice(0, 12);
    //   }
    // },
  },

  mounted() {
    country1.forEach((v) => {
      let obj = {
        name: `${v.name}(${v.dial_code})`,
        code: v.code,
      };
      this.countrycode.push(obj);
    });

    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    // console.log(this.dateOfBirth, "dobdob.....");

    this.getData();
    this.getChild();
    this.getinfrant();
  },

  created() {
    this.getConfig();
    // setInterval(() => {
    //   location.reload();
    // }, 500);

    let $data = localStorage.getItem("loader");
    if ($data == "true") {
      this.$router.push("/");
    }

    this.local_id = this.$route.query.pass_id || this.$route.query.pass_uid;
    let data = location.href;
    this.Localoneway_id = this.$route.query.pass_id1;
    console.log(this.local_id, "this.local_idthis.local_id");

    if (data) {
      if (data.includes("env")) {
        this.portal = true;
        this.portal_offId = this.$route.query.pass_uid;
        this.portal_shopId = this.$route.query.response_id;
        this.airlogodata = airLogo;
        this.portelDataConvert();
      }
    }

    if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(
        localStorage.getItem(`${this.Localoneway_id}`)
      );
      if (onewaydata_uid) {
        this.onewayresult.push(onewaydata_uid);
        console.log(this.onewayresult, "onewayresultttttt");
        this.onewayData();
        this.itineraryInfo1 = true;
      } else {
        alert("Something went Wrong...");
        this.$router.push("/");
      }
    }

    if (this.local_id) {
      if (this.local_id.includes("multi")) {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));
        if (data_uid) {
          this.multiresult.push(data_uid);
          this.multiData();
          console.log(data_uid, "multicitymulticitymulticity");
        } else {
          console.log("Something went Wrong...");
          alert("Something went Wrong...");
          this.$router.push("/");
        }
      } else {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));

        if (data_uid) {
          this.roundresult.push(data_uid);
          console.log(this.roundresult, "this.roundresultthis.roundresult");
          this.getadult();
          this.itineraryInfo = true;
        }
        //  else {
        //   console.log("missingId...missingId...missingId..2222.");
        //   alert("Something went Wrong...");
        //   this.$router.push("/");
        // }
        if (this.roundresult.length > 0) {
          this.shoppingid = this.roundresult[0].ShoppingResponseId;
          this.selectId = this.roundresult[0].selectId;
        } else if (this.onewayresult.length > 0) {
          this.shoppingid = this.onewayresult[0].ShoppingResponseId;
          this.selectId = this.onewayresult[0].selectId;
        } else if (this.multiresult.length > 0) {
          this.shoppingid = this.multiresult[0].ShoppingResponseId;
          this.selectId = this.multiresult[0].selectId;
        }
      }
    }

    // else {
    //         this.$router.push("/")
    //         console.log("workiiiii....1")
    //       }
  },
};
</script>

<style scoped>
@import "@/assets/style.css";
>>> .v-card {
  overflow: unset;
  position: inherit !important;
}

>>> .v-input__details {
  display: block;
}

>>> .v-messages__message {
  font-size: 10px;
  line-height: 8px;
}

.f-size-20 {
  font-size: 20px;
}

.f-size-18 {
  font-size: 18px;
}
.f-size-16 {
  font-size: 16px;
}
.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.vue-tel-input {
  height: 40px;
}

.flight-btn1 {
  font-size: 16px;
  font-family: serif;
  font-weight: 400;
  color: black;
  cursor: pointer;
}

.p-error {
  font-size: 10px;
  color: #b00020;
  letter-spacing: 0.0333333333em;
  transition-duration: 150ms;
  position: relative;
  top: -21px;
}

>>> .spl-checkbox .v-label {
  font-size: 12px !important;
  font-weight: 600;
}

.active-line1 {
  height: 3px;
  background-color: red;
  width: 82.2px;
  position: absolute;
  left: 35px;
  border-radius: 90px 0px 0px 40px/14px 0px 0px 8px;
}

.active-line2 {
  height: 3px;
  background-color: red;
  width: 99px;
  position: absolute;
  left: 117.2px;
  border-radius: 0px 90px 40px 0px/0px 14px 8px 0px;
}

/* >>>.v-img__img {
z-index: -1;
position: absolute;
top: 0;
left: 0;
width: 70%;
height: 50%;
} */
/* .v-card {
  border-radius: 10px;
} */

.active1 {
  background-color: rgb(153, 190, 202);
  border-radius: 30px 0px 0px 30px;
  color: white;
}

.active2 {
  background-color: rgb(153, 190, 202);
  border-radius: 0px 30px 30px 0px;
  color: white;
}

/* Scroll Bar Styles */

.card-content::-webkit-scrollbar {
  width: 20px;
}

>>> .v-toolbar__content {
  height: 30px !important;
  padding: 2px 3px;
}

.card-content::-webkit-scrollbar-thumb {
  background: #4e4e4e;
  border-radius: 25px;
}

/* minimalist design */
.card-content::-webkit-scrollbar {
  width: 10px;
}

.card-content::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
}

.card-content::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136);
  border-radius: 10px;
}

/* striped lines */
.card-content::-webkit-scrollbar {
  width: 20px;
}

.card-content::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 5px;
}

/* animated  */
.card-content::-webkit-scrollbar {
  width: 8px !important;
}

.card-content::-webkit-scrollbar-track {
  background: darkblue;
}

.card-content::-webkit-scrollbar-thumb {
  background: lightgray;
}

/* Scroll Bar Styles End */

.card-content {
  max-height: 540px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px;
}

.flight-btn {
  cursor: pointer;
  background-color: blueviolet;
  color: white;
}

p.small-size {
  font-size: 14px;
}

.font-weight {
  font-weight: 500;
}

.line-height {
  padding-bottom: 15px;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.tool-span span {
  font-size: 18px;
  font-weight: 600;
}

.travel-date {
  font-size: 14px;
  font-weight: 600;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

.close-btn {
  min-width: 12%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.reset-btn {
  color: brown;
  cursor: pointer;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  box-shadow: none;
  text-transform: capitalize;
}

.addmore-btn {
  color: dodgerblue;
  cursor: pointer;
  width: 100px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  box-shadow: none;
  text-transform: capitalize;
}

.addmore-btn:hover {
  color: darkcyan;
}

>>> .v-selection-control .v-label {
  white-space: normal;
  word-break: break-word;
  height: 100%;
  width: 100%;
  font-size: 14px;
}

/* Timeline */

/* >>>.v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
} */

/* >>>.v-timeline-divider__inner-dot {
  border-radius: 15px;
} */

/* >>>.v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
} */

.v-timeline--vertical.v-timeline {
  height: auto;
}

.card-space {
  margin: 5px 0px;
  /* border-top: 4px solid rgba(42, 58, 88, 0.84); */
}

.fare-summery {
  position: sticky;
  padding: 10px 0px;
  width: 100%;
  z-index: 1;
  transition: top 0.5s;
}

.fare-summery-btn {
}

.ffb-button {
  color: #0748ac !important;
  border: 1px solid #0748ac !important;
  margin-left: 10px;
  height: 40px !important;
}

.ffb-button:hover {
  color: #fff !important;
  background: #0b446d !important;
  border: 1px solid #0b446d;
}

.ffb-button:focus {
  color: #fff !important;
  background: #0b446d !important;
  border: 1px solid #0b446d;
}

.ffb-button:active {
  color: #fff !important;
  background: #0b446d !important;
  border: 1px solid #0b446d;
}

.ffb-cart {
  box-shadow: none;
  background-color: #f6f6f6;
  padding: 10px 20px !important;
  margin-top: 10px;
}

.redress {
  cursor: pointer;
  color: #162849;
}

.redress:hover {
  cursor: pointer;
  color: #0b446d;
}

.v-input__control {
  height: 45px !important;
}

.v-text-field .v-field--active input {
  opacity: 1;
  font-size: 13px;
  font-weight: 500;
  position: relative;
  top: -6px;
}

.v-field--center-affix .v-label.v-field-label {
  top: 39%;
  transform: translateY(-50%);
}

.v-selection-control .v-label {
  height: auto;
}

/* Datepicker Style Section */

.v-card {
  overflow: unset;
}

>>> .dp__input_icon {
  display: none;
}

.v-icon {
  width: 100%;
}

>>> .dp__input {
  height: 42px;
  border-color: darkgrey;
  border-radius: 4px !important;
  padding: 0px 20px;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  color: grey;
}

>>> .dp__menu_inner {
  padding: var(--dp-menu-padding);
  box-shadow: 0px 0px 4px 0px black;
  background-color: #f5f5f521;
}

.calendar-container {
  display: flex;
  align-items: center;
  position: relative;
}

.label-top {
  color: transparent;
  background: transparent;
}

.datepicker-label {
  position: relative;
  top: 10px;
  left: 6px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 11px;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
  background: white;
}

.label-top2 {
  position: absolute;
  top: 0px;
  left: -8px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 24px;
  left: -8px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.multiLabel-top {
  position: absolute;
  right: 87px;
  transform: translateY(-100%);
  background-color: white;
  padding: 0 8px;
  font-size: 12px;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute;
  right: 69px;
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

>>> .v-input__control {
  height: 40px;
  display: flex;
  grid-area: control;
}

>>> .dp__input {
  height: 40px;
  border-color: darkgray;
}

>>> .v-input__control {
  height: 40px;
}

>>> .v-field__field {
  height: 40px;
}

>>> .v-field__input {
  padding-top: 0 !important;
}

.delete-icon {
  display: flex;
  justify-content: end;
  position: absolute;
  left: 12px;
  z-index: -1;
}

.p-float-label-1 {
  position: relative;
  top: -24px;
}

.p-float-label-2 {
  position: relative;
  top: -25px;
}

.dob-label-top {
  position: relative;
  pointer-events: none;
  top: 11px;
  left: 12px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  font-size: 12px;
  color: gray;
  z-index: 1;
  background: white;
  padding: 0px 3px;
  font-weight: 400;
}

.dob-label {
  position: relative;
  pointer-events: none;
  top: 31px;
  left: 18px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  font-size: 1rem;
  color: gray;
  z-index: 1;
  background: white;
  /* padding: 0px 3px; */
  font-weight: 400;
}

.dob-label-1 {
  visibility: hidden;
}
/* >>>.dp__main{
    top:-20px;
} */
/* Timeline End */

.tot-price {
  font-size: 18px;
  font-weight: 600;
}
@media only screen and (min-width: 768px) {
  .fare-show {
    display: none;
  }

  .continue-btn {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .fare-show {
    display: flex !important;
    justify-content: center;
  }
  .fare-show v-card {
    max-width: 0px;
  }
  >>> .spl-checkbox .v-input__details {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .fare-show {
    display: block;
  }
}

@media only screen and (max-width: 769px) {
  .fare-summery {
    display: none;
  }
  .fare-summery1 {
    display: none;
  }
}

@media only screen and (min-width: 481px) {
  /* .fare-show {
    display: none;
  } */

  .continue-btn {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  /* .fare-summery {
    display: none;
  } */

  .airline-logo .v-img {
    margin: 0 auto;
  }

  .airline-profile {
    text-align: center;
  }

  .agree-content {
    font-size: 11px;
  }

  .card-space {
    margin: 2px;
  }

  .font-change-14 {
    font-size: 14px;
  }

  .font-change-11 {
    font-size: 11px;
  }

  .layover-size {
    width: 50% !important;
  }

  .lay-fsize {
    font-size: 9px;
  }
}

@media only screen and (max-width: 767px) {
  .class-box {
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 1200px) {
  .tot-price {
    font-size: 16px;
    font-weight: 600;
  }

  .lay-fsize {
    font-size: 9px;
  }
}

@media only screen and (max-width: 768px) {
  .price .price-content {
    text-align: left !important;
  }

  .card-box .spaced {
    padding: 5px 12px !important;
  }

  .tool-span span {
    font-size: 12px;
    font-weight: bold;
    padding: 0px 3px;
  }

  .sub-section {
    border-left: none !important;
  }

  .sub-section.row {
    padding-left: 0px !important;
  }

  .tot-price {
    font-size: 18px;
    font-weight: 500;
  }

  .airline-logo {
    padding: 0;
  }

  /* .airline-logo .v-img {
    width: 20px !important;
  } */

  .airline-profile {
    padding: 0;
  }

  .airline-name {
    font-size: 8px;
  }

  .airline-id {
    font-size: 10px;
  }

  .aircode-name {
    font-size: 11px;
  }

  .airline-date {
    font-size: 8px;
  }

  .airline-time {
    font-size: 10px;
  }

  .class-type .v-icon {
    font-size: 11px !important;
  }

  .class-type span {
    font-size: 9px;
  }
}

>>> .v-autocomplete--single .v-field--variant-outlined input {
  top: 33%;
}
@media only screen and (max-width: 480px) {
  .class-box {
    justify-content: start !important;
  }
  .trip-text {
    text-align: start !important;
  }
}
.v-container {
  max-width: 1020px !important;
}
.duration-data .f-size-14 {
  font-size: 14px;
}
.time-data .f-size-20 {
  font-size: 20px;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .duration-data .f-size-14 {
    font-size: 13px;
  }
}
@media screen and (max-width: 1360px) {
  .duration-data .f-size-14 {
    font-size: 13px;
  }
  .time-data .f-size-20 {
    font-size: 18px;
  }
}
>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: center !important;
}

>>> .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>> .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #002d5b;
}
>>> .v-tabs--density-default.v-tabs--stacked {
  --v-tabs-height: 58px;
}
>>> .v-tabs--fixed-tabs .v-slide-group__content > *:last-child,
.v-tabs--align-tabs-center .v-slide-group__content > *:last-child {
  margin-inline-end: inherit;
}
/* 
>>>.checked-box .mdi-checkbox-blank-outline{
color:#B00020;
} */
>>> .v-label {
  line-height: 1.4 !important;
}
>>>.mrdata .v-field--appended{
    padding-inline-end: 4px;
}
</style>
