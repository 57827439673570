<template>

    <div class="p-3" style="background-color:#ffffffd9">

        <!-- <section class="newsletter-wrapper footer-inner-wrap d-flex position-relative">
            <div class="container mt-3 text-center">
                <h2 class="title text-uppercase">About us</h2>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-center">
                            <p>Thank you for visiting BuyMyTrip.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <section v-html="aboutContent"></section>
  </div>


    <!-- <div style="background-color:#ffffffd9">

        <section class="newsletter-wrapper footer-inner-wrap d-flex position-relative">
            <div class="container mt-3 text-center">
                <h2 class="title text-uppercase">About us</h2>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-center">
                            <p>Thank you for visiting BuyMyTrip.com</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="footr-content-wrap">
            <div>
                <div class="container">
                    <h4><strong>Company</strong></h4>

                    <p>BuyMyTrip is one of the fastest growing online travel company in Canada. With our dedicated team of
                        skilled software engineers and travel specialist we decided to take our expertise to the web and
                        develop amazing software to provide a full range of travel products online.</p>

                    <p>We provide Instant E-tickets with the cheapest possible price with no hidden fees or no extra
                        charges. We are always there, through your entire journey for any customer support needed.</p>

                    <p>&nbsp;</p>

                    <h4><strong>Technology</strong></h4>

                    <p>BuyMyTrip has direct access to many wholesalers, airlines and hotel suppliers in Canada. The main aim
                        is to simplify the booking procedure and to eliminate the need to compare prices. You can stay up to
                        date on schedule changes, alerts or any details regarding your upcoming booking through your mobile
                        devices. We are constantly working towards getting the best products indicating a right balance of
                        Quality and Cost.</p>

                    <p>&nbsp;</p>

                    <h4><strong>Holidays</strong></h4>

                    <p>We offer an extensive range of great holiday package of unrivalled value and quality, whether you're
                        looking for a City break, a sunshine getaway or a sporting holiday. Our travel packages make sure
                        that every holiday is packed with unparalleled fun with the extraordinary moments especially for you
                        which you will cherish for a life time. We are constantly improvising our packages to keep abreast
                        of the changing times and provide a fun filled holiday.</p>

                    <p>&nbsp;</p>

                    <h4><strong>Stay</strong></h4>

                    <p>By booking on BuyMyTrip you save your valuable time and money. We are connected globally with over
                        110,000 hotels. We cover a wide range of hotels be it a basic value hotel, a 5 star luxury resort or
                        independent boutique.</p>

                    <h4><strong>Insurance Services</strong></h4>

                    <p>We also offer travel insurance across with one of the world's largest insurance providers, AIG.</p>
                </div>
            </div>
        </section>
        <br>
    </div> -->
</template>


<script>

import axios from "axios";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    data() {
        return {

            aboutContent:"",
            getAboutUrl: "",
            aboutType: "",
        }
    },

    methods: {

        getConfig() {
            // let getConfigData = this.$store.state.data

            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData = JSON.parse(portalLocal)

            const getConfigData = getConfigDataFromLocalStorage();
            if (getConfigData) {
                this.getAboutUrl = getConfigData.payload.portal_configuration.content_data.aboutus.url
                this.aboutType = getConfigData.payload.portal_configuration.content_data.aboutus.type
                // console.log(getConfigData, 'head...about..1..')
                // console.log(this.getAboutUrl, 'head...about..2..')

                this.getConfigFunc();
            }
        },

        getConfigFunc() {
            axios.get(this.getAboutUrl, {
                headers: {
                    "Content-Type": this.aboutType,
                },
            })
                .then((response) => {

                    this.aboutContent = response.data
                    console.log(response.data, "getAboutUrlrgetAboutUrlrgetAboutUrlrab.....")

                })

                .catch((error) => {
                    console.log(error, 'erroroor.....')
                })
        }
    },

    created() {
            this.getConfig();
            this.getConfigFunc();
    }
}

</script>