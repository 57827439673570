import { createApp } from 'vue'
import App from './App.vue'
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import PrimeVue from 'primevue/config';
import router from "./router/route.js";
import i18n from './plugins/i18n'; 

loadFonts();

createApp(App)
.use(router)
.use(vuetify)
.use(PrimeVue)
.use(i18n)
.mount('#app')
