<template>
  <div id="loading-center">
                <div class="loader">
                    <div class="loader-outter"></div>
                    <div class="loader-inner"></div>
                </div>
            </div>
</template>

<script>
export default {

}
</script>

<style>
@import '@/assets/style.css';

</style>