<template>
  <!-- preloader -->
  <!-- <div v-if="preloader">
            <div id="loading-center">
                <div class="loader">
                    <div class="loader-outter"></div>
                    <div class="loader-inner"></div>
                </div>
            </div>
        </div> -->
  <!-- preloader-end -->

  <!-- Scroll-top -->
  <!-- <button class="scroll-top scroll-to-target" data-target="html">
            <i class="fas fa-angle-up"></i>
        </button> -->
  <!-- Scroll-top-end-->

  <!-- main-area -->
  <main>
    <!-- slider-area -->
    <section class="slider-area">
      <div class="slider-active">
        <div
          class="single-slider slider-bg d-flex flex-column"
          data-background="assets/img/slider/slider_bg01.jpg"
        >
          <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="slider-content text-center">
                  <h2 class="title" data-animation="fadeInUp" data-delay=".2s">
                    Travel far enough, you meet yourself.
                  </h2>
                  <p data-animation="fadeInUp" data-delay=".4s">
                    Explore top destinations and exclusive deals for your next
                    adventure!
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- flight booking -->

          <div class="booking-area">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="booking-tag">
                    <ul>
                      <li>
                        <a href="#" class="act"
                          ><i class="fa-solid fa-plane"></i>Flights</a
                        >
                      </li>
                      <!-- <li>
                        <a href="#"
                          ><i class="fa-solid fa-umbrella-beach"></i>Holidays</a
                        >
                      </li>
                      <li>
                        <a href="#"
                          ><i class="fa-solid fa-plane-departure"></i>Flights
                          and Stays</a
                        >
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-bed"></i>Stays</a>
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-bus"></i>Tours</a>
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-ship"></i>Cruises</a>
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-tag"></i>Deals</a>
                      </li>
                      <li>
                        <a href="#"><i class="fa-solid fa-car"></i>Cars</a>
                      </li> -->
                    </ul>
                  </div>
                  <div class="booking-wrap">
                    <ul
                      class="nav nav-tabs"
                      id="myTab"
                      role="tablist"
                      style="
                        background: black;
                        border-radius: 20px 20px 0px 0px;
                      "
                    >
                      <li class="nav-item" role="presentation">
                        <button
                          @click="
                            (airbooking = true),
                              (mytrips = false),
                              (resentSearch = false)
                          "
                          :class="airbooking ? 'true-btn' : 'false-btn'"
                          style="border-top-left-radius: 20px"
                        >
                          <i class="fa-solid fa-plane me-1"></i> air BOOKing
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          @click="
                            (airbooking = false),
                              (mytrips = true),
                              (resentSearch = false)
                          "
                          :class="mytrips ? 'true-btn' : 'false-btn'"
                        >
                          <i class="fa-regular fa-file-lines me-1"></i>
                          my trips
                        </button>
                      </li>

                      <li class="nav-item" role="presentation">
                        <button
                          @click="
                            (airbooking = false),
                              (mytrips = false),
                              (resentSearch = true)
                          "
                          :class="resentSearch ? 'true-btn' : 'false-btn'"
                        >
                          <i class="fa-solid fa-plane me-1"></i>
                          Recent Search
                        </button>
                      </li>

                      <!-- <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="trips-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#trips-tab-pane"
                          type="button"
                          role="tab"
                          aria-controls="trips-tab-pane"
                          aria-selected="false"
                        >
                          <i class="fa-regular fa-file-lines"></i>my trips
                        </button>
                      </li> -->
                      <!-- <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="check-tab"
                                         data-bs-toggle="tab" data-bs-target="#check-tab-pane"
                                         
                                         
                                         type="button"
                                            role="tab" aria-controls="check-tab-pane" 
                                            aria-selected="false">
                                            <i class="fa-regular fa-circle-check"></i> check-in</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="flight-tab" data-bs-toggle="tab" data-bs-target="#flight-tab-pane" type="button"
                                            role="tab" aria-controls="flight-tab-pane" aria-selected="false">
                                            <i class="flaticon-clock"></i> Flight status</button>
                                    </li> -->
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div v-if="airbooking">
                        <div class="row">
                          <div class="col-lg-12" style="text-align: left">
                            <div class="tab-content-wrap">
                              <div class="content-top mb-2 row">
                                <div class="col-md-5 col-12">
                                  <v-radio-group v-model="radios" inline>
                                    <v-radio
                                      label="RoundTrip"
                                      value="one"
                                      @click="act(1)"
                                    ></v-radio>
                                    <v-radio
                                      label="Oneway"
                                      value="two"
                                      @click="act(2)"
                                    ></v-radio>
                                    <v-radio
                                      label="Multicity"
                                      value="three"
                                      @click="act(3)"
                                    ></v-radio>
                                  </v-radio-group>
                                </div>
                                <div v-if="multicity" class="col-md-2 col-5">
                                  <div
                                    class="input-styles-2 select-option rounded-border mt-1"
                                  >
                                    <v-select
                                      v-model="classType"
                                      ref="mySelect"
                                      class="select-2"
                                      :items="[
                                        'Economy',
                                        'Business',
                                        'First Class',
                                      ]"
                                      variant="underlined"
                                      style="color: black"
                                    ></v-select>
                                  </div>
                                </div>
                                <div v-if="multicity" class="col-md-3 col-7">
                                  <div
                                    class="input-styles-2 select-option rounded-border mt-1"
                                    style="height: 30px"
                                  >
                                    <v-select
                                      v-model="selectedCountry"
                                      :items="countries"
                                      ref="countrySelect"
                                      variant="underlined"
                                      style="position: relative; top: 0px"
                                      class="select-1"
                                    >
                                      <!-- <template #item="{ item }"> -->
                                      <template #item="{}">
                                        <div
                                          class="dropdown_section"
                                          style="cursor: pointer"
                                        >
                                          <div
                                            class="row d-flex align-center my-2 mx-1"
                                            style="height: 37px"
                                            @click.stop
                                          >
                                            <div
                                              class="col-3"
                                              style="width: 38%"
                                            >
                                              <span
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 500;
                                                  color: #4e4d4d;
                                                "
                                                >Adult</span
                                              >
                                            </div>
                                            <div
                                              class="col-3 px-0"
                                              style="width: 9%"
                                            >
                                              <v-icon
                                                style="
                                                  width: auto;
                                                  color: grey;
                                                  font-size: 18px;
                                                "
                                              >
                                                mdi-human-male-female
                                              </v-icon>
                                            </div>
                                            <div
                                              class="col-6 adult"
                                              style="width: 50%; height: auto"
                                            >
                                              <div
                                                class="row"
                                                style="padding: 0px"
                                              >
                                                <div
                                                  class="col-4 formbuttons"
                                                  @click="deg1()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    -
                                                  </div>
                                                </div>
                                                <div
                                                  class="col-4"
                                                  style="
                                                    background-color: white;
                                                  "
                                                  align="center"
                                                >
                                                  <div>{{ aduls }}</div>
                                                </div>
                                                <div
                                                  class="col-4 formbuttons"
                                                  @click="add1()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    +
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- <div>{{ item.name }}</div> -->
                                          </div>

                                          <div
                                            class="row d-flex align-center my-2 mx-1"
                                            @click.stop
                                          >
                                            <div
                                              class="col-4 d-flex flex-column pe-0"
                                              style="width: 38%"
                                            >
                                              <span
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 500;
                                                  color: #4e4d4d;
                                                "
                                                >Children</span
                                              >
                                              <span
                                                style="
                                                  font-size: 11px;
                                                  color: #4e4d4d;
                                                "
                                                >2-11(yrs)</span
                                              >
                                            </div>
                                            <div
                                              class="col-2 px-0"
                                              style="width: 9%"
                                            >
                                              <v-icon
                                                style="
                                                  width: auto;
                                                  color: grey;
                                                  font-size: 18px;
                                                "
                                              >
                                                mdi-human-child
                                              </v-icon>
                                            </div>
                                            <div
                                              class="col-6 adult"
                                              style="width: 50%"
                                            >
                                              <div
                                                class="row"
                                                style="padding: 0px"
                                              >
                                                <div
                                                  class="col-4 formbuttons"
                                                  @click="deg2()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    -
                                                  </div>
                                                </div>
                                                <div
                                                  class="col-4"
                                                  style="
                                                    background-color: white;
                                                  "
                                                  align="center"
                                                >
                                                  <div>{{ aduls1 }}</div>
                                                </div>
                                                <div
                                                  class="col-4 formbuttons"
                                                  @click="add2()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    +
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <!-- <div>{{ item.name }}</div> -->
                                          </div>

                                          <div
                                            class="row d-flex align-center my-2 mx-1"
                                            @click.stop
                                          >
                                            <div
                                              class="col-5 d-flex flex-column pe-0"
                                              style="width: 38%"
                                            >
                                              <span
                                                style="
                                                  font-size: 14px;
                                                  font-weight: 500;
                                                  color: #4e4d4d;
                                                "
                                                >Lap Infant</span
                                              >
                                              <span
                                                style="
                                                  font-size: 11px;
                                                  color: #4e4d4d;
                                                "
                                                >2(yrs)</span
                                              >
                                            </div>

                                            <div
                                              class="col-1 ps-0"
                                              style="width: 9%"
                                            >
                                              <v-icon
                                                style="
                                                  width: auto;
                                                  color: grey;
                                                  font-size: 18px;
                                                "
                                              >
                                                mdi-baby-buggy
                                              </v-icon>
                                            </div>
                                            <div
                                              class="col-6 adult"
                                              style="width: 50%"
                                            >
                                              <div
                                                class="row"
                                                style="padding: 0px"
                                              >
                                                <div
                                                  class="col-4 formbuttons"
                                                  @click="deg3()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    -
                                                  </div>
                                                </div>
                                                <div
                                                  class="col-4"
                                                  style="
                                                    background-color: white;
                                                  "
                                                  align="center"
                                                >
                                                  <div>{{ aduls2 }}</div>
                                                </div>
                                                <div
                                                  class="col-4 formbuttons"
                                                  @click="add3()"
                                                  style="
                                                    background-color: #26225e;
                                                    color: white;
                                                  "
                                                >
                                                  <div class="adult-plus">
                                                    +
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div
                                            class="mt-4 pe-3 d-flex justify-end"
                                            @click="
                                              (travels = false),
                                                localdone(),
                                                closeDropdown()
                                            "
                                          >
                                            <v-btn
                                              rounded="3"
                                              color="#26225e"
                                              width="100px"
                                              height="30px"
                                              style="color: white"
                                            >
                                              Done
                                            </v-btn>
                                          </div>
                                        </div>
                                      </template>

                                      <template #selection="{}">
                                        <div class="pax-fonts d-flex">
                                          <span
                                            style="
                                              color: grey;
                                              font-size: 14px;
                                              font-weight: 500;
                                            "
                                            >{{ resultData }} Passenger(s)</span
                                          >
                                          <!-- <span>, {{ aduls1 }} Ch</span>
                                                                <span>, {{ aduls2 }} In</span> -->
                                        </div>
                                      </template>
                                    </v-select>
                                  </div>
                                </div>
                              </div>
                              <div v-if="rount || Oneway">
                                <form action="#" class="booking-form">
                                  <ul
                                    class="py-2 ps-2 row flex-row justify-content-between mobileForm"
                                    style="
                                      background-color: rgb(227 225 223);
                                      margin: 0;
                                    "
                                  >
                                    <li class="col-md-3 col-12 ps-2">
                                      <div class="form-grp">
                                        <div class="labelTop">
                                          <p class="m-0 pt-2 ps-2">From</p>
                                        </div>
                                        <div
                                          class="d-flex input-styles rounded-border"
                                        >
                                          <span class="p-float-label">
                                            <!-- <AutoComplete
                                              v-model="oneway.from"
                                              optionLabel="name"
                                              dropdown
                                              forceSelection
                                              :suggestions="filteredCountries"
                                              @complete="search"
                                              @change="onChangeFrom()"
                                              @click="fromClick(oneway)"
                                              ref="fromAutoComplete"
                                              class="mb-2"
                                              style="
                                                width: 100%;
                                                height: 100%;
                                                font-size: 16px;
                                                font-weight: 600;
                                                top: -15px;
                                              "
                                            >
                                              <template #option="slotProps">
                                                <div
                                                  class="flex align-items-center dropdown-box"
                                                  @click="focus1()"
                                                >
                                                  <div>
                                                    {{
                                                      slotProps.option
                                                        .originalName
                                                    }}
                                                  </div>
                                                </div>
                                              </template>
                                            </AutoComplete> -->

                                            <div class="vas-autocomplete">
                                              <input
                                                type="text"
                                                v-model="inputCity"
                                                @keyup="typeCity(inputCity, 1)"
                                                class="inter-input"
                                                @click="
                                                  (inputCity = ''),
                                                    (formres = true),
                                                    (formres1 = false);
                                                  city1 = [];
                                                "
                                              />
                                              <!-- {{
                                                console.log(
                                                  inputCity,
                                                  "inputCityinputCity"
                                                )
                                              }} -->
                                              <!-- <div
                                                @click="onewaySwap"
                                                class="pos-swap"
                                              >
                                                <v-icon
                                                  size="30"
                                                  color="white"
                                                  :class="{
                                                    rotateSwap: !swapData,
                                                    normalSwap: swapData,
                                                  }"
                                                  >mdi-swap-horizontal</v-icon
                                                >
                                              </div> -->
                                              <!-- {{ console.log(this.swapData, "this.formres") }} -->
                                              <div v-if="typedrop">
                                                <div
                                                  class="autocompete-menual-dropdown"
                                                  v-if="this.city.length > 0"
                                                >
                                                  <div
                                                    v-for="(
                                                      data, index
                                                    ) in city"
                                                    :key="index"
                                                  >
                                                    <div
                                                      @click="datachange(data)"
                                                      :style="
                                                        index == city.length - 1
                                                          ? ''
                                                          : 'border-bottom:1px solid lightgray'
                                                      "
                                                      class="d-flex va-back"
                                                    >
                                                      <div
                                                        style="
                                                          display: flex;
                                                          align-items: center;
                                                        "
                                                      >
                                                        <v-icon
                                                          style="
                                                            transform: rotate(
                                                              45deg
                                                            );
                                                          "
                                                          color="gray"
                                                          size="20"
                                                        >
                                                          {{
                                                            data.location ===
                                                            "airport"
                                                              ? "mdi-airplane"
                                                              : "mdi-radiobox-marked"
                                                          }}</v-icon
                                                        >
                                                      </div>

                                                      <div
                                                        class="m-1 va-content"
                                                      >
                                                        {{ data.iata }},{{
                                                          data.city
                                                        }},{{ data.name }}
                                                      </div>
                                                    </div>
                                                    <!-- -------group--- -->

                                                    <div
                                                      v-if="
                                                        data.group_airports
                                                          .length > 0
                                                      "
                                                    >
                                                      <div
                                                        v-for="group in data.group_airports"
                                                        :key="group"
                                                      >
                                                        <div
                                                          v-if="
                                                            data.group_airport_city !=
                                                            group.iata
                                                          "
                                                        >
                                                          <div
                                                            class="d-flex va-back"
                                                            @click="
                                                              datachange(group)
                                                            "
                                                          >
                                                            <div
                                                              style="
                                                                display: flex;
                                                                align-items: center;
                                                              "
                                                              class="ms-4"
                                                            >
                                                              <v-icon
                                                                style="
                                                                  transform: rotate(
                                                                    45deg
                                                                  );
                                                                "
                                                                color="gray"
                                                                size="15"
                                                                >mdi-airplane</v-icon
                                                              >
                                                            </div>

                                                            <div
                                                              class="m-1 va-content"
                                                            >
                                                              {{
                                                                group.iata
                                                              }},{{
                                                                group.city
                                                              }},{{
                                                                group.name
                                                              }}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <!-- <label
                                              for="ac"
                                              class="fromLabel"
                                              style="top: -27px"
                                              >Leaving From</label
                                            > -->
                                          </span>
                                        </div>

                                        <div class="text-start">
                                          <span
                                            class="p-error"
                                            v-if="fromErrorMessage"
                                            >Departure is required</span
                                          >
                                          <br
                                            v-if="
                                              fromErrorMessage && maximumletter
                                            "
                                          />
                                          <span
                                            class="p-error"
                                            v-if="maximumletter"
                                            >Enter minimum 3 letters</span
                                          >
                                        </div>

                                        <!-- <div v-if="formres">
                                          <div
                                            v-if="resultFrom.length"
                                            class="recent_style"
                                          >
                                            <v-card
                                              style="
                                                max-height: 300px;
                                                border-radius: 10px;
                                                padding: 5px;
                                              "
                                            >
                                              <h5
                                                style="
                                                  text-align: left;
                                                  margin: 12px;
                                                  font-weight: 500;
                                                  font-size: 20px;
                                                "
                                              >
                                                Recent Search From
                                              </h5>
                                              <div
                                                class="row resu-form mt-1 align-center"
                                                v-for="data of resultFrom"
                                                :key="data"
                                              >
                                                <div class="col-2">
                                                  <v-icon
                                                    size="22"
                                                    class="float-end"
                                                  >
                                                    mdi-airplane-takeoff
                                                  </v-icon>
                                                </div>

                                                <div
                                                  class="col-10 ps-0"
                                                  @click="getrecent(data)"
                                                >
                                                  <div>
                                                    {{
                                                      getrecentData(
                                                        data.from.originalName
                                                      )
                                                    }}
                                                  </div>
                                                </div>
                                              </div>
                                            </v-card>
                                          </div>
                                        </div> -->
                                      </div>
                                    </li>
                                    <li class="col-md-3 col-12 ps-2">
                                      <div class="form-grp">
                                        <div class="labelTop">
                                          <p
                                            class="m-0 pt-2 ps-md-4 ms-md-2 ps-2 ms-0"
                                          >
                                            To
                                          </p>
                                        </div>
                                        <div
                                          class="d-flex input-styles rounded-border roundLabel"
                                          @click="formres1 = true"
                                        >
                                          <span class="p-float-label">
                                            <div class="vas-autocomplete">
                                              <input
                                                type="text"
                                                v-model="inputCityto"
                                                @keyup="
                                                  typeCity(inputCityto, 2)
                                                "
                                                class="inter-input"
                                                @click="
                                                  (inputCityto = ''),
                                                    (formres1 = true),
                                                    (formres = false),
                                                    (city = [])
                                                "
                                              />

                                              <div v-if="typedrop1">
                                                <div
                                                  class="autocompete-menual-dropdown"
                                                  v-if="this.city1.length > 0"
                                                >
                                                  <div
                                                    v-for="(
                                                      data, index
                                                    ) in city1"
                                                    :key="index"
                                                  >
                                                    <div
                                                      @click="
                                                        datachangeTo(data)
                                                      "
                                                      :style="
                                                        index ==
                                                        city1.length - 1
                                                          ? ''
                                                          : 'border-bottom:1px solid lightgray'
                                                      "
                                                      class="d-flex va-back"
                                                    >
                                                      <div
                                                        style="
                                                          display: flex;
                                                          align-items: center;
                                                        "
                                                      >
                                                        <v-icon
                                                          style="
                                                            transform: rotate(
                                                              45deg
                                                            );
                                                          "
                                                          color="gray"
                                                          size="20"
                                                        >
                                                          {{
                                                            data.location ===
                                                            "airport"
                                                              ? "mdi-airplane"
                                                              : "mdi-radiobox-marked"
                                                          }}
                                                        </v-icon>
                                                      </div>

                                                      <div
                                                        class="m-1 va-content"
                                                      >
                                                        {{ data.iata }},{{
                                                          data.city
                                                        }},{{ data.name }}
                                                      </div>
                                                    </div>

                                                    <div
                                                      class="ms-4"
                                                      v-if="
                                                        data.group_airports
                                                          .length > 0
                                                      "
                                                    >
                                                      <div
                                                        v-for="group in data.group_airports"
                                                        :key="group"
                                                      >
                                                        <div
                                                          v-if="
                                                            data.group_airport_city !=
                                                            group.iata
                                                          "
                                                        >
                                                          <div
                                                            class="d-flex va-back"
                                                            @click="
                                                              datachangeTo(
                                                                group
                                                              )
                                                            "
                                                          >
                                                            <div
                                                              style="
                                                                display: flex;
                                                                align-items: center;
                                                              "
                                                            >
                                                              <v-icon
                                                                style="
                                                                  transform: rotate(
                                                                    45deg
                                                                  );
                                                                "
                                                                color="gray"
                                                                size="15"
                                                                >mdi-airplane</v-icon
                                                              >
                                                            </div>

                                                            <div
                                                              class="m-1 va-content"
                                                            >
                                                              {{
                                                                group.iata
                                                              }},{{
                                                                group.city
                                                              }},{{
                                                                group.name
                                                              }}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>

                                            <!-- <label for="ac">Going To</label> -->
                                          </span>
                                        </div>
                                        <div class="text-start">
                                          <span
                                            class="p-error"
                                            v-if="toErrorMessage"
                                            >Arrival is required</span
                                          >
                                          <br
                                            v-if="
                                              toErrorMessage && maximumletter1
                                            "
                                          />
                                          <span
                                            class="p-error"
                                            v-if="maximumletter1"
                                            >Enter minimum 3 letters</span
                                          >
                                        </div>
                                        <button class="exchange-icon">
                                          <i
                                            class="fa-solid fa-arrow-right-arrow-left"
                                          ></i>
                                        </button>

                                        <!-- <div v-if="formres1">
                                          <div
                                            v-if="resultTo.length"
                                            class="recent_style"
                                          >
                                            <v-card
                                              style="
                                                max-height: 300px;
                                                border-radius: 10px;
                                                padding: 5px;
                                              "
                                            >
                                              <h5
                                                style="
                                                  text-align: left;
                                                  margin: 12px;
                                                  font-weight: 500;
                                                  font-size: 20px;
                                                "
                                              >
                                                Recent Search To
                                              </h5>
                                              <div
                                                class="row resu-form mt-1"
                                                v-for="data of resultTo"
                                                :key="data"
                                              >
                                                <div class="col-2">
                                                  <v-icon
                                                    size="22"
                                                    class="float-end"
                                                  >
                                                    mdi-airplane-landing
                                                  </v-icon>
                                                </div>

                                                <div
                                                  class="col-10 ps-0"
                                                  @click="getrecent1(data)"
                                                >
                                                  <div>
                                                    {{
                                                      getrecentData(
                                                        data.to.originalName
                                                      )
                                                    }}
                                                  </div>
                                                </div>
                                              </div>
                                            </v-card>
                                          </div>
                                        </div> -->
                                      </div>
                                    </li>
                                    <!-- <li>
                                                                <div class="form-grp select">
                                                                    <label for="shortBy">Trip</label>
                                                                    <select id="shortBy" name="select" class="form-select mb-2" aria-label="Default select example">
                                                                        <option value="">Economy</option>
                                                                        <option>Business</option>
                                                                        <option>First Class</option>
                                                                    </select>
                                                                </div>
                                                            </li> -->
                                    <li class="col-md-3 col-12">
                                      <div class="form-grp date">
                                        <ul class="ps-0">
                                          <!-- <li>
                                                                            <label for="shortBy">Depart</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li>
                                                                        <li>
                                                                            <label for="shortBy">Return</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li> -->
                                          <div>
                                            <div class="labelTop">
                                              <p class="m-0 pt-2 ps-0">
                                                Depart Date
                                              </p>
                                            </div>

                                            <div
                                              class="d-flex input-styles rounded-border"
                                            >
                                              <!-- <span class="d-flex align-center ps-1 me-2">
                                                                            <v-icon color="#3c0ec2">mdi-calendar</v-icon>
                                                                        </span> -->

                                              <span class="p-float-label">
                                                <!-- <span :class="clicked1 ? 'label-top' : 'datepicker-label'">
                                                                            Departure
                                                                            </span> -->

                                                <VueDatePicker
                                                  ref="dpRef1"
                                                  v-model="oneway.dedate"
                                                  :min-date="new Date()"
                                                  :six-weeks="sixWeekMode"
                                                  :max-date="
                                                    new Date(
                                                      new Date().getFullYear() +
                                                        1,
                                                      new Date().getMonth(),
                                                      new Date().getDate()
                                                    )
                                                  "
                                                  :format="dateFormat"
                                                  :hide-navigation="['time']"
                                                  auto-apply
                                                  :multi-calendars="
                                                    multiCalender
                                                  "
                                                  @update:modelValue="
                                                    fromDateChanged
                                                  "
                                                  :on-click-outside="
                                                    ClickOutDepat
                                                  "
                                                  class="px-0 roundFromTime"
                                                />

                                                <!-- <div class="col-12"> -->
                                                <!-- <span class="p-error" v-if="fromDateError">Dep. Date is required</span> -->
                                                <!-- </div> -->
                                              </span>
                                            </div>
                                            <div
                                              class="text-start"
                                              v-if="fromDateError"
                                            >
                                              <span class="p-error"
                                                >Date is required</span
                                              >
                                            </div>
                                          </div>

                                          <div :class="{ disable: Oneway }">
                                            <div class="labelTop">
                                              <p class="m-0 pt-2">
                                                Return Date
                                              </p>
                                            </div>
                                            <div
                                              class="d-flex input-styles rounded-border"
                                            >
                                              <!-- <span class="d-flex align-center ps-1 me-2">
                                                                                <v-icon color="#3c0ec2">mdi-calendar</v-icon>
                                                                            </span> -->
                                              <span class="p-float-label">
                                                <!-- <span :class="clicked2 ? 'label-top' : 'datepicker-label'">
                                                                                Return
                                                                                </span> -->
                                                <VueDatePicker
                                                  ref="dpRef2"
                                                  v-model="oneway.redate"
                                                  :format="dateFormat"
                                                  no-today
                                                  :six-weeks="sixWeekMode"
                                                  :start-date="oneway.dedate"
                                                  :multi-calendars="
                                                    multiCalender
                                                  "
                                                  :disabled="red1 === 2"
                                                  :min-date="
                                                    oneway.dedate
                                                      ? oneway.dedate
                                                      : new Date()
                                                  "
                                                  :max-date="
                                                    new Date(
                                                      new Date().getFullYear() +
                                                        1,
                                                      new Date().getMonth(),
                                                      new Date().getDate()
                                                    )
                                                  "
                                                  :hide-navigation="['time']"
                                                  auto-apply
                                                  :on-click-outside="
                                                    ClickOutReturn
                                                  "
                                                  class="roundToTime"
                                                />

                                                <!-- <span class="p-error" v-if="toDateError">Arr. Date is required</span> -->
                                              </span>
                                            </div>
                                            <div class="text-start">
                                              <span
                                                class="p-error ms-2"
                                                v-if="toDateError"
                                                >Date is required</span
                                              >
                                            </div>
                                          </div>
                                        </ul>
                                      </div>
                                    </li>
                                    <li class="col-md-3 col-12 p-0">
                                      <div class="form-grp economy">
                                        <div @click="travels = true">
                                          <label
                                            for="text"
                                            class="pt-2 mt-1"
                                            style="height: 32px"
                                            >Passenger/ Class</label
                                          >
                                          <v-select
                                            v-model="selectedCountry"
                                            :items="countries"
                                            ref="countrySelect"
                                            class="select-1"
                                          >
                                            <!-- <template #item="{ item }"> -->
                                            <template #item="{}">
                                              <div
                                                class="dropdown_section"
                                                style="cursor: pointer"
                                                v-if="travels"
                                              >
                                                <div
                                                  class="row d-flex align-center my-2 mx-1"
                                                  style="height: 37px"
                                                  @click.stop
                                                >
                                                  <div
                                                    class="col-3"
                                                    style="width: 38%"
                                                  >
                                                    <span
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 500;
                                                        color: #4e4d4d;
                                                      "
                                                      >Adult</span
                                                    >
                                                  </div>
                                                  <div
                                                    class="col-3 px-0"
                                                    style="width: 9%"
                                                  >
                                                    <v-icon
                                                      style="
                                                        width: auto;
                                                        color: grey;
                                                        font-size: 18px;
                                                      "
                                                    >
                                                      mdi-human-male-female
                                                    </v-icon>
                                                  </div>
                                                  <div
                                                    class="col-6 adult"
                                                    style="
                                                      width: 50%;
                                                      height: auto;
                                                    "
                                                  >
                                                    <div
                                                      class="row"
                                                      style="padding: 0px"
                                                    >
                                                      <div
                                                        class="col-4"
                                                        @click="deg1()"
                                                      >
                                                        <div class="adult-plus">
                                                          -
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        style="
                                                          background-color: white;
                                                        "
                                                        align="center"
                                                      >
                                                        <div>{{ aduls }}</div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        @click="add1()"
                                                      >
                                                        <div class="adult-plus">
                                                          +
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <!-- <div>{{ item.name }}</div> -->
                                                </div>

                                                <div
                                                  class="row d-flex align-center my-2 mx-1"
                                                  @click.stop
                                                >
                                                  <div
                                                    class="col-4 d-flex flex-column pe-0"
                                                    style="width: 38%"
                                                  >
                                                    <span
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 500;
                                                        color: #4e4d4d;
                                                      "
                                                      >Children</span
                                                    >
                                                    <span
                                                      style="
                                                        font-size: 11px;
                                                        color: #4e4d4d;
                                                      "
                                                      >2-11(yrs)</span
                                                    >
                                                  </div>
                                                  <div
                                                    class="col-2 px-0"
                                                    style="width: 9%"
                                                  >
                                                    <v-icon
                                                      style="
                                                        width: auto;
                                                        color: grey;
                                                        font-size: 18px;
                                                      "
                                                    >
                                                      mdi-human-child
                                                    </v-icon>
                                                  </div>
                                                  <div
                                                    class="col-6 adult"
                                                    style="width: 50%"
                                                  >
                                                    <div
                                                      class="row"
                                                      style="padding: 0px"
                                                    >
                                                      <div
                                                        class="col-4"
                                                        @click="deg2()"
                                                      >
                                                        <div class="adult-plus">
                                                          -
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        style="
                                                          background-color: white;
                                                        "
                                                        align="center"
                                                      >
                                                        <div>{{ aduls1 }}</div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        @click="add2()"
                                                      >
                                                        <div class="adult-plus">
                                                          +
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <!-- <div>{{ item.name }}</div> -->
                                                </div>

                                                <div
                                                  class="row d-flex align-center my-2 mx-1"
                                                  @click.stop
                                                >
                                                  <div
                                                    class="col-5 d-flex flex-column pe-0"
                                                    style="width: 38%"
                                                  >
                                                    <span
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 500;
                                                        color: #4e4d4d;
                                                      "
                                                      >Lap Infant</span
                                                    >
                                                    <span
                                                      style="
                                                        font-size: 11px;
                                                        color: #4e4d4d;
                                                      "
                                                      >2(yrs)</span
                                                    >
                                                  </div>

                                                  <div
                                                    class="col-1 ps-0"
                                                    style="width: 9%"
                                                  >
                                                    <v-icon
                                                      style="
                                                        width: auto;
                                                        color: grey;
                                                        font-size: 18px;
                                                      "
                                                    >
                                                      mdi-baby-buggy
                                                    </v-icon>
                                                  </div>
                                                  <div
                                                    class="col-6 adult"
                                                    style="width: 50%"
                                                  >
                                                    <div
                                                      class="row"
                                                      style="padding: 0px"
                                                    >
                                                      <div
                                                        class="col-4"
                                                        @click="deg3()"
                                                      >
                                                        <div class="adult-plus">
                                                          -
                                                        </div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        style="
                                                          background-color: white;
                                                        "
                                                        align="center"
                                                      >
                                                        <div>{{ aduls2 }}</div>
                                                      </div>
                                                      <div
                                                        class="col-4"
                                                        @click="add3()"
                                                      >
                                                        <div class="adult-plus">
                                                          +
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  class="row d-flex align-center my-2 mx-1"
                                                  @click.stop
                                                >
                                                  <div class="col-4">
                                                    <span
                                                      style="
                                                        font-size: 14px;
                                                        font-weight: 500;
                                                        color: grey;
                                                      "
                                                      >Cabin</span
                                                    >
                                                  </div>

                                                  <div
                                                    class="col-2"
                                                    align="center"
                                                  >
                                                    <v-icon
                                                      style="
                                                        width: auto;
                                                        color: grey;
                                                        font-size: 18px;
                                                      "
                                                    >
                                                      mdi-seat-passenger
                                                    </v-icon>
                                                  </div>
                                                  <div class="col-6">
                                                    <div
                                                      class="input-styles-1 select-option select-2"
                                                    >
                                                      <v-select
                                                        v-model="classType"
                                                        ref="mySelect"
                                                        @click="
                                                          selectFunction()
                                                        "
                                                        :items="[
                                                          'Economy',
                                                          'Business',
                                                          'First Class',
                                                        ]"
                                                        variant="underlined"
                                                        style="color: gray"
                                                      ></v-select>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  align="end"
                                                  class="mt-4 pe-3 d-flex justify-end"
                                                  @click="
                                                    localdone(), closeDropdown()
                                                  "
                                                >
                                                  <v-btn
                                                    rounded="3"
                                                    color="#26225e"
                                                    width="100px"
                                                    height="30px"
                                                    style="color: white"
                                                    @click="travels = false"
                                                  >
                                                    Done
                                                  </v-btn>
                                                </div>
                                              </div>
                                            </template>

                                            <template #selection="{}">
                                              <div class="pax-fonts">
                                                <span class="adt-chd"
                                                  >{{ aduls }} Adt</span
                                                >
                                                <span
                                                  v-if="aduls1 > 0"
                                                  class="adt-chd"
                                                  >, {{ aduls1 }} Ch</span
                                                >
                                                <span
                                                  v-if="aduls2 > 0"
                                                  class="adt-chd"
                                                  >, {{ aduls2 }} Inf</span
                                                >

                                                <span class="adt-chd"
                                                  >, {{ classType }}</span
                                                >
                                              </div>
                                            </template>
                                          </v-select>
                                        </div>
                                      </div>
                                      <!-- <div class="d-flex input-styles">

                                                               
                                                                </div> -->
                                    </li>
                                  </ul>
                                </form>
                              </div>
                              <div class="my-4 mt-md-0 mt-5" v-if="multicity">
                                <v-form style="border-radius: 8px">
                                  <div
                                    class="row multicity m-0"
                                    v-for="(data, index) of multiData.itinery"
                                    :key="index"
                                  >
                                    <ul
                                      class="multiData py-2 ps-2 d-flex flex-md-row flex-column justify-content-between"
                                      style="
                                        background-color: rgb(227 225 223);
                                        margin: 0;
                                        border-radius: 5px;
                                      "
                                    >
                                      <li class="col-lg-3 col-12">
                                        <div class="form-grp">
                                          <div class="labelTop">
                                            <p class="m-0 ps-2">
                                              Flight {{ index + 1 }} From
                                            </p>
                                          </div>
                                          <div
                                            class="d-flex input-styles rounded-border"
                                          >
                                            <span class="p-float-label">
                                              <!-- <AutoComplete
                                                v-model="data.from"
                                                optionLabel="name"
                                                dropdown
                                                forceSelection
                                                :suggestions="filteredCountries"
                                                @complete="search"
                                                @change="
                                                  onChangeMultiFrom(index)
                                                "
                                                @click="
                                                  multiFromClick(index),
                                                    (focusedElement = 'from')
                                                "
                                                ref="fromAutoComplete"
                                                class="mb-2"
                                                style="
                                                  width: 100%;
                                                  height: 100%;
                                                  font-size: 16px;
                                                  font-weight: 600;
                                                  top: -10px;
                                                "
                                              >
                                                <template #option="slotProps">
                                                  <div
                                                    class="flex align-items-center dropdown-box"
                                                    @click="multifocus1(index)"
                                                  >
                                                    <div>
                                                      {{
                                                        slotProps.option
                                                          .originalName
                                                      }}
                                                    </div>
                                                  </div>
                                                </template>
                                              </AutoComplete>
                                              <label
                                                for="ac"
                                                class="fromLabel"
                                                style="top: -38px; left: 0px"
                                                >Flight
                                                {{ index + 1 }} From</label
                                              > -->

                                              <div class="vas-autocomplete">
                                                <input
                                                  type="text"
                                                  style="cursor: pointer"
                                                  v-model="data.from"
                                                  @click="
                                                    multiData.itinery[
                                                      index
                                                    ].from = ''
                                                  "
                                                  @keyup="
                                                    typeMultiCity(
                                                      data.from,
                                                      index,
                                                      1
                                                    )
                                                  "
                                                  class="inter-input"
                                                />
                                                <!-- {{console.log(data,'data.autocomfrom')}} -->
                                                <div
                                                  v-if="
                                                    multiData.itinery[index]
                                                      .autocomfrom
                                                      ? true
                                                      : false
                                                  "
                                                >
                                                  <div
                                                    class="autocompete-menual-dropdown"
                                                    v-if="
                                                      this.multidrop.length > 0
                                                        ? true
                                                        : false
                                                    "
                                                  >
                                                    <div
                                                      v-for="(
                                                        data1, index1
                                                      ) in multidrop"
                                                      :key="index1"
                                                    >
                                                      {{
                                                        console.log(
                                                          data1,
                                                          "data1data1"
                                                        )
                                                      }}
                                                      <div
                                                        @click="
                                                          datachangeMuti(
                                                            data1,
                                                            index
                                                          )
                                                        "
                                                        :style="
                                                          index1 ==
                                                          multidrop.length - 1
                                                            ? ''
                                                            : 'border-bottom:1px solid lightgray'
                                                        "
                                                        class="d-flex p-2 va-back"
                                                      >
                                                        <div
                                                          style="
                                                            display: flex;
                                                            align-items: center;
                                                          "
                                                        >
                                                          <v-icon
                                                            style="
                                                              transform: rotate(
                                                                90deg
                                                              );
                                                            "
                                                            color="gray"
                                                            size="20"
                                                            >mdi-airplane</v-icon
                                                          >
                                                        </div>

                                                        <div
                                                          class="ms-1 va-content"
                                                        >
                                                          {{ data1.iata }},
                                                          {{ data1.city }},
                                                          {{ data1.name }}
                                                        </div>
                                                      </div>

                                                      <div
                                                        v-if="
                                                          data1.group_airports
                                                            .length > 0
                                                        "
                                                      >
                                                        <div
                                                          v-for="(
                                                            group, index1
                                                          ) in data1.group_airports"
                                                          :key="index1"
                                                        >
                                                          <div
                                                            v-if="
                                                              data1.group_airport_city !=
                                                              group.iata
                                                            "
                                                          >
                                                            <div
                                                              class="d-flex va-back"
                                                              @click="
                                                                datachangeMuti(
                                                                  group,
                                                                  index1
                                                                )
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: flex;
                                                                  align-items: center;
                                                                "
                                                                class="ms-4"
                                                              >
                                                                <v-icon
                                                                  style="
                                                                    transform: rotate(
                                                                      90deg
                                                                    );
                                                                  "
                                                                  color="gray"
                                                                  size="15"
                                                                  >mdi-airplane</v-icon
                                                                >
                                                              </div>

                                                              <div
                                                                class="m-1 va-content"
                                                              >
                                                                {{
                                                                  group.iata
                                                                }},
                                                                {{
                                                                  group.city
                                                                }},
                                                                {{ group.name }}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </div>

                                          <div class="text-start">
                                            <span
                                              class="p-error"
                                              v-if="maximumletter"
                                              >Enter minimum 3 letters</span
                                            >
                                          </div>
                                        </div>
                                      </li>
                                      <li class="col-lg-3 col-12">
                                        <div class="form-grp">
                                          <div class="labelTop">
                                            <p class="m-0 ps-2">
                                              Flight {{ index + 1 }} To
                                            </p>
                                          </div>
                                          <div
                                            class="d-flex input-styles rounded-border"
                                            @click="formres1 = true"
                                          >
                                            <span
                                              class="p-float-label multiDataTo"
                                            >
                                              <div class="vas-autocomplete">
                                                <input
                                                  style="cursor: pointer"
                                                  type="text"
                                                  v-model="data.to"
                                                  @click="
                                                    multiData.itinery[
                                                      index
                                                    ].to = ''
                                                  "
                                                  @keyup="
                                                    typeMultiCity(
                                                      data.to,
                                                      index,
                                                      2
                                                    )
                                                  "
                                                  class="inter-input"
                                                />

                                                <div v-if="data.autocomTo">
                                                  <div
                                                    class="autocompete-menual-dropdown"
                                                    v-if="
                                                      multidrop1.length > 0
                                                        ? true
                                                        : false
                                                    "
                                                  >
                                                    <div
                                                      v-for="(
                                                        data, index1
                                                      ) in multidrop1"
                                                      :key="index1"
                                                    >
                                                      <div
                                                        @click="
                                                          datachangeMutiTo(
                                                            data,
                                                            index
                                                          )
                                                        "
                                                        :style="
                                                          index1 ==
                                                          multidrop1.length - 1
                                                            ? ''
                                                            : 'border-bottom:1px solid lightgray'
                                                        "
                                                        class="d-flex p-2 va-back"
                                                      >
                                                        <div
                                                          style="
                                                            display: flex;
                                                            align-items: center;
                                                          "
                                                        >
                                                          <v-icon
                                                            style="
                                                              transform: rotate(
                                                                90deg
                                                              );
                                                            "
                                                            color="gray"
                                                            size="20"
                                                          >
                                                            {{
                                                              data.location ===
                                                              "airport"
                                                                ? "mdi-airplane"
                                                                : "mdi-radiobox-marked"
                                                            }}
                                                          </v-icon>
                                                        </div>

                                                        <div
                                                          class="ms-1 va-content"
                                                        >
                                                          {{ data.iata }},
                                                          {{ data.city }},
                                                          {{ data.name }}
                                                        </div>
                                                      </div>

                                                      <div
                                                        v-if="
                                                          data.group_airports
                                                            .length > 0
                                                        "
                                                      >
                                                        <div
                                                          v-for="(
                                                            group, index1
                                                          ) in data.group_airports"
                                                          :key="index1"
                                                        >
                                                          <div
                                                            v-if="
                                                              data.group_airport_city !=
                                                              group.iata
                                                            "
                                                          >
                                                            <div
                                                              class="d-flex va-back"
                                                              @click="
                                                                datachangeMutiTo(
                                                                  group,
                                                                  index1
                                                                )
                                                              "
                                                            >
                                                              <div
                                                                style="
                                                                  display: flex;
                                                                  align-items: center;
                                                                "
                                                                class="ms-4"
                                                              >
                                                                <v-icon
                                                                  style="
                                                                    transform: rotate(
                                                                      90deg
                                                                    );
                                                                  "
                                                                  color="gray"
                                                                  size="15"
                                                                  >mdi-airplane</v-icon
                                                                >
                                                              </div>

                                                              <div
                                                                class="m-1 va-content"
                                                              >
                                                                {{
                                                                  group.iata
                                                                }},
                                                                {{
                                                                  group.city
                                                                }},
                                                                {{ group.name }}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </span>
                                          </div>
                                          <div class="text-start">
                                            <!-- <span class="p-error" v-if="toErrorMessage">Arrival is required</span>
                                                                    <br v-if="toErrorMessage && maximumletter1" /> -->
                                            <span
                                              class="p-error"
                                              v-if="maximumletter1"
                                              >Enter minimum 3 letters</span
                                            >
                                          </div>
                                        </div>
                                      </li>
                                      <li class="col-lg-3 col-12">
                                        <div class="form-grp date">
                                          <div>
                                            <div class="labelTop">
                                              <p class="m-0 ps-2">
                                                Flight {{ index + 1 }} Date
                                              </p>
                                            </div>

                                            <div
                                              class="d-flex input-styles rounded-border"
                                            >
                                              <!-- <span class="d-flex align-center ps-1 me-2">
                                                                            <v-icon color="#3c0ec2">mdi-calendar</v-icon>
                                                                        </span> -->

                                              <span
                                                class="p-float-label multiDate"
                                              >
                                                <!-- <span :class="clicked1 ? 'label-top' : 'datepicker-label'">
                                                                            Departure
                                                                            </span> -->

                                                <VueDatePicker
                                                  ref="dpRef4"
                                                  v-model="data.departDate"
                                                  :six-weeks="sixWeekMode"
                                                  :min-date="
                                                    getdate1(
                                                      multiData.itinery,
                                                      index
                                                    )
                                                  "
                                                  :start-date="
                                                    getdate1(
                                                      multiData.itinery,
                                                      index
                                                    )
                                                  "
                                                  :max-date="
                                                    new Date(
                                                      new Date().getFullYear() +
                                                        1,
                                                      new Date().getMonth(),
                                                      new Date().getDate()
                                                    )
                                                  "
                                                  :format="dateFormat"
                                                  :hide-navigation="['time']"
                                                  auto-apply
                                                  :multi-calendars="
                                                    multiCalender
                                                  "
                                                  @focus="
                                                    (data.focusMultiEvent3 = true),
                                                      (data.focusMultiEvent1 = false),
                                                      (data.focusMultiEvent2 = false)
                                                  "
                                                  @update:modelValue="
                                                    (newValue) =>
                                                      multiDateChanged(
                                                        newValue,
                                                        index
                                                      )
                                                  "
                                                  :month-change-on-scroll="
                                                    false
                                                  "
                                                  :on-click-outside="
                                                    ClickOutDepat
                                                  "
                                                  class="px-0"
                                                />

                                                <!-- <div class="col-12"> -->
                                                <!-- <span class="p-error" v-if="fromDateError">Dep. Date is required</span> -->
                                                <!-- </div> -->
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                      <li class="col-lg-2 col-12">
                                        <div
                                          class="content-bottom"
                                          style="margin: 0 !important"
                                        >
                                          <v-btn
                                            v-if="index == 0 ? true : false"
                                            block
                                            class="d-md-flex d-none search-btn btn m-0"
                                            :disabled="disButton"
                                            @click="sendedMulticity()"
                                            ><span
                                              class="f-size-20 me-1 text-capitalize"
                                              >Show Flights</span
                                            >
                                          </v-btn>
                                          <div
                                            class="d-flex"
                                            v-if="index !== 0 ? true : false"
                                          >
                                            <div
                                              style="
                                                display: flex;
                                                align-items: center;
                                                margin-right: 10px;
                                              "
                                            >
                                              <span
                                                style="
                                                  margin: auto;
                                                  cursor: pointer;
                                                "
                                                v-if="index > 1 ? true : false"
                                                @click="deleteItin(index)"
                                              >
                                                <v-icon color="red">
                                                  mdi-close-circle-outline
                                                </v-icon>
                                              </span>
                                            </div>

                                            <div
                                              v-if="
                                                multiData.itinery.length == 4
                                                  ? false
                                                  : true
                                              "
                                            >
                                              <v-btn
                                                v-if="
                                                  multiData.itinery.length -
                                                    1 ==
                                                    3 ||
                                                  index ==
                                                    multiData.itinery.length - 1
                                                    ? true
                                                    : false
                                                "
                                                class="d-flex align-center formbottom_addBtn"
                                                @click="addTrip(index)"
                                                prepend-icon="mdi-plus-circle"
                                                >Add More</v-btn
                                              >
                                            </div>

                                            <div
                                              v-if="index == 3 ? true : false"
                                            >
                                              <v-btn
                                                class="d-flex align-center formbottom_ClearBtn"
                                                @click="clearall1()"
                                                prepend-icon="mdi-close-box-multiple"
                                                >Clear All</v-btn
                                              >
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </v-form>
                              </div>

                              <div
                                class="content-bottom"
                                v-if="rount || Oneway"
                              >
                                <!-- <a href="booking-details.html" class="promo-code">+ Add Promo code</a> -->
                                <!-- <a href="/search" class="btn">Show Flights <i class="flaticon-flight-1"></i></a> -->
                                <div class="text-center">
                                  <v-btn
                                    block
                                    class="search-btn btn"
                                    v-if="rount"
                                    :disabled="disButton"
                                    rounded="2"
                                    @click="sended()"
                                    ><span
                                      class="f-size-20 me-1 text-capitalize"
                                      >Show Flights</span
                                    >
                                  </v-btn>
                                  <v-btn
                                    block
                                    class="search-btn btn"
                                    v-if="Oneway"
                                    :disabled="disButton"
                                    rounded="2"
                                    @click="sendedone()"
                                    ><span
                                      class="f-size-20 me-1 text-capitalize"
                                      >Show Flights</span
                                    >
                                  </v-btn>
                                </div>
                              </div>
                              <div
                                class="content-bottom d-md-none d-flex"
                                v-if="multicity"
                              >
                                <v-btn
                                  block
                                  class="d-flex search-btn btn m-0"
                                  :disabled="disButton"
                                  @click="sendedMulticity()"
                                  ><span class="f-size-20 me-1 text-capitalize"
                                    >Show Flights</span
                                  >
                                </v-btn>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="mytrips">
                        <div class="tab-content-wrap">
                          <v-for
                            v-model="valid"
                            ref="form"
                            @submit.prevent="bookinglist"
                          >
                            <div class="row" style="padding: 20px 0px">
                              <div class="col-md-5 mb-lg-0 mb-2">
                                <v-text-field
                                  label="Booking Reference ID"
                                  class="view-book"
                                  v-model="bookid"
                                  :rules="[(v) => !!v || 'Booking Reference']"
                                  variant="outlined"
                                ></v-text-field>
                              </div>

                              <div class="col-md-5 mb-lg-0 mb-2">
                                <v-text-field
                                  label="Last Name"
                                  class="view-book"
                                  :rules="[
                                    (v) => !!v || 'Last Name is required',
                                  ]"
                                  v-model="lastname"
                                  variant="outlined"
                                  @input="capitalizeLastName"
                                ></v-text-field>
                              </div>

                              <div class="col-md-2">
                                <v-btn
                                  type="submit"
                                  width="100%"
                                  style="height: 45px !important"
                                  color="#26225e"
                                  >submit</v-btn
                                >
                              </div>
                            </div>
                          </v-for>
                        </div>
                      </div>
                      <!-- 
 class="tab-pane fade show"
                        id="trips-tab-pane"
                        role="tabpanel"
                        aria-labelledby="trips-tab"
                        tabindex="0" -->

                      <div v-if="resentSearch">
                        <div class="tab-content-wrap" style="min-height: 137px">
                          <div
                            v-if="
                              this.RecentSearchData.length > 0 ? true : false
                            "
                          >
                            <div class="row">
                              <div
                                class="col-md-4 mt-2"
                                v-for="data of this.RecentSearchData"
                                :key="data"
                              >
                                <v-card
                                  @click="cardassigndata(data)"
                                  class="pa-2"
                                >
                                  <div class="row">
                                    <div class="col-5">
                                      <div class="re-fon">
                                        {{ data.from }}
                                      </div>
                                      <div class="re-fon">
                                        {{ getdated(data.fromDate) }}
                                      </div>
                                    </div>
                                    <div class="col-2">
                                      <div v-if="data.city == 1">
                                        <v-icon>mdi-swap-horizontal</v-icon>
                                      </div>
                                      <div v-if="data.city == 2">
                                        <v-icon>mdi-arrow-right</v-icon>
                                      </div>
                                      <div v-if="data.city == 3">
                                        <v-icon>mdi-swap-horizontal</v-icon>
                                        <v-icon>mdi-swap-horizontal</v-icon>
                                        <v-icon>mdi-swap-horizontal</v-icon>
                                      </div>
                                    </div>
                                    <div class="col-5">
                                      <div class="re-fon">
                                        {{ data.to }}
                                      </div>
                                      <div class="re-fon" v-if="data.toDate">
                                        {{ getdated(data.toDate) }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7">
                                      <div class="re-fon">
                                        Passenger(s):
                                        <span v-if="data.adult > 0"
                                          >{{ data.adult }} Adt</span
                                        >
                                        <span v-if="data.child > 0"
                                          >{{ data.child }} Ch</span
                                        >
                                        <span v-if="data.inf > 0"
                                          >{{ data.inf }}Inf</span
                                        >
                                      </div>
                                    </div>
                                    <div class="col-5 re-fon" v-if="data.class">
                                      Class: {{ data.class }}
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- flight booking -->

          <!-- <div class="container">
            <div class="row">
              <div class="col-xl-12 col-lg-12">
                <div class="slider-content text-center">
                  <h2 class="title" data-animation="fadeInUp" data-delay=".2s">
                    Travel far enough, you meet yourself.
                  </h2>
                  <p data-animation="fadeInUp" data-delay=".4s">
                    Explore top destinations and exclusive deals for your next
                    adventure!
                  </p>
                 </div>
              </div>
            </div>
          </div> -->
        </div>
        <!-- <div class="single-slider slider-bg" data-background="assets/img/slider/slider_bg02.jpg">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-8 col-lg-10">
                                    <div class="slider-content">
                                        <h2 class="title" data-animation="fadeInUp" data-delay=".2s">A Lifetime of Discounts? It's Genius.</h2>
                                        <p data-animation="fadeInUp" data-delay=".4s">Get rewarded for your travels – unlock instant savings of 10% or more with a free Geairinfo.com account</p>
                                        <a href="contact.html" class="btn" data-animation="fadeInUp" data-delay=".6s">Sign in / Register</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="single-slider slider-bg" data-background="assets/img/slider/slider_bg03.jpg">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-8 col-lg-10">
                                    <div class="slider-content">
                                        <h2 class="title" data-animation="fadeInUp" data-delay=".2s">A Lifetime of Discounts? It's Genius.</h2>
                                        <p data-animation="fadeInUp" data-delay=".4s">Get rewarded for your travels – unlock instant savings of 10% or more with a free Geairinfo.com account</p>
                                        <a href="contact.html" class="btn" data-animation="fadeInUp" data-delay=".6s">Sign in / Register</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
      </div>
    </section>
    <!-- slider-area-end -->

    <!-- booking-area -->

    <!-- <div class="booking-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="booking-tag">
                                <ul>
                                    <li><a href="booking-list.html"><i class="flaticon-flight"></i>Flights</a></li>
                                    <li><a href="booking-list.html"><i class="flaticon-car-1"></i>Car Rentals</a></li>
                                    <li><a href="booking-list.html"><i class="flaticon-eiffel-tower"></i>Attractions</a></li>
                                    <li><a href="booking-list.html"><i class="flaticon-taxi"></i>Airport Taxis</a></li>
                                </ul>
                            </div>
                            <div class="booking-wrap">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="bOOKing-tab" data-bs-toggle="tab" data-bs-target="#bOOKing-tab-pane" type="button"
                                            role="tab" aria-controls="bOOKing-tab-pane" aria-selected="true"><i class="flaticon-flight"></i>air BOOKing</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="trips-tab" data-bs-toggle="tab" data-bs-target="#trips-tab-pane" type="button"
                                            role="tab" aria-controls="trips-tab-pane" aria-selected="false"><i class="flaticon-file"></i> my trips</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="check-tab" data-bs-toggle="tab" data-bs-target="#check-tab-pane" type="button"
                                            role="tab" aria-controls="check-tab-pane" aria-selected="false"><i class="flaticon-tick"></i> check-in</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="flight-tab" data-bs-toggle="tab" data-bs-target="#flight-tab-pane" type="button"
                                            role="tab" aria-controls="flight-tab-pane" aria-selected="false"><i class="flaticon-clock"></i> Flight status</button>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="bOOKing-tab-pane" role="tabpanel" aria-labelledby="bOOKing-tab" tabindex="0">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="tab-content-wrap">
                                                    <div class="content-top">
                                                        <ul>
                                                            <li>Flights</li>
                                                            <li><span>Just from $12</span>Geair Stopover</li>
                                                        </ul>
                                                    </div>
                                                    <form action="#" class="booking-form">
                                                        <ul>
                                                            <li>
                                                                <div class="form-grp">
                                                                    <input type="text" placeholder="From">
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp">
                                                                    <input type="text" placeholder="To">
                                                                    <button class="exchange-icon"><i class="flaticon-exchange-1"></i></button>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp select">
                                                                    <label for="shortBy">Trip</label>
                                                                    <select id="shortBy" name="select" class="form-select" aria-label="Default select example">
                                                                        <option value="">Tour type</option>
                                                                        <option>Adventure Travel</option>
                                                                        <option>Family Tours</option>
                                                                        <option>Newest Item</option>
                                                                        <option>Nature & wildlife</option>
                                                                    </select>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp date">
                                                                    <ul>
                                                                        <li>
                                                                            <label for="shortBy">Depart</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li>
                                                                        <li>
                                                                            <label for="shortBy">Return</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp economy">
                                                                    <label for="text">Passenger/ Class</label>
                                                                    <input type="text" id="text" placeholder="1 Passenger, Economy">
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </form>
                                                    <div class="content-bottom">
                                                        <a href="booking-details.html" class="promo-code">+ Add Promo code</a>
                                                        <a href="booking-details.html" class="btn">Show Flights <i class="flaticon-flight-1"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="trips-tab-pane" role="tabpanel" aria-labelledby="trips-tab" tabindex="0">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="tab-content-wrap">
                                                    <div class="content-top">
                                                        <ul>
                                                            <li>Flights</li>
                                                            <li><span>Just from $12</span>Geair Stopover</li>
                                                        </ul>
                                                    </div>
                                                    <form action="#" class="booking-form">
                                                        <ul>
                                                            <li>
                                                                <div class="form-grp">
                                                                    <input type="text" placeholder="From">
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp">
                                                                    <input type="text" placeholder="To">
                                                                    <button class="exchange-icon"><i class="flaticon-exchange-1"></i></button>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp select">
                                                                    <label for="shortByTwo">Trip</label>
                                                                    <select id="shortByTwo" name="select" class="form-select" aria-label="Default select example">
                                                                        <option value="">Tour type</option>
                                                                        <option>Adventure Travel</option>
                                                                        <option>Family Tours</option>
                                                                        <option>Newest Item</option>
                                                                        <option>Nature & wildlife</option>
                                                                    </select>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp date">
                                                                    <ul>
                                                                        <li>
                                                                            <label for="shortBy">Depart</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li>
                                                                        <li>
                                                                            <label for="shortBy">Return</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp economy">
                                                                    <label for="textTwo">Passenger/ Class</label>
                                                                    <input type="text" id="textTwo" placeholder="1 Passenger, Economy">
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </form>
                                                    <div class="content-bottom">
                                                        <a href="booking-details.html" class="promo-code">+ Add Promo code</a>
                                                        <a href="booking-details.html" class="btn">Show Flights <i class="flaticon-flight-1"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="check-tab-pane" role="tabpanel" aria-labelledby="check-tab" tabindex="0">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="tab-content-wrap">
                                                    <div class="content-top">
                                                        <ul>
                                                            <li>Flights</li>
                                                            <li><span>Just from $12</span>Geair Stopover</li>
                                                        </ul>
                                                    </div>
                                                    <form action="#" class="booking-form">
                                                        <ul>
                                                            <li>
                                                                <div class="form-grp">
                                                                    <input type="text" placeholder="From">
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp">
                                                                    <input type="text" placeholder="To">
                                                                    <button class="exchange-icon"><i class="flaticon-exchange-1"></i></button>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp select">
                                                                    <label for="shortByThree">Trip</label>
                                                                    <select id="shortByThree" name="select" class="form-select" aria-label="Default select example">
                                                                        <option value="">Tour type</option>
                                                                        <option>Adventure Travel</option>
                                                                        <option>Family Tours</option>
                                                                        <option>Newest Item</option>
                                                                        <option>Nature & wildlife</option>
                                                                    </select>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp date">
                                                                    <ul>
                                                                        <li>
                                                                            <label for="shortBy">Depart</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li>
                                                                        <li>
                                                                            <label for="shortBy">Return</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp economy">
                                                                    <label for="textThree">Passenger/ Class</label>
                                                                    <input type="text" id="textThree" placeholder="1 Passenger, Economy">
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </form>
                                                    <div class="content-bottom">
                                                        <a href="booking-details.html" class="promo-code">+ Add Promo code</a>
                                                        <a href="booking-details.html" class="btn">Show Flights <i class="flaticon-flight-1"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="flight-tab-pane" role="tabpanel" aria-labelledby="flight-tab" tabindex="0">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="tab-content-wrap">
                                                    <div class="content-top">
                                                        <ul>
                                                            <li>Flights</li>
                                                            <li><span>Just from $12</span>Geair Stopover</li>
                                                        </ul>
                                                    </div>
                                                    <form action="#" class="booking-form">
                                                        <ul>
                                                            <li>
                                                                <div class="form-grp">
                                                                    <input type="text" placeholder="From">
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp">
                                                                    <input type="text" placeholder="To">
                                                                    <button class="exchange-icon"><i class="flaticon-exchange-1"></i></button>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp select">
                                                                    <label for="shortByFour">Trip</label>
                                                                    <select id="shortByFour" name="select" class="form-select" aria-label="Default select example">
                                                                        <option value="">Tour type</option>
                                                                        <option>Adventure Travel</option>
                                                                        <option>Family Tours</option>
                                                                        <option>Newest Item</option>
                                                                        <option>Nature & wildlife</option>
                                                                    </select>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp date">
                                                                    <ul>
                                                                        <li>
                                                                            <label for="shortBy">Depart</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li>
                                                                        <li>
                                                                            <label for="shortBy">Return</label>
                                                                            <input type="text" class="date" placeholder="Select Date">
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="form-grp economy">
                                                                    <label for="textFour">Passenger/ Class</label>
                                                                    <input type="text" id="textFour" placeholder="1 Passenger, Economy">
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </form>
                                                    <div class="content-bottom">
                                                        <a href="booking-details.html" class="promo-code">+ Add Promo code</a>
                                                        <a href="booking-details.html" class="btn">Show Flights <i class="flaticon-flight-1"></i></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

    <!-- booking-area-end -->

    <!-- features-area -->
    <section class="features-area">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-xl-4 col-lg-6 col-sm-10">
            <div class="features-item">
              <div class="features-icon mt-0">
                <!-- <i class="flaticon-help"></i> -->
                <img src="../assets/img/icon/help.png" />
              </div>
              <div class="features-content">
                <h6 class="title">We are now available</h6>
                <p>Call +1 555 666 888 for contuct with us</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-sm-10">
            <div class="features-item">
              <div class="features-icon mt-0">
                <!-- <i class="flaticon-plane"></i> -->
                <img src="../assets/img/icon/plane(1).png" />
              </div>
              <div class="features-content">
                <h6 class="title">International Flight</h6>
                <p>Call +1 555 666 888 for booking assistance</p>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-6 col-sm-10">
            <div class="features-item">
              <div class="features-icon mt-0">
                <!-- <i class="flaticon-money-back-guarantee"></i> -->
                <img src="../assets/img/icon/refund.png" />
              </div>
              <div class="features-content">
                <h6 class="title">Check Refund</h6>
                <p>Call +1 555 666 888 for check refund status</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- features-area-end -->

    <!-- fly-next-area -->
    <section class="fly-next-area">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title text-center">
              <span class="sub-title">Flynext Package</span>
              <h2 class="title">Your Great Destination</h2>
            </div>
          </div>
        </div>
        <div class="row fly-next-active justify-content-center">
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a href="booking-details.html"
                  ><img src="../assets/img/images/fly_img01.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>09 Jun 2022 - 16 Jun 2022</span>
                <h4 class="title">Dubai (DXB)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a href="booking-details.html" class="air-logo"
                  ><img src="../assets/img/icon/fly_icon01.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Economy from</p>
                  <h4 class="price">$195</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-one cat-two"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a href="booking-details.html"
                  ><img src="../assets/img/images/fly_img02.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>09 Jun 2022 - 16 Jun 2022</span>
                <h4 class="title">Switzerland (SWL)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a href="booking-details.html" class="air-logo"
                  ><img src="../assets/img/icon/fly_icon02.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Business Class</p>
                  <h4 class="price">$800</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a href="booking-details.html"
                  ><img src="../assets/img/images/fly_img03.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>09 Jun 2022 - 16 Jun 2022</span>
                <h4 class="title">Denmark (DEK)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a href="booking-details.html" class="air-logo"
                  ><img src="../assets/img/icon/fly_icon03.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Economy from</p>
                  <h4 class="price">$ 350</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-one"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a href="booking-details.html"
                  ><img src="../assets/img/images/fly_img04.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>09 Jun 2022 - 16 Jun 2022</span>
                <h4 class="title">Jakarta (DXB)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a href="booking-details.html" class="air-logo"
                  ><img src="../assets/img/icon/fly_icon01.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Business Class</p>
                  <h4 class="price">$ 220</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a href="booking-details.html"
                  ><img src="../assets/img/images/fly_img05.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>09 Jun 2022 - 16 Jun 2022</span>
                <h4 class="title">Dubai (DXB)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a href="booking-details.html" class="air-logo"
                  ><img src="../assets/img/icon/fly_icon03.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Economy from</p>
                  <h4 class="price">$195</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-one"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a href="booking-details.html"
                  ><img src="../assets/img/images/fly_img06.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>09 Jun 2022 - 16 Jun 2022</span>
                <h4 class="title">Dubai (DXB)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a href="booking-details.html" class="air-logo"
                  ><img src="../assets/img/icon/fly_icon02.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Business Class</p>
                  <h4 class="price">$175</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two cat-one"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a href="booking-details.html"
                  ><img src="../assets/img/images/fly_img07.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>09 Jun 2022 - 16 Jun 2022</span>
                <h4 class="title">Switzerland (SWL)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a href="booking-details.html" class="air-logo"
                  ><img src="../assets/img/icon/fly_icon01.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Economy from</p>
                  <h4 class="price">$195</h4>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-3 col-lg-4 col-md-6 col-sm-6 grid-item grid-sizer cat-two"
          >
            <div class="fly-next-item">
              <div class="fly-next-thumb">
                <a href="booking-details.html"
                  ><img src="../assets/img/images/fly_img08.jpg" alt=""
                /></a>
              </div>
              <div class="fly-next-content">
                <span>09 Jun 2022 - 16 Jun 2022</span>
                <h4 class="title">Turkish (SWL)</h4>
                <a href="#" class="exchange-btn"
                  ><i class="flaticon-exchange-1"></i
                ></a>
                <h4 class="title">New York (USA)</h4>
                <a href="booking-details.html" class="air-logo"
                  ><img src="../assets/img/icon/fly_icon02.jpg" alt=""
                /></a>
                <div class="content-bottom">
                  <p>Business Class</p>
                  <h4 class="price">$350</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- fly-next-area-end -->

    <!-- flight-offer-area -->
    <section class="flight-offer-area">
      <div class="container">
        <div class="row align-items-center mb-35">
          <div class="col-md-8">
            <div class="section-title">
              <span class="sub-title">Offer Deals</span>
              <h2 class="title">Flight Offer Deals</h2>
            </div>
          </div>
          <div class="col-md-4">
            <div class="best-price text-end">
              <a href="booking-list.html"
                >Best Price Guarantee <i class="flaticon-check"></i
              ></a>
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-10">
            <div class="flight-offer-item">
              <div class="flight-offer-thumb">
                <img src="../assets/img/images/offer_img01.jpg" alt="" />
              </div>
              <div class="flight-offer-content">
                <h2 class="title">Dhaka to Dubai</h2>
                <span>09 Jun 2022 - 16 Jun 2022</span>
                <p>Economy from</p>
                <h4 class="price">$ 980</h4>
              </div>
              <div class="overlay-content">
                <h2 class="title">Dhaka to Dubai</h2>
                <span>09 Jun 2022 - 16 Jun 2022</span>
                <p>Economy from</p>
                <h4 class="price">$ 980</h4>
                <div class="content-bottom">
                  <a href="booking-details.html" class="btn">Booking Now</a>
                  <a href="booking-list.html" class="discover">Discover</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-10">
            <div class="row">
              <div class="col-sm-6">
                <div class="flight-offer-item offer-item-two">
                  <div class="flight-offer-thumb">
                    <img src="../assets/img/images/offer_img02.jpg" alt="" />
                  </div>
                  <div class="flight-offer-content">
                    <h2 class="title">New York to California</h2>
                    <span>09 Jun 2022 - 16 Jun 2022</span>
                    <p>Economy from</p>
                    <h4 class="price">$ 290</h4>
                  </div>
                  <div class="overlay-content">
                    <h2 class="title">New York to California</h2>
                    <span>09 Jun 2022 - 16 Jun 2022</span>
                    <p>Economy from</p>
                    <h4 class="price">$ 290</h4>
                    <div class="content-bottom">
                      <a href="booking-details.html" class="btn">Booking Now</a>
                      <a href="booking-list.html" class="discover">Discover</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="flight-offer-item offer-item-two">
                  <div class="flight-offer-thumb">
                    <img src="../assets/img/images/offer_img03.jpg" alt="" />
                  </div>
                  <div class="flight-offer-content">
                    <h2 class="title">Malaga to Finland</h2>
                    <span>09 Jun 2022 - 16 Jun 2022</span>
                    <p>Economy from</p>
                    <h4 class="price">$ 792</h4>
                  </div>
                  <div class="overlay-content">
                    <h2 class="title">Malaga to Finland</h2>
                    <span>09 Jun 2022 - 16 Jun 2022</span>
                    <p>Economy from</p>
                    <h4 class="price">$ 792</h4>
                    <div class="content-bottom">
                      <a href="booking-details.html" class="btn">Booking Now</a>
                      <a href="booking-list.html" class="discover">Discover</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="flight-offer-item offer-item-two">
                  <div class="flight-offer-thumb">
                    <img src="../assets/img/images/offer_img04.jpg" alt="" />
                  </div>
                  <div class="flight-offer-content">
                    <h2 class="title">Dubai to Maldives</h2>
                    <span>09 Jun 2022 - 16 Jun 2022</span>
                    <p>Economy from</p>
                    <h4 class="price">$ 980</h4>
                  </div>
                  <div class="overlay-content">
                    <h2 class="title">Dubai to Maldives</h2>
                    <span>09 Jun 2022 - 16 Jun 2022</span>
                    <p>Economy from</p>
                    <h4 class="price">$ 980</h4>
                    <div class="content-bottom">
                      <a href="booking-details.html" class="btn">Booking Now</a>
                      <a href="booking-list.html" class="discover">Discover</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="flight-offer-item offer-item-two">
                  <div class="flight-offer-thumb">
                    <img src="../assets/img/images/offer_img05.jpg" alt="" />
                  </div>
                  <div class="flight-offer-content">
                    <h2 class="title">Dubai to New York</h2>
                    <span>09 Jun 2022 - 16 Jun 2022</span>
                    <p>Economy from</p>
                    <h4 class="price">$ 350</h4>
                  </div>
                  <div class="overlay-content">
                    <h2 class="title">Dubai to New York</h2>
                    <span>09 Jun 2022 - 16 Jun 2022</span>
                    <p>Economy from</p>
                    <h4 class="price">$ 350</h4>
                    <div class="content-bottom">
                      <a href="booking-details.html" class="btn">Booking Now</a>
                      <a href="booking-list.html" class="discover">Discover</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- flight-offer-area-end -->

    <!-- destination-area -->
    <section class="destination-area destination-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-8" style="text-align: left">
            <div class="section-title">
              <span class="sub-title">Offer Deals</span>
              <h2 class="title">Your Great Destination</h2>
            </div>
            <div class="destination-content">
              <!-- <p>Get rewarded for your travels – unlock instant savings of 10% or more with a free <span>Metrotravelservices.com</span> account</p> -->
              <ul class="p-0 flex-md-row flex-column">
                <li>
                  <div class="counter-item">
                    <div class="counter-content">
                      <h2 class="count fw-bold">
                        <span class="odometer" data-count="5830"></span>5830+
                      </h2>
                      <p class="mt-1">Happy Customers</p>
                    </div>
                    <div class="counter-icon mt-0">
                      <!-- <i class="flaticon-group"></i> -->
                      <img src="../assets/img/icon/group.png" width="60px" />
                    </div>
                  </div>
                </li>
                <li>
                  <div class="counter-item">
                    <div class="counter-content">
                      <h2 class="count fw-bold">
                        <span class="odometer" data-count="100"></span>100%
                      </h2>
                      <p class="mt-1">Client Setisfied</p>
                    </div>
                    <div class="counter-icon mt-0">
                      <!-- <i class="flaticon-globe"></i> -->
                      <img src="../assets/img/icon/global.png" width="60px" />
                    </div>
                  </div>
                </li>
              </ul>
              <div class="content-bottom flex-md-row flex-column">
                <p>Discover the latest offers & news and start planning</p>
                <a href="contact.html">contact us</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- destination-area-end -->

    <!-- brand-area -->
    <!-- <div class="brand-area brand-bg">
                <div class="container">
                    <div class="row brand-active">
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img01.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img02.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img03.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img04.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img05.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img06.png" alt="">
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="brand-item">
                                <img src="../assets/img/brand/brand_img03.png" alt="">
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div> -->
    <!-- brand-area-end -->

    <!-- service-area -->
    <section class="service-area">
      <div class="container" style="text-align: left">
        <div class="row align-items-end mb-50">
          <div class="col-md-8">
            <div class="section-title">
              <span class="sub-title">Why Flyro</span>
              <h2 class="title">Our Great Flight Options</h2>
            </div>
          </div>
          <div class="col-md-4">
            <div class="service-nav"></div>
          </div>
        </div>
        <div class="row service-active">
          <div class="col-lg-4">
            <div class="service-item">
              <div class="service-icon">
                <img src="../assets/img/icon/service_icon01.png" alt="" />
              </div>
              <div class="service-content">
                <span>Service 01</span>
                <h2 class="title">Pre-Book Your Baggage</h2>
                <div class="service-list">
                  <ul class="ps-0">
                    <li>
                      Pre-book your baggage <i class="fa-solid fa-check"></i>
                    </li>
                    <li>
                      Allowance now and save up
                      <i class="fa-solid fa-check"></i>
                    </li>
                    <li>
                      90% of baggage charges <i class="fa-solid fa-check"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="service-item">
              <div class="service-icon">
                <img src="../assets/img/icon/service_icon02.png" alt="" />
              </div>
              <div class="service-content">
                <span>Service 02</span>
                <h2 class="title">Reserve preferred seat!</h2>
                <div class="service-list">
                  <ul class="ps-0">
                    <li>
                      What will it be, window or aisle?
                      <i class="fa-solid fa-check"></i>
                    </li>
                    <li>
                      Select your preferred seat prior
                      <i class="fa-solid fa-check"></i>
                    </li>
                    <li>Reserved for you. <i class="fa-solid fa-check"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="service-item">
              <div class="service-icon">
                <img src="../assets/img/icon/service_icon03.png" alt="" />
              </div>
              <div class="service-content">
                <span>Service 03</span>
                <h2 class="title">Enjoy stress-free travel</h2>
                <div class="service-list">
                  <ul class="ps-0">
                    <li>
                      Travel stress-free by getting<i
                        class="fa-solid fa-check"
                      ></i>
                    </li>
                    <li>
                      Covered for flight modification
                      <i class="fa-solid fa-check"></i>
                    </li>
                    <li>
                      Cancellation, accident & medical
                      <i class="fa-solid fa-check"></i>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- service-area-end -->

    <!-- blog-area -->
    <section class="blog-area blog-bg">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="section-title text-center">
              <span class="sub-title">our News Feeds</span>
              <h2 class="title">Latest News Update</h2>
            </div>
          </div>
        </div>
        <div class="row justify-content-center flex-md-row flex-column">
          <div class="col-53">
            <div class="blog-item">
              <div class="blog-thumb">
                <a href="blog-details.html"
                  ><img src="../assets/img/blog/blog_img01.jpg" alt=""
                /></a>
              </div>
              <div class="blog-content">
                <div class="blog-meta">
                  <ul class="d-flex flex-row ps-0">
                    <li>
                      <i class="fa-regular fa-user"></i>
                      <a href="#">Emely Watson</a>
                    </li>
                    <li>
                      <i class="fa-solid fa-calendar-days"></i> February 19,
                      2022
                    </li>
                  </ul>
                </div>
                <h2 class="title text-start">
                  <a href="blog-details.html"
                    >Depending on your departure point and destination
                    flights</a
                  >
                </h2>
              </div>
            </div>
          </div>
          <div class="col-47">
            <div class="blog-item small-item flex-md-row flex-column">
              <div class="blog-thumb">
                <a href="blog-details.html"
                  ><img src="../assets/img/blog/blog_img02.jpg" alt=""
                /></a>
              </div>
              <div class="blog-content">
                <div class="blog-meta">
                  <ul class="d-flex flex-row ps-0">
                    <li>
                      <i class="fa-regular fa-user"></i>
                      <a href="#">Emely Watson</a>
                    </li>
                    <li>
                      <i class="fa-solid fa-calendar-days"></i> February 19,
                      2022
                    </li>
                  </ul>
                </div>
                <h2 class="title text-start">
                  <a href="blog-details.html"
                    >Happy International Country Flight Attendant Day</a
                  >
                </h2>
              </div>
            </div>
            <div class="blog-item small-item flex-md-row flex-column">
              <div class="blog-thumb">
                <a href="blog-details.html"
                  ><img src="../assets/img/blog/blog_img03.jpg" alt=""
                /></a>
              </div>
              <div class="blog-content">
                <div class="blog-meta">
                  <ul class="d-flex flex-row ps-0">
                    <li>
                      <i class="fa-regular fa-user"></i>
                      <a href="#">Emely Watson</a>
                    </li>
                    <li>
                      <i class="fa-solid fa-calendar-days"></i> February 19,
                      2022
                    </li>
                  </ul>
                </div>
                <h2 class="title text-start">
                  <a href="blog-details.html"
                    >The US is a Large Country and Climate Varies by Region</a
                  >
                </h2>
              </div>
            </div>
            <div class="blog-item small-item flex-md-row flex-column">
              <div class="blog-thumb">
                <a href="blog-details.html"
                  ><img src="../assets/img/blog/blog_img04.jpg" alt=""
                /></a>
              </div>
              <div class="blog-content">
                <div class="blog-meta">
                  <ul class="d-flex flex-row ps-0">
                    <li>
                      <i class="fa-regular fa-user"></i>
                      <a href="#">Emely Watson</a>
                    </li>
                    <li>
                      <i class="fa-solid fa-calendar-days"></i> February 19,
                      2022
                    </li>
                  </ul>
                </div>
                <h2 class="title text-start">
                  <a href="blog-details.html"
                    >But There are Dozen of Low-cost Airlines Including</a
                  >
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- blog-area-end -->
  </main>
  <!-- main-area-end -->
</template>

<script>
import moment from "moment";
import axios from "axios";
// import AutoComplete from "primevue/autocomplete";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';
export default {
  components: {
    // multicityCom,
    VueDatePicker,
    // AutoComplete,
    // dataloader
  },

  data() {
    return {
      selectedCity: "Modern",
      autocomplete:"",
      subscribeApi:"",
      cities: [
        { name: "New York", code: "NY" },
        { name: "Rome", code: "RM" },
        { name: "London", code: "LDN" },
        { name: "Istanbul", code: "IST" },
        { name: "Paris", code: "PRS" },
      ],

      countries: [{ name: "Australia", code: "AU" }],

      //   imgItems: [

      //     {
      //       src: require('@/assets/homesl1.png')
      //     },
      //     {
      //       src: require('@/assets/homesl2.png')
      //     },
      //     {
      //       src: require('@/assets/homesl3.png')
      //     },
      //     {
      //       src: require('@/assets/homesl4.png')
      //     },
      //     {
      //       src: require('@/assets/homesl5.png')
      //     },
      //   ],

      inputCity: "",
      inputCityto: "",
      typedrop: false,
      typedrop1: false,

      radios: "one",
      fromlabel: false,
      tolabel: false,
      loader: false,
      dialog1: false,
      dialog2: false,
      dateFormat: "MMM dd",
      sixWeekMode: true,
      classSelect: false,
      subEmail: "",
      subPhonenumber: "",
      multiCalender: true,
      validData: false,
      selectedCountry: "",
      maximumletter: false,
      maximumletter1: false,
      fromDate: "",
      name1: "John Doe",
      source: "vasanth",
      trav: true,
      loginbox: false,
      registerbox: false,
      terms: false,
      visible: false,
      RecentSearchData: [],
      selectAirports: "",
      red: "1",
      red1: 1,
      rount: true,
      Oneway: false,
      multicity: false,
      // select: "Economy",
      travels: false,
      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      resultData: 1,
      classType: "Economy",
      addition: 0,
      customer: [],
      buton: true,
      avath: false,
      error: false,
      viewbox: false,
      isVisible: true,
      // selectAirports: "",
      token: "Tc7RQaX79Hrx5CFK",

      // Autocomplete
      loading: false,
      fromAirports: [],
      airitems: [],
      // Autocomplete End

      items: [],

      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,
      valid: false,
      Errormsg: "",
      agreeMsg: "",
      subscribe: {
        email: "",
        phone: "",
        checkAgree: false,
      },

      roundedShadow: false,
      emailBox: false,
      phoneNumber: true,

      subEmailvalid: [
        (v) => !!v || "This field is required",
        (v) => /.+@.+\..+/.test(v) || "Please enter a valid email address",
      ],
      phoneValidate: [
        (v) => /^\+?[0-9]{1,16}$/.test(v) || "Please enter a phone number ",
        // (v) => /^(\+?[0-9]{1,12})?$/.test(v) || "Please enter a valid phone number (up to 12 digits, starting with +)",
      ],

      input: [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ],

      addmore: true,
      clearall: false,

      airbooking: true,
      mytrips: false,
      resentSearch: false,

      oneway: {
        from: null,
        to: null,
        travelDate: null,
        dedate: null,
        redate: null,
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },
      city: [],
      city1: [],
      multiData: {
        itinery: [
          {
            from: null,
            to: null,
            departDate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
          },
          {
            from: null,
            to: null,
            departDate: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
          },
        ],
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "3",
      },
      multidrop: [],
      multidrop1: [],
      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ONEWAY",
              CabinType: "Y",
              Currency: "CAD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ROUND",
              CabinType: "Y",
              Currency: "CAD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      array: [
        {
          name: "keerthi",
          age: 90,
        },
        {
          name: "keerthi",
          age: 90,
        },
      ],

      Adult: [],
      Child: [],
      recendFrom: [],
      recendTo: [],
      resultFrom: [],
      resultTo: [],
      forData: false,
      formres: false,
      formres1: false,
      Infrant: [],
      urldedate: "",
      urlredate: "",
      roundfrom: "",
      roundto: "",
      concatenatedArray: [],
      loadingData: true,
      dataloaded: false,
      randomAlphabets: "",
      cacheData: "",
      fromRecent: "",
      toRecent: "",
      multi: {
        from: null,
        to: null,
      },
      deatail: {
        name: "",
        email: "",
        password: "",
        repassword: "",
      },
      loginuser: {
        email: "",
        password: "",
      },
      userName: [(v) => !!v || "Please enter your name"],
      email: [(v) => !!v || "Please enter your Email"],
      password: [(v) => !!v || "Please enter your Password"],
      repassword: [
        (v) => !!v || "Please enter your Re-Password",
        (v) => v === this.deatail.password || "Passwords do not match",
      ],
      pnr: [(v) => !!v || "Please enter your PNR/Booking Ref no"],
      emaillast: [(v) => !!v || "Please enter your Email/Lastname"],

      chooseUs: [
        {
          logo: "fa-solid fa-shield-halved",
          sideHead: "Cheapest Deals",
          chooseLine:
            "Lowest Guaranteed booking price for popular destinations all over the globe.",
        },
        {
          logo: "fa-solid fa-mobile-screen",
          sideHead: "Easy To Book",
          chooseLine:
            "Search for your favorite city, choose the best deal and get ready to travel It is that easy!",
        },
        {
          logo: "fa-solid fa-certificate",
          sideHead: "Trust",
          chooseLine: "Providing 100% Safe and Secure bookings to customers.",
        },
      ],

      //   Destination: [
      //     {
      //       name: "Andaman",
      //       rate: "$480",
      //       url: require('@/assets/andaman.jpg')
      //     },
      //     {
      //       name: "SriLanka",
      //       rate: "$890",
      //       url: require('@/assets/SriLanka.jpg')
      //     },
      //     {
      //       name: "Dubai",
      //       rate: "$2310",
      //       url: require('@/assets/DUBAI.jpg')
      //     },
      //     {
      //       name: "Thailand",
      //       rate: "$760",
      //       url: require('@/assets/Thailand.jpg')
      //     },
      //   ],

      //   service: [
      //     {
      //       url: require('@/assets/cardimg.jpg'),
      //       name: "Best Price Promise and Guarantee",
      //       letter: "Get best fares to destinations worldwide."
      //     },
      //     {
      //       url: require('@/assets/cardimg1.jpg'),
      //       name: "Get Great Deals! ",
      //       letter: "Best fares in 500+ airlines for low airfares!"
      //     },
      //     {
      //       url: require('@/assets/cardimg2.svg'),
      //       name: "Expert Guidance",
      //       letter: "Get personalized assistance from our travel experts."
      //     },
      //   ],
      //   profile: [
      //     {
      //       url: require('@/assets/double-quotes.png'),
      //       url2: require('@/assets/profile.png'),
      //       name: "Alex Feder",
      //     },
      //     {
      //       url: require('@/assets/double-quotes.png'),
      //       url2: require('@/assets/profile.png'),
      //       name: "Elly Forb",
      //     },
      //     {
      //       url: require('@/assets/double-quotes.png'),
      //       url2: require('@/assets/profile.png'),
      //       name: "Mia Nest",
      //     },
      //     {
      //       url: require('@/assets/double-quotes.png'),
      //       url2: require('@/assets/profile.png'),
      //       name: "Dan Dorno",
      //     },
      //   ],

      //   slide: [
      //     {
      //       url: require('@/assets/maldives.avif'),
      //       name: "MALDIVES ISLAND - MALDIVES",
      //       letter: "Discover Maldives Island",
      //       rate: "$960"
      //     },
      //     {
      //       url: require('@/assets/taj-mahal.avif'),
      //       name: "TAJ MAHAL - AGRA",
      //       letter: "Historical Architecture",
      //       rate:"$1200"
      //     },
      //     {
      //       url: require('@/assets/paris.jpeg'),
      //       name: "EIFFEL TOWER - PARIS",
      //       letter : "Most Recognizable Monuments",
      //       rate : "$2120"
      //     },
      //     {
      //       url: require('@/assets/Jaipur.avif'),
      //       name: "PINK PALACE - JAIPUR",
      //       letter: "Palace of a Pyramidal shape",
      //       rate: "$2145"
      //     },
      //     {
      //       url: require('@/assets/munnar.avif'),
      //       name : "MUNNAR",
      //       letter : "Popular Resort Town in Kerala",
      //       rate: "$850"
      //     },
      //     {
      //       url: require('@/assets/china.jpg'),
      //       name : "SIKKIM",
      //       letter : "The land of Gorgeous Nature",
      //       rate: "$1550"
      //     }
      //   ],
      //   cardData: [
      //     {
      //       url: require('@/assets/canada.avif'),
      //       name: "CANADA",
      //       letter: "Snow-capped Mountains, Exotic Beaches, Lush Green Forests and Pristine Lakes.",
      //       rate: "$960"
      //     },
      //     {
      //       url: require('@/assets/baliimg.avif'),
      //       name: "BALI",
      //       letter: "Green Mountains, Rice Field Terraces, and Cool Villages",
      //       rate:"$1200"
      //     },
      //     {
      //       url: require('@/assets/dubaiimg.avif'),
      //       name: "DUBAI",
      //       letter : "Stunning Architecture, Majestic Skyscrapers, Glittering Skylines",
      //       rate : "$2120"
      //     },
      //     {
      //       url: require('@/assets/san.avif'),
      //       name: "SAN FRANCISCO",
      //       letter: " Famous for Grand-dame Victorians, a Soaring Golden Bridge",
      //       rate: "$2145"
      //     },
      //     {
      //       url: require('@/assets/parisimg.avif'),
      //       name : "PARIS",
      //       letter : "Paris is known for its Gorgeous, Imposing Monuments.",
      //       rate: "$850"
      //     },
      //     {
      //       url: require('@/assets/cardimg3.avif'),
      //       name : "CHITKUL - HIMACHAL PRADESH",
      //       letter : "Hub of Beautiful Lakes hold Cultural and Religious Significance.",
      //       rate: "$920"
      //     }
      //   ],

      airlineIcao: {},

      // uniqueId:[],

      airlineIcaoUrl: "",
      airlineIcaoType: "",

      focusedElement: null,
      changeTextFrom: "",
      changeTextTo: "",
      focusEvent1: false,
      focusEvent2: false,
      focusEvent3: false,
      dateUnfocus: false,
      closeDateMenu: false,
      bookid: "",
      lastname: "",
      focusFalse: true,

      pluginWhatspp: false,
      whatsappApi: "",
    };
  },

  beforeUnmount() {
    document.body.removeEventListener("click", this.handleOutsideClick);
  },

  methods: {
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    getrecent(data) {
      // console.log(data, 'pppp')
      this.oneway.from = data.from.name;
      this.fromRecent = data.from;
      // this.oneway.from= $data[$data.length - 1].replace(")", '')
    },

    getrecent1(data) {
      this.oneway.to = data.to.name;
      this.toRecent = data.to;
      //
    },
    getdated($event) {
      return moment($event).format(" DD-MMM-YYYY");
    },
    // getrecentData(data) {
    //   console.log(data, "ahahakkaaj");
    //   let res = data.split(",");
    //   let result = "";
    //   res.forEach((v, i) => {
    //     if (i !== res.length - 1) result += v + ",";
    //   });
    //   return result.slice(0, -1);
    // },

    async typeCity(event, $data) {
      if (event.length >= 1) {
        this.formres = false;
        if (event.length >= 1) {
          this.formres1 = false;
        }
      }
      this.city = [];
      this.city1 = [];
      // let $event = [];
      console.log(event, $data, "searchsearchsearchsearch");
      if ($data == 1 && event.length > 0) {
        this.fromloader = true;
      } else {
        this.fromloader = false;
      }

      if ($data == 2 && event.length > 0) {
        this.toloader = true;
      } else {
        this.toloader = false;
      }

      if (event.length >= 3) {
        await axios
         .get(`${this.autocomplete}?airportString=${event}`,{
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, response.data, "responseyyyyyyy");

            if ($data == 1) {
              this.fromloader = false;
              this.city = response.data;
              this.typedrop = true;
            } else if ($data == 2) {
              this.toloader = false;
              this.city1 = response.data;
              this.typedrop1 = true;
            }

            // let airlineIcao = response.data;
            // if (airlineIcao) {
            //   Object.keys(airlineIcao).forEach((icao) => {
            //     const airport = airlineIcao[icao];

            //     if (airport.iata !== "") {
            //       this.fromAirports.push({
            //         iata: airport.iata,
            //         icao: airport.icao,
            //         places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
            //       });
            //     }
            //   });
            // }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        // const searchString = event.toUpperCase();

        // setTimeout(() => {
        //   const iataMatch = [];
        //   const otherMatches = [];
        //   this.fromAirports.forEach((e) => {
        //     const iata = e.iata ? e.iata.toUpperCase() : "";
        //     const placesLowerCase = e.places.toLowerCase();
        //     if (iata === searchString) {
        //       iataMatch.push(e);
        //     } else if (placesLowerCase.includes(searchString.toLowerCase())) {
        //       otherMatches.push(e);
        //     }
        //   });

        //   this.airitems = iataMatch.concat(otherMatches);

        //   $event = this.airitems.map((item) => {
        //     const parts = item.places.split(", ");
        //     if (parts.length >= 2) {
        //       const name = `${parts[1]} (${parts[0]})`;
        //       const originalName = item.places;
        //       return {
        //         name,
        //         originalName,
        //       };
        //     }
        //   });

        //   if ($data == 1) {
        //     this.fromloader = false;
        //     $event.forEach((v) => {
        //       if (
        //         !this.city.some((item) => item.originalName == v.originalName)
        //       ) {
        //         this.city.push(v);
        //       }
        //     });
        //     this.typedrop = true;
        //   } else if ($data == 2) {
        //     this.toloader = false;
        //     $event.forEach((v) => {
        //       if (
        //         !this.city1.some((item) => item.originalName == v.originalName)
        //       ) {
        //         this.city1.push(v);
        //       }
        //     });
        //     this.typedrop1 = true;
        //   }

        //   console.log(this.city, this.city1, "vasanth");
        //   this.loading = false;
        // }, 1000);
      }
    },

    // generateRandomAlphabets() {
    //   const alphabets = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    //   let result = '';

    //   for (let i = 0; i < 6; i++) {
    //     const randomIndex = Math.floor(Math.random() * alphabets.length);
    //     result += alphabets[randomIndex];
    //   }

    //   this.randomAlphabets = result;
    //   this.updateCanvas();
    // },
    // updateCanvas() {
    //   const canvas = this.$refs.myCanvas;
    //   const ctx = canvas.getContext('2d');
    //   ctx.clearRect(0, 0, canvas.width, canvas.height);
    //   ctx.font = '20px Arial';
    //   ctx.fillStyle = "white";
    //   ctx.fillText(this.randomAlphabets, 10, 40);
    // },
    // generate() {
    //   this.generateRandomAlphabets();
    // },

    deleteItin(index) {
      this.multiData.itinery.splice(index, 1);
    },

    keyData($data) {
      if (this.randomAlphabets == $data.toUpperCase()) {
        this.loadingData = false;
      } else {
        this.loadingData = true;
      }
    },
    subData($data) {
      console.log($data, "tytttt");
      if ($data.includes("com")) {
        document.getElementById("visible").style.visibility = "visible";
        this.dataloaded = true;
      } else {
        this.dataloaded = false;
      }
    },

    focusAutocomplete(element) {
      this.focusedElement = element;

      if (this.focusedElement === "from") {
        this.formres = true;
        this.formres1 = false;

        this.focusEvent1 = true;
        this.focusEvent2 = false;
        this.focusEvent3 = false;

        this.$refs.fromAutoComplete.$el.querySelector("input").focus();
      } else if (this.focusedElement === "to") {
        this.formres1 = true;
        this.formres = false;
        this.focusEvent3 = false;
        this.focusEvent1 = false;
        this.focusEvent2 = false;

        this.$nextTick(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
        });
      }
    },

    focusMulti_AutoComplete(element, index) {
      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          if (!this.focusFalse) {
            this.focusedElement = null;
            v.focusMultiEvent3 = false;
          } else {
            this.focusedElement = element;
          }
        }
      });

      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          v.focusMultiEvent3 = false;
          console.log(v.focusMultiEvent3, "checkcheckcheck");
        }
      });

      if (this.focusedElement === "from") {
        this.multiData.itinery.map((v, i) => {
          // console.log(v,i,index,'mimimimi...1.')
          if (i == index) {
            v.formres = true;
            v.formres1 = false;

            v.focusMultiEvent1 = true;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
            const inputElement =
              this.$refs.fromAutoComplete[index].$el.querySelector("input");
            if (inputElement) {
              inputElement.focus();
            }
          } else {
            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
          }
        });
      } else if (this.focusedElement === "to") {
        this.multiData.itinery.map((v, i) => {
          if (i == index) {
            v.formres = false;
            v.formres1 = true;

            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = true;
            v.focusMultiEvent3 = false;

            const inputElement =
              this.$refs.secondAutoComplete[index].$el.querySelector("input");
            if (inputElement) {
              inputElement.focus();
            }
          } else {
            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
          }
        });
      } else if (this.focusedElement == "calendar1") {
        this.multiData.itinery.map((v, i) => {
          if (i == index) {
            v.formres = false;
            v.formres1 = true;

            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = true;

            const inputElement = this.$refs.dpRef4[index];
            if (inputElement) {
              inputElement.openMenu();
            }
            // v.$refs.fromAutoComplete.$el.querySelector("input").focus();

            // v.$nextTick(() => {
            //   this.$refs.secondAutoComplete.$el.querySelector("input").focus();
            // })
          } else {
            v.focusMultiEvent1 = false;
            v.focusMultiEvent2 = false;
            v.focusMultiEvent3 = false;
          }
        });
      }
    },

    focusCalendar(element) {
      if (!this.focusFalse) {
        this.focusedElement = null;
        this.focusEvent3 = false;
        this.focusEvent2 = false;
        this.focusEvent1 = false;
        this.$refs.dpRef1.closeMenu();
      } else {
        this.focusedElement = element;
      }

      if (this.focusedElement == "calendar1") {
        this.$nextTick(() => {
          this.$refs.dpRef1.openMenu();
          this.focusEvent3 = true;
          this.focusEvent2 = false;
          this.focusEvent1 = false;
          this.formres = false;
          this.formres1 = false;
        });
      } else {
        this.focusEvent3 = false;
        this.focusEvent2 = false;
        this.focusEvent1 = false;
      }
    },

    handleOutsideClick(event) {
      if (!event.target.closest(".input-styles")) {
        this.formres = false;
        this.formres1 = false;
        this.focusedElement = null;
        this.focusEvent1 = false;
        this.focusEvent2 = false;
        this.focusEvent3 = false;

        this.multiData.itinery.map((v) => {
          // console.log(v, i, "focusMultiEvent3focusMultiEvent3")
          v.focusMultiEvent1 = false;
          v.focusMultiEvent2 = false;
          v.focusMultiEvent3 = false;
        });
      }

      if (!event.target.closest(".subscribe-inputFields")) {
        this.focusedElement = null;
        this.roundedShadow = false;
      }
    },
    // subRadio() {

    //   this.phoneNumber = !this.phoneNumber
    // },
    adulted() {
      this.Adult.push({
        PassengerID: "T",
        PTC: "ADT",
      });
      //console.log(this.Adult, 'add')
    },
    adulted1() {
      this.Adult.pop();
      //console.log(this.Adult, 'remove')
    },

    Childed() {
      this.Child.push({
        PassengerID: "T",
        PTC: "CHD",
      });
    },
    Childed1() {
      this.Child.pop();
    },
    Infranted() {
      this.Infrant.push({
        PassengerID: "T",
        PTC: "INF",
      });
      this.passengerData();
    },
    Infranted1() {
      this.Infrant.pop();
    },

    act(data) {
      this.red1 = data;
      //console.log(this.red1, '00')
      this.oneway.city = this.red1;
      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
      }
      if (data == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
      }
      if (data == 3) {
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
      }
    },
    roundData() {
      let result = "";
      result = localStorage.getItem("rountData");
      console.log(this.result, "lavs");
      let result1 = [];
      result1 = JSON.parse(result);
      this.oneway.class = result1.class;
      if (result1.from.name) {
        this.oneway.from = result1.from.name;
      } else {
        this.oneway.from = result1.from;
      }
      if (result1.to.name) {
        this.oneway.to = result1.to.name;
      } else {
        this.oneway.to = result1.to;
      }
      this.oneway.dedate = moment(result1.dedate).format("DD MMM-YYYY");

      this.oneway.redate = moment(result1.redate).format("DD MMM-YYYY");

      this.oneway.class = result1.class;

      this.aduls = result1.adult;

      this.aduls2 = result1.infrant;

      this.aduls1 = result1.child;

      this.select = result1.class;
    },

    passengerData() {
      this.resultData = this.aduls + this.aduls1 + this.aduls2;
    },
    addTrip() {
      if (this.multiData.itinery.length < 4) {
        this.multiData.itinery.push({
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          multiSearchBtn: true,
          focusMultiEvent1: false,
          focusMultiEvent2: false,
          focusMultiEvent3: false,
        });

        if (this.multiData.itinery.length == 4) {
          this.addmore = false;
        }
      }

      // if(this.multiData.itinery.length-1){
      //   this.multiSearchBtn = true
      // }

      if (this.multiData.itinery.length > 3) {
        this.clearall = true;
      }

      for (let i = 1; i < this.multiData.itinery.length; i++) {
        this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      }

      // for (let i = 0; i <= this.multiData.length - 1; i++) {
      //     //console.log(this.multiData.length,'this.multiData.length')
      //     if(this.multiData.length==2){
      //       this.multiData[1].from = this.multiData[0].to
      //     }
      //     else if(this.multiData.length==3){
      //       this.multiData[2].from = this.multiData[1].to
      //     }
      //     else if(this.multiData.length==4){
      //       this.multiData[3].from = this.multiData[2].to
      //     }
      //   }
      //console.log(this.multiData, 'ooooo')
    },

    clearall1() {
      this.multiData.itinery.splice(2, 5);
      (this.multiData.itinery = [
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter: false,
          maximumletter1: false,
          clicked3: false,
        },
        {
          from: null,
          to: null,
          departDate: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter1: false,
          clicked3: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },
    delet(index) {
      this.multiData.itinery.splice(index, 1);
    },

    deg1() {
      this.adulted1();
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3();
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1;

        this.added();
      }
      // //console.log( this.Adult,'concatenatedArray')
    },
    add1() {
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls += 1;
        //console.log(this.aduls, '1111')

        this.adulted();
        this.added();
      }

      // //console.log( this.Adult,'concatenatedArray')
    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1;

        this.Childed1();
        this.added();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1;
        //console.log(this.aduls1, '2222')

        this.Childed();
        this.added();
      }
      // //console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1;

        this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1;
        //console.log(this.aduls2, '3333')

        this.Infranted();
        this.added();
      }
    },

    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );
      this.passengerData();
      //console.log(this.concatenatedArray, 'concatenatedArray')
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls");
      this.travels = false;
    },

    selectFunction() {
      this.classSelect = !this.classSelect;
      this.$refs.mySelect.focus();
      // if (!this.classSelect) {
      //   this.$refs.countrySelect.focus();
      // }
    },

    closeDropdown() {
      this.travels = false;
      this.$refs.countrySelect.blur();
      // this.$refs.mySelect.blur();
    },

    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;
      this.$refs.dpRef2.openMenu();
      this.focusedElement = "calendar2";

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;
      } else {
        this.clicked1 = false;
        this.fromDateError = true;
        this.$refs.dpRef1.openMenu();

        // setTimeout(() => {
        //   this.$refs.dpRef2.closeMenu();
        // }, 10)
      }
    },

    clearDate() {
      this.oneway.dedate = null;
      this.oneway.travelDate = null;
      this.focusEvent3 = false;
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();
      // console.log(v.clicked3,"checking....")

      if (this.oneway.dedate) {
        this.clicked1 = true;
        // this.fromlabel=true
      } else {
        this.clicked1 = false;
        // this.fromlabel=false;
      }
    },

    // toDateChanged(newValue) {
    //   this.oneway.redate = newValue;

    //this.$refs.countrySelect.focus();

    // let inputField = this.$refs.countrySelect
    // if(inputField){
    //   inputField.menu=true
    // }
    // this.$refs.countrySelect.openMenu();
    // console.log(this.$refs.countrySelect,'this.$refs.countrySelectthis.$refs.countrySelect')

    // if (this.oneway.redate) {
    //   this.clicked2 = true;
    //   this.toDateError = false;
    //   this.focusedElement = null
    // }
    // else {
    //   this.clicked2 = false;
    //   this.toDateError = true;
    //   this.$refs.dpRef2.openMenu();
    // }
    // },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();

      // this.$refs.mySelect.focus();

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;
      //console.log(this.oneway.dedate, "kkkkkkkkk");

      // this.$refs.select1.focus();

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.fromDateError = false;
      } else {
        this.clicked1 = false;
        this.fromDateError = true;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },
    // Oneway datePicker End

    // Multicity datepicker

    multiDateChanged(newValue, index) {
      this.multiData.itinery.map((v, i) => {
        if (index == i) {
          if (v.departDate) {
            v.focusMultiEvent3 = false;
          } else {
            v.focusMultiEvent3 = true;
          }
        }
      });

      this.multiData.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.depdata)) {
            v.depdata = null;
          }
        }
      });
    },

    getdate1($data, index) {
      if (index > 0) {
        return $data[index - 1].departDate;
      } else {
        return `${new Date()}`;
      }
    },

    multiClickOutside() {
      this.input.clicked3 = false;
      this.datePickerIsOpen = false;
    },

    sended() {
      this.focusFalse = false;
      this.inputCity =
        this.inputCity !== "" ? this.oneway.from : (this.oneway.from = null);
      this.inputCityto =
        this.inputCityto !== "" ? this.oneway.to : (this.oneway.to = null);
      console.log(this.focusEvent3, "focusEvent3focusEvent3");

      this.$nextTick(() => {
        this.focusEvent3 = false;
        this.$refs.dpRef1.closeMenu();
        console.log(this.focusEvent3, "this.focusEvent3this.focusEvent3");
      });

      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      // console.log(this.oneway, 'oneway.classoneway.class')
      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        setTimeout(() => {
          // this.$refs.dpRef1.closeMenu();
          this.focusEvent3 = false;
          // console.log(this.$refs.dpRef1, 'fdfdfdfdfdf...1')
        }, 5);

        let result3 = [];
        let result2 = [];
        result3 = JSON.parse(localStorage.getItem("recentFrom"));
        let obj = {
          from: this.inputCity,
          to: this.inputCityto,
          fromDate: this.oneway.dedate,
          toDate: this.oneway.redate,
          adult: this.aduls,
          child: this.aduls1,
          inf: this.aduls2,
          city: this.red1,
          class: this.classType,
        };

        // console.log(obj,'pppppppp')

        this.recendFrom.unshift(obj);

        if (result3 == null) {
          // console.log('ppp')
        } else {
          result2 = [...this.recendFrom, ...result3];
        }

        localStorage.setItem("recentFrom", JSON.stringify(result2));
        // console.log(  this.$store.state.recentForm,'  this.$store.state.recentForm')
        // console.log(  this.$store.state.recentForm,'  this.$store.state.recentForm')

        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else {
          this.roundto = this.oneway.to;
        }
        this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");
        this.urlredate = moment(this.oneway.redate).format("YYYY-MM-DD");
        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };
        console.log(rountdetail, "rountdetailrountdetail");

        this.$router.push({ path: "/search", query: rountdetail });
      } else {
        alert("Please Enter the Details");
        this.validateOnewayForm();
      }
    },

    bookinglist() {
      if (this.valid) {
        this.overlay = true;
        // this.retreivebooking.booking_id = this.bookid;
        // this.retreivebooking.last_name = this.lastname;

        let obj = {
          booking_Id: this.bookid,
          last_name: this.lastname,
        };

        let booking_details = JSON.stringify(obj);

        setTimeout(() => {
          this.dialog3 = true;
          this.overlay = false;
          this.$router.push({
            path: "/bookingView",
            query: { booking_id: booking_details },
          });
        }, 2000);
      }
    },
    cardassigndata($event) {
      console.log($event, "$event4444");
      this.resentSearch = false;
      this.airbooking = true;
      this.inputCity = $event.from;
      this.oneway.from = $event.from;
      this.inputCityto = $event.to;
      this.oneway.to = $event.to;
      this.aduls = $event.adult;
      this.aduls1 = $event.child;
      this.aduls2 = $event.inf;
      this.classType = $event.class;
      this.oneway.dedate = $event.fromDate;
      this.oneway.redate = $event.toDate;

      this.act($event.city);
    },
    sendedone() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      this.inputCity =
        this.inputCity !== "" ? this.oneway.from : (this.oneway.from = null);
      this.inputCityto =
        this.inputCityto !== "" ? this.oneway.to : (this.oneway.to = null);
      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;

        let result3 = [];
        let result2 = [];
        result3 = JSON.parse(localStorage.getItem("recentFrom"));
        let obj = {
          from: this.inputCity,
          to: this.inputCityto,
          fromDate: this.oneway.dedate,
          toDate: this.oneway.redate ? this.oneway.redate : "",
          adult: this.aduls,
          child: this.aduls1,
          inf: this.aduls2,
          city: this.red1,
          class: this.classType,
        };

        // console.log(obj,'pppppppp')

        this.recendFrom.unshift(obj);
        console.log(this.recendFrom, "pthis.recendFrom");

        if (result3 == null) {
          // console.log('ppp')
        } else {
          result2 = [...this.recendFrom, ...result3];
        }

        localStorage.setItem("recentFrom", JSON.stringify(result2));

        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);
        let fromData = "";
        let toData = "";

        if (this.oneway.from.name) {
          fromData = this.oneway.from.name;
        } else {
          fromData = this.oneway.from;
        }

        if (this.oneway.to.name) {
          toData = this.oneway.to.name;
        } else {
          toData = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");

        // console.log(this.oneway, ' this.oneway this.oneway this.oneway this.oneway this.oneway')
        let rountdetail = {
          from: fromData,
          to: toData,
          dedate: this.urldedate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };

        this.$router.push({ path: "/search", query: rountdetail });
      } else {
        alert("please enter your detail");

        this.validateOnewayForm();
      }
    },
    onewaySwap() {
      this.swapData = !this.swapData;
      let $dataFrom = this.inputCity;
      let $dataTo = this.inputCityto;
      this.inputCity = $dataTo;
      this.inputCityto = $dataFrom;
    },

    datachange(data) {
      console.log(data, "pppp");
      this.oneway.from = `${data.city} (${data.iata})`;
      this.inputCity = this.oneway.from;
      this.typedrop = false;
    },
    datachangeTo(data) {
      this.oneway.to = `${data.city} (${data.iata})`;
      this.inputCityto = this.oneway.to;
      this.typedrop1 = false;
    },
    datachangeMuti(data, index) {
      console.log(data, index, "data,index1");
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          v.from = `${data.city} (${data.iata})`;
          v.autocomfrom = false;
        }
      });
      console.log(this.multiData, "this.multiDatathis.multiData");
    },
    datachangeMutiTo(data, index) {
      console.log(data, index, "data,index1");
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          v.to = `${data.city} (${data.iata})`;
          v.autocomTo = false;
        }
      });

      for (let i = 1; i < this.multiData.itinery.length; i++) {
        this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      }
      console.log(this.multiData, "this.multiDatathis.multiData");
    },

    sendedMulticity() {
      this.focusFalse = false;
      console.log(this.multiData, " this.multiData this.multiData");
      if (
        this.multiData.itinery[0].from &&
        this.multiData.itinery[0].to &&
        this.multiData.itinery[0].departDate &&
        this.multiData.itinery[1].from &&
        this.multiData.itinery[1].to &&
        this.multiData.itinery[1].departDate &&
        (!this.multiData.itinery[2] ||
          (this.multiData.itinery[2].from &&
            this.multiData.itinery[2].to &&
            this.multiData.itinery[2].departDate)) &&
        (!this.multiData.itinery[3] ||
          (this.multiData.itinery[3].from &&
            this.multiData.itinery[3].to &&
            this.multiData.itinery[3].departDate))
      ) {
        this.multiData.adult = this.aduls;
        this.multiData.child = this.aduls1;
        this.multiData.infrant = this.aduls2;
        this.multiData.class = this.classType;
        let $data = [];
        this.multiData.itinery.forEach((v) => {
          console.log(v, "multy");
          let itineraryObject = {
            from: v.from.name || v.from,
            to: v.to.name || v.to,
            depdata: moment(v.departDate).format("DD-MMM-YYYY"),
          };
          $data.push(itineraryObject);
        });
        console.log($data, " $data $data");

        this.multiData.itinery = $data;

        console.log(this.multiData, "this.multiData");
        let muti_city = JSON.stringify(this.multiData);
        this.$router.push({ path: "/search", query: { muti_city } });
      } else {
        alert("please enter your detail");
      }
    },

    // sendedMulticity() {

    //   for (let i = 0; i < this.input.length; i++) {

    //     if (this.input[i].from) {
    //       this.input[i].multiFromError = false;
    //     } else {
    //       this.input[i].multiFromError = true;
    //     }

    //     if (this.input[i].to) {
    //       this.input[i].multiToError = false;
    //     } else {
    //       this.input[i].multiToError = true;
    //     }

    //     if (this.input[i].departDate) {
    //       this.input[i].multiDateError = false;
    //     } else {
    //       this.input[i].multiDateError = true;
    //     }
    //   }

    // },

    // submit() {
    //   //console.log(this.valid, '9999')
    //   if (this.valid) {
    //     loginService.createUser(this.deatail).then(res => {
    //       //console.log(res, '88888')
    //     })
    //   }
    // },

    login() {
      let user1 = this.customer.findIndex(
        (v) => v.email === this.loginuser.email
      );
      //console.log(user1, '23442234')
      let user2 = this.customer.findIndex(
        (v) => v.password === this.loginuser.password
      );
      //console.log(user2, '23442234')
      if (user1 === 0 && user2 === 0) {
        this.avath = true;
        this.buton = false;
      } else {
        this.avath = false;
        this.buton = true;
        this.error = true;
      }
    },

    onChangeMultiFrom(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
            v.maximumletter = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
            v.maximumletter1 = false;
          } else {
            v.multiToError = true;
            // v.maximumletter1 = true
          }
        }
      });

      for (let i = 1; i < this.multiData.itinery.length; i++) {
        this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
      }
    },

    // console.log($data, index,'$data, index$data, index')
    // let data1=$data
    // for(let i=0;i<$data.length;i++){
    //     console.log(data1[i],'v[i].departDate')
    //     for(let j=0;j<data1.length;j++){
    //      if($data[i].departDate > data1[j].departDate || $data[i].departDate > data1[j].departDate){
    //        return $data[i-1].departDate=null
    //        console.log(data1[j].departDate,'data1[j].departDatedata1[j].departDate')
    //      }
    //     }
    // }

    // $data.forEach((v,i)=>{
    //     console.log(v[i],'v[i].departDate')
    //     if(v[i].departDate > v[i+1].departDate){

    //        return $data.departDate.splice(v[i],6)
    //     }
    //     else{
    //         return v[i].departDate
    //     }
    // })

    //      if (index == 0 && (($data[index].departDate > $data[index + 1].departDate) || (!$data[index + 1].departDate))) {
    //         console.log("Partially WOrkinggg...1.")
    //         $data[index + 1].departDate = null
    //     }

    //     else if ($data[index].departDate > $data[index + 1].departDate) {
    //             $data[index + 1].departDate = null
    //             console.log("Partially WOrkinggg...2.")
    //         $data[index].departDate.splice()
    //     }

    //     else{
    //     console.log('Not workinfgkkkk')
    // }

    // getdate1($data, index) {
    //     if (index > 0) {
    //         return $data[index - 1].departDate
    //     }
    //     else {
    //         return `${new Date}`
    //     }
    // },

    // multiClickOutside(index) {
    //     console.log(index,"checking....")

    //     this.multiData.clicked3 = false
    //     this.datePickerIsOpen = false;

    //     this.multiData.map((v, i) => {
    //         if (index == i) {
    //             console.log(i,v,"checking...1.")
    //             this.$refs.dpRef4.closeMenu();
    //         }
    //     })
    // },

    multiFromClick(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;
            if (!v.from) {
              v.multiFromError = true;
            } else {
              v.multiFromError = false;
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;
            if (!v.to) {
              v.multiToError = true;
            } else {
              v.multiToError = false;
            }
          }
        }
      });
    },

    multifocus1(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (!v.to) {
            setTimeout(() => {
              const inputElement =
                this.$refs.secondAutoComplete[index].$el.querySelector("input");
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = true;
              if (inputElement) {
                inputElement.focus();
              }
            }, 10);
          } else {
            setTimeout(() => {
              const inputElement =
                this.$refs.fromAutoComplete[index].$el.querySelector("input");
              console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }
        }
      });
    },

    multifocus2(index) {
      this.multiData.itinery.map((v, i) => {
        if (i == index) {
          if (!v.departDate) {
            setTimeout(() => {
              const inputElement = this.$refs.dpRef4[index];
              if (inputElement) {
                inputElement.openMenu();
              }
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = true;
            }, 10);
          } else {
            setTimeout(() => {
              v.focusMultiEvent2 = false;
              v.focusMultiEvent3 = false;

              const inputElement =
                this.$refs.secondAutoComplete[index].$el.querySelector("input");
              console.log(this.$refs.fromAutoComplete);
              v.focusMultiEvent1 = false;
              v.focusMultiEvent2 = false;
              if (inputElement) {
                inputElement.blur();
              }
            }, 10);
          }

          // for (let i = 0; i < 1; i++) {
          //   console.log(this.multiData[0].to, 'bfbfbfbf.....')
          //   this.multiData.itinery[1].from = this.multiData.itinery[0].to;
          // }
        }
      });

      // setTimeout(() => {
      //   if (this.$refs.dpRef4.length > 0) {
      //     const inputElement = this.$refs.dpRef4[index];
      //     if (inputElement) {
      //       inputElement.openMenu();
      //     }
      //   }

      // for (let i = 0; i < 1; i++) {
      //   //console.log(this.multiData[0].to, 'bfbfbfbf.....')
      //   this.multiData.itinery[1].from = this.multiData.itinery[0].to;
      // }
    },

    // Subcribe Section
    subscribeData() {
      let subscribeData = {
        request_type: "subscriber_form",
        email: this.subEmail,
        whatsapp: true,
        phone_no: this.subPhonenumber,
        token: "djfkdjkf",
      };
      console.log(subscribeData, "subscribeDatasubscribeData");
      if (this.validData) {
        this.loader = true;
        axios
          .post(this.subscribeApi, subscribeData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response, "response");
            this.loader = false;
            this.dialog2 = true;
          })
          .catch((error) => {
            console.log(error, "error");
            setTimeout(() => {
              this.dialog1 = true;
            }, 500);
          });
      }
    },
    clearEmailError() {
      if (this.Errormsg && this.subscribe.email) {
        this.Errormsg = "";
      }
    },
    clearAgreeError() {
      if (this.agreeMsg && this.subscribe.checkAgree) {
        this.agreeMsg = "";
      }
    },
    // Subcribe Section End

    // fromOutsideAuto($event){
    //   console.log($event.from, 'p.....')
    //   if ($event.from) {
    //     this.fromlabel=true
    //   }
    //   else{
    //   this.fromlabel=false
    //   }
    // },

    // toOutsideAuto($event){
    //   console.log($event.to, 'p.....')
    //   if ($event.to) {
    //     this.tolabel=true
    //   }
    //   else{
    //   this.tolabel=false
    //   }
    // },

    // AutoComplete Functions

    fromClick($event) {
      this.formres = true;
      this.fromlabel = true;
      this.formres1 = false;
      //console.log($event, 'p.....')
      if ($event.from) {
        $event.from = null;
        this.fromErrorMessage = true;
        this.changeTextFrom = false;
      }
    },

    toClick($event) {
      this.tolabel = true;
      this.formres = false;
      //console.log($event, 'p.....')
      if ($event.to) {
        $event.to = null;
        this.toErrorMessage = true;
        this.changeTextTo = false;
      }
    },

    onChangeFrom() {
      setTimeout(() => {
        if (this.oneway.from) {
          this.fromErrorMessage = false;
          this.changeTextFrom = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100);
    },
    // if (this.oneway.from) {
    //   //console.log(this.oneway.from, 'okokok....1...')
    //   this.fromErrorMessage = false;
    // }
    // else {
    //   //console.log(this.oneway.from, 'okokok....2...')
    //   this.fromErrorMessage = true;
    // }

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
          this.changeTextTo = true;
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },
    async typeMultiCity(event, index, $num) {
      console.log(event, index, $num, "searchsearchsearchsearch");
      // let $event = [];
      console.log(event, index, "searchsearchsearchsearch");

      if (event.length >= 3) {
        if ($num == 1 && event.length > 2) {
          this.multiData.itinery.map((v, i) => {
            v.autocomTo = false;
            v.fromloader = true;
            if (i == index && event.length > 2) {
              v.autocomfrom = true;
            } else {
              v.autocomfrom = false;
              v.fromloader = false;
              this.multidrop = [];
            }
          });
        } else if ($num == 2 && event.length > 0) {
          this.multiData.itinery.map((v, i) => {
            v.autocomfrom = false;
            if (i == index && event.length > 2) {
              v.autocomTo = true;
              v.toloader = true;
            } else {
              v.autocomTo = false;
              v.toloader = false;
              this.multidrop1 = [];
            }
          });

          for (let i = 1; i < this.multiData.itinery.length; i++) {
            this.multiData.itinery[i].from = this.multiData.itinery[i - 1].to;
          }
        }

        await axios
          .get(
            `${this.autocomplete}?airportString=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response.data, "responsedata");

            if ($num == 1) {
              this.multiData.itinery.map((v) => {
                v.fromloader = false;
              });

              this.multidrop = response.data;
              console.log(this.multidrop, "this.multidrop");
            } else if ($num == 2) {
              this.multiData.itinery.map((v) => {
                v.toloader = false;
              });

              this.multidrop1 = response.data;
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
      }
    },
    validateOnewayForm() {
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },

    async search(event) {
      console.log(event, "searchsearchsearchsearch");

      if (event.query.length >= 1) {
        this.formres = false;
        if (event.query.length >= 1) {
          this.formres1 = false;
        }
      }

      this.fromAirports = [];

      if (event.query.length >= 3) {
        await axios
          .get(
            `${this.autocomplete}?airportString=${event.query}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, "responseautocomple");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.query.toUpperCase();
        // console.log(searchString,'searchStringsearchString')
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
              // console.log(iataMatch,'iataMatchiataMatch')
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
              //  console.log(otherMatches,'otherMatches')
            }
          });

          this.airitems = iataMatch.concat(otherMatches);
          this.loading = false;
        }, 1000);
      } else {
        if (this.oneway.from) {
          this.maximumletter = true;
          // this.maximumletter1 = false;
        }
        if (this.oneway.to) {
          this.maximumletter1 = true;
          this.maximumletter = false;
        }
      }
    },

    focus1() {
      if (!this.oneway.to) {
        // console.log('testueeiekek.....1')
        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
          this.focusEvent2 = true;
        }, 100);
      } else {
        this.focusEvent2 = false;
        // console.log('testueeiekek.....2')
        setTimeout(() => {
          this.$refs.fromAutoComplete.$el.querySelector("input").blur();
        }, 100);
      }
    },

    focus2() {
      if (!this.oneway.dedate || !this.oneway.travelDate) {
        setTimeout(() => {
          this.focusEvent3 = true;
          if (this.$refs.dpRef1) {
            this.$refs.dpRef1.openMenu();
          }
        }, 100);
      } else {
        this.focusEvent3 = false;

        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").blur();
        }, 100);
      }
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          //console.log(this.input[0].to, 'bfbfbfbf.....')
          this.input[1].from = this.input[0].to;
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth <= 480) {
        // console.log(this.multiCalender,'multmiikmhuihuu...1111')
        this.multiCalender = false;
      } else {
        // console.log(this.multiCalender,'multmiikmhuihuu...2222')

        this.multiCalender = true;
      }
    },

    getConfig() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        // let pluginWhats = ""
        // pluginWhats = getConfigData.payload.portal_configuration.menus.enabled.plugins

        // tagLineCaption
        // console.log(pluginWhats, 'dasdasdasda..1..1..1.1')
        // if (pluginWhats) {
        //   this.pluginWhatspp = true
        //   this.whatsappApi = getConfigData.payload.portal_configuration.API_endpoints.whatsapp
        // }
        // else {
        //   this.pluginWhatspp = false
        // }
        this.autocomplete = getConfigData.payload.portal_configuration.API_endpoints.autocomplete
        this.subscribeApi = getConfigData.payload.portal_configuration.API_endpoints.subscriber_form

      }
    },

    autoDate() {
      this.oneway.travelDate = [];
      let value1 = new Date();
      let value2 = new Date(new Date().setDate(value1.getDate() + 7));

      this.oneway.travelDate.push(value1, value2);

      if (this.oneway.travelDate == 2) {
        this.oneway.dedate = this.oneway.travelDate[0];
        this.oneway.redate = this.oneway.travelDate[1];
      }

      // console.log(this.oneway.travelDate, 'creaaadddddddd....')
    },

    recentFromData() {
      let result1 = [];
      let result2 = [];
      // let result3 = []
      // localStorage.setItem("recentFrom")
      result1 = JSON.parse(localStorage.getItem("recentFrom"));
      // console.log(result1, 'result1result1')
      if (result1 == null) {
        console.log("suhajhbhu");
      } else if (result1.length > 0) {
        //  alert('pppp')
        result2 = result1.splice(0, 15);
        this.RecentSearchData = result2;
        console.log(this.RecentSearchData, "this.RecentSearchData");
        // result3 = result1.splice(0, 15)
        // console.log(result1, 'ppp')
        let result = [];
        let resultTo = [];

        result2.forEach((a) => {
          if (!result.some((b) => b.from.name === a.from.name)) {
            result.push(a);
          }
        });
        this.resultFrom = result;
        // console.log(result2,'result3result3')
        result2.forEach((a) => {
          if (!resultTo.some((b) => b.to.name === a.to.name)) {
            resultTo.push(a);
          }
        });
        // console.log(resultTo, 'resultToresultTo')

        this.resultTo = resultTo;
      } else {
        console.log(result1);
      }
    },
  },

  // AutoComplete Functions End
  watch: {
    //     'oneway.from'(newVal) {
    //    this.oneway.from="yyz"
    //       console.log(newVal, 'ppppvvsd')

    // },

    "oneway.class"(newSelectValue) {
      // console.log('select property changed to:', newSelectValue);

      if (newSelectValue) {
        // setTimeout(() => {
        this.$refs.countrySelect.focus();
        this.classSelect = false;
        // console.log(this.classSelect)
        //  }, 100);
      }
    },

    "oneway.travelDate"(value) {
      if (value && value.length <= 2) {
        this.oneway.dedate = value[0];
        this.oneway.redate = value[1];

        this.focusEvent3 = false;
      } else {
        this.oneway.travelDate = null;
      }
    },

    "oneway.dedate"(value) {
      if (value) {
        this.focusEvent3 = false;
      } else {
        this.oneway.dedate = null;
      }
    },
  },

  // AutoComplete Functions
  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },
  },
  // AutoComplete Functions End

  created() {
    localStorage.removeItem("delete_Id");
    this.getConfig();
    // this.autoDate();
    // this.autocompletJson();
    window.addEventListener("resize", this.handleResize);
  },

  mounted() {
    // this.fetchData();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    document.body.addEventListener("click", this.handleOutsideClick);
    this.recentFromData();
    // this.generateRandomAlphabets()

    //
    // loginService.getAllUser().then(res => {
    //   this.customer = res.data
    //   //console.log(this.customer, '44444')
    // })
    //console.log(this.oneway, 'llllllllllllllll')
  },
};
</script>

<style scoped>
@import "@/assets/style.css";
>>> .v-selection-control-group {
  display: flex;
  flex-direction: row !important;
}
.p-error {
  font-size: 13px;
  color: red;
  position: absolute;
  /* left: 0px;
  top: 24px; */
}
.p-error-from {
  font-size: 13px;
  color: red;
  position: absolute;
  bottom: -16px;
}

.p-error-to {
  font-size: 13px;
  color: red;
  position: absolute;
  bottom: 1px;
}

.dropdown-box {
  padding: 8px 10px;
  /* border-radius: 10px; */
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  display: block;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

/* .p-float-label {
   width: 100%;
   font-size: 16px;
   color: #242424;
   font-weight: 600;
 } */

.p-float-label label {
  position: relative;
  pointer-events: none;
  top: -26px;
  left: -50px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  color: #808080b3;
  font-size: 14px;
  font-weight: 500;
}
.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.formbuttons {
  background-color: #26225e;
}

.labelTop p {
  font-size: 13px;
  margin: 0;
  text-align: left;
  color: black;
  /* color: #3c0ec2; */
  font-weight: 600;
  padding-left: 13px;
}

.p-float-label .p-inputwrapper-focus ~ label {
  display: none !important;
}

.p-float-label .p-inputwrapper-filled ~ label {
  display: none !important;
}
>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}
.p-component * {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
  /* padding: 2px;
  padding-top: 8px;
  padding-bottom: 8px; */
}
.input-styles {
  /* padding: 6px 0px 6px 8px; */
  /* background: #cecece2e; */
  height: 40px;
  border-radius: 2rem !important;
  padding-right: 12px;
  width: 100%;
  margin-right: 4px;
  padding-left: 8px;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}
>>> .p-autocomplete-panel .p-component .p-ripple-disabled ul {
  list-style: none !important;
}
.p-float-label label {
  left: 15px;
}
.p-float-label .fromLabel {
  left: -10px;
}
>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}
>>> .p-autocomplete .p-autocomplete-dropdown {
  display: none !important;
}
>>> .p-autocomplete .p-component .p-inputwrapper {
  top: -9px;
}
>>> .dp__input_icon {
  display: none;
}
>>> .dp__clear_icon {
  display: none;
}
>>> .p-autocomplete-loader {
  position: relative;
  top: 80%;
}
.date .disable {
  opacity: 0.3 !important;
}
>>> .economy .select-1 {
  --v-field-padding-top--plain-underlined: 12px;
}

/* >>> .economy .select-1 .v-input__control {
  height: 34px;
} */

>>> .economy .select-1 .v-field__input {
  /* padding-top: 2; */
  align-items: center;
  padding: 0px;
  min-height: 0px;
}
>>> .economy .select-1 .v-input {
  height: 30px;
}

>>> .economy .select-1 .v-field__append-inner {
  padding-top: 0;
}
>>> .economy .v-field--variant-filled .v-field__overlay {
  background-color: transparent;
}
>>> .economy .v-field--variant-filled .v-field__outline::before {
  border-color: transparent !important;
}
.pax-fonts span {
  font-size: 15px;
  font-weight: 600;
}
>>> .multiDate .dp__input {
  background-color: transparent !important;
  border: none !important;
  width: 100%;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #6a2e4d; */
  color: #26225e;
  /* padding: 20px 20px; */
  height: 35px;
  font-family: "Barlow", sans-serif;
}
>>> .input-styles-2 .v-select .v-select__selection-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: grey !important;
}
.input-styles-2 {
  box-shadow: none;
  padding: 3px 5px 8px 12px;
  border: 1px solid #0000003d;
  height: 30px !important;
  color: black;
  background: white;
  border-radius: 8px;
}
>>> .select-option .v-input {
  --v-input-chips-margin-bottom: 0 !important;
  --v-input-chips-margin-top: -3px !important;
}
>>> .input-styles-2 .v-field__input {
  padding-top: 0px;
  min-height: 0px;
}
>>> .input-styles-2 .v-field .v-field__append-inner {
  padding-top: 0px;
}
>>> .input-styles-2 .v-field--variant-underlined .v-field__outline::before {
  border-color: transparent !important;
  border-style: none !important;
}
>>> .v-field--variant-underlined .v-field__outline::before {
  border-color: transparent !important;
  border-style: none !important;
}
>>> .p-autocomplete-option {
  display: block !important;
}
>>> .multiDate .dp__pointer {
  padding-left: 0px;
}
>>> .multiDate .dp__main {
  top: -5px;
}
.recent_style {
  position: absolute;
  background: white;
  width: 340px;
  margin: 12px 12px;
  border-radius: 20px;
  z-index: 5;
  /* border: 1px solid gray; */
}
.adt-chd {
  width: 100%;
  background: transparent;
  border: none;
  font-size: 14px !important;
  font-weight: 500 !important;

  height: 35px;
  font-family: "Barlow", sans-serif;
}
.re-fon {
  font-size: 13px;
  font-weight: 500;
  font-family: "Barlow", sans-serif;
}
>>> .view-book .v-input__control {
  display: flex;
  grid-area: control;
  height: 45px;
}
.inter-input {
  width: 100%;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 500;
  /* color: #6a2e4d; */
  color: #26225e;
  /* padding: 20px 20px; */
  height: 25px;
  font-family: "Barlow", sans-serif;
}
.flight-search .pos-swap {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #002d5b;
  position: absolute;
  top: 0.9rem;
  right: -1.2rem;
  z-index: 1;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
}

.flight-search .pos-swap:hover {
  background-color: #ffc104;
  color: #343534;
}

@media screen and (max-width: 992px) {
  .flight-search .pos-swap {
    right: 0;
    top: 2.5rem;
  }
}
.autocompete-menual-dropdown {
  border: 1px solid lightgray;
  background-color: white;
  cursor: pointer;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 300px;
  /* max-width: 100%; */
  padding: 4px;
  border-radius: 2px;
  margin-top: 18px;
  /* margin-left: -44px; */
  z-index: 99999;
  font-size: 13px;
  font-weight: 500;
}
>>> .select-option .v-input {
  --v-input-chips-margin-top: 0px !important;
}
.booking-form .form-grp {
  top: -36px;
}
>>> .roundFromTime .dp__input_wrap {
  top: -7px;
  left: -5px;
}
>>> .roundToTime .dp__input_wrap {
  top: -7px;
  left: 8px;
}
.labelTop {
  position: relative;
  top: 3px;
}
.resu-form:hover {
  background: #e3e1df;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .blog-area .row .col-53 {
    width: 100%;
    flex: 0 0 100%;
  }
  .blog-area .row .col-47 {
    width: 100%;
    flex: 0 0 100%;
  }
  .blog-content .title {
    font-size: 20px;
  }
  .blog-content {
    padding: 25px;
  }
  .destination-content ul li {
    width: 100%;
    margin-bottom: 10px;
  }
  .flight-offer-item.offer-item-two .flight-offer-thumb img {
    width: 100%;
  }
  .fly-next-thumb img {
    width: 100%;
  }
  .v-selection-control--density-default {
    --v-selection-control-size: 35px;
  }
  .booking-area .tab-content-wrap {
    width: 100%;
  }
  .booking-form {
    height: 290px;
  }
  .booking-wrap .nav-tabs .nav-item {
    width: 100%;
  }
  .booking-form .form-grp {
    top: -13px;
  }
  .mobileForm,
  .multiData {
    height: auto;
  }
  .booking-form .form-grp.economy {
    padding: 0px 4px;
    height: 55px;
  }
  .booking-form .form-grp .exchange-icon {
    display: none;
  }
  .roundLabel .p-float-label label {
    left: -8px;
  }
  >>> .v-input__details {
    display: none;
  }
  >>> .multiData {
    border-bottom: 1px solid #a4a0a0;
  }
  .roundDataTo {
    left: -25px;
  }
}

.va-back:hover {
  background-color: lightblue;
}

.va-content {
  font-size: 13px;
  font-weight: 500;
  text-align: start;
}
>>> .dp__pointer {
  padding-left: 0px !important;
}
.true-btn {
  margin-bottom: 0;
  background: #ffffff;
  border: none;
  color: black;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: 100%;
  justify-content: center;
  transition: 0.3s linear;
  font-family: "Barlow", sans-serif;
  font-size: 15px;
}
.false-btn {
  margin-bottom: 0;
  background: black;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  border-radius: 0;
  display: flex;
  align-items: center;
  padding: 6px 12px;
  width: 100%;
  justify-content: center;
  transition: 0.3s linear;
  font-family: "Barlow", sans-serif;
  font-size: 15px;
}
</style>
